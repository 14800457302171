import axiosClient from './axiosClient';

export const featuredkeywordApi = {
  getAll(params) {
    return axiosClient.get('/admin/featuredkeywords/', { params });
  },
  delete(id) {
    return axiosClient.delete(`/admin/featuredkeywords/${id}/`);
  },
  add(keyword) {
    return axiosClient.post(`/admin/featuredkeywords`, keyword);
  },
  edit({ data, id }) {
    return axiosClient.patch(`/admin/featuredkeywords/${id}`, data);
  },
  get(id) {
    return axiosClient.get(`/admin/featuredkeywords/${id}`);
  }
};
