import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Form, Input, Switch } from 'antd';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';

import { TitleWrapper } from 'components/layout';
import WrapperCenter from 'components/common/button/wrapperCenter';

import { companyConfirmApi } from 'api/companyConfirmApi';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './styles.css';

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
const tailLayout = {
  wrapperCol: {
    span: 24,
  },
};
const Acolumn = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const FeatureAddStyles = styled.div`
  .form {
    margin-top: 10px;
  }
`;

function AddFeatureKeyWords() {
  const intl = useIntl();
  const [historyFormData, setHistoryFormData] = useState({});
  const [enable, setEnable] = useState(false);
  let navigate = useNavigate();
  const [form] = Form.useForm();
  const formElemt = useRef();
  const keyword = useRef();
  const { id } = useParams();
  const handleConfirm = () => {
    onFinish(true);
  };
  const handleRefuce = () => {
    onFinish(false);
  };
  const onFinish = async (isBool) => {
    if (isBool) {
      const newForm = {
        confirm_id: +id,
        user_id: historyFormData.Userid,
        company_id: historyFormData.Companyid,
      };
      await companyConfirmApi.confirm(newForm);
    } else {
      const newForm = {
        confirm_id: +id,
      };
      await companyConfirmApi.refuse(newForm);
    }
    navigate('/companyconfirm');
  };
  function SortData(array) {
    array.Companyname = array.company.name;
    array.Companyid = array.company.id;
    array.Username = array.user.name;
    array.Userid = array.user.id;
    return array;
  }
  async function fetchKeyWord(id) {
    const { data } = await companyConfirmApi.getid(id);
    const newDate = SortData(data);
    setEnable(!!data.confirm);
    setHistoryFormData({ ...newDate });
    formElemt.current.setFieldsValue({ ...newDate });
  }
  useEffect(() => {
    if (id) {
      fetchKeyWord(id);
    }
    keyword.current.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <FeatureAddStyles>
      <TitleWrapper>
        {intl.formatMessage({
          id: id
            ? 'views.companyconfirm.title.edit'
            : 'views.companyconfirm.title.add',
        })}
      </TitleWrapper>
      <Form
        ref={formElemt}
        {...layout}
        form={form}
        name="control-hooks"
        onFinish={onFinish}
        className="form"
      >
        <Form.Item
          name="Companyname"
          label="Tên công ty"
          rules={[
            {
              required: true,
              message: 'không để trường này trống',
            },
          ]}
        >
          <Input ref={keyword} disabled={true} />
        </Form.Item>
        <Form.Item
          name="Username"
          label="Tên tài khoản"
          rules={[
            {
              required: true,
              message: 'không để trường này trống',
            },
          ]}
        >
          <Input ref={keyword} disabled={true} />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              message: 'không để trường này trống',
            },
          ]}
        >
          <Input ref={keyword} disabled={true} />
        </Form.Item>
        <Form.Item
          name="phone"
          label="Số điện thoại"
          rules={[
            {
              required: true,
              message: 'không để trường này trống',
            },
          ]}
        >
          <Input ref={keyword} disabled={true} />
        </Form.Item>
        <Form.Item name="confirm" label="Duyệt công ty" {...Acolumn}>
          <Switch
            checked={enable}
            onChange={(values) => setEnable(values)}
            disabled={true}
          />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <WrapperCenter>
            <Button
              type="primary"
              style={{ marginRight: 10 }}
              onClick={handleConfirm}
            >
              Xác nhận
            </Button>
            <Button type="primary" onClick={handleRefuce}>
              Từ chối
            </Button>
          </WrapperCenter>
        </Form.Item>
      </Form>
    </FeatureAddStyles>
  );
}

export default AddFeatureKeyWords;
