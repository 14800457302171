import { useIntl } from 'react-intl';
import { Switch } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { TitleWrapper, ContentWrapper } from 'components/layout';

import { useCustomSearchParams } from 'hooks/useCustomSearchParams';
import { useBlog, useUpdateBlog } from './queries';
import { TableWrapper } from 'components';

import { calcIndexByOrder } from 'utils/common';

const FeatureKeyWordStyles = styled.div`
  .action {
    margin: 0px 5px;
  }
  .action:hover {
    opacity: 0.7;
    cursor: pointer;
  }
  .ant-table-wrapper {
  }
  .content {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;
function Featuredkeyword(props) {
  const intl = useIntl();
  let navigate = useNavigate();
  const [search, setSearch] = useCustomSearchParams();
  // table
  const argument = {
    params: search,
    options: {
      keepPreviousData: true,
    },
  };
  //
  const { data, isPreviousData, isFetching } = useBlog(argument);
  const { mutate } = useUpdateBlog(argument);
  const columns = [
    {
      title: intl.formatMessage({ id: 'common.index' }),
      render: (_, __, index) =>
        calcIndexByOrder({ index, page: search.page, limit: search.page_size }),
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'blog.content' }),
      dataIndex: 'content',
      align: 'center',
      width : 600,
      render: (_, value) => (
        <>
          <p className="content">{value.content}</p>
        </>
      ),
    },
    {
      title: intl.formatMessage({ id: 'blog.user' }),
      dataIndex: 'user',
      align: 'center',
      render: (_, value) => (
        <>{value.user_info.name || value.user_info.email}</>
      ),
    },
    {
      title: intl.formatMessage({ id: 'blog.show' }),
      dataIndex: 'hiện tin tức',
      align: 'center',
      render: (_, value) => (
        <>
          <Switch
            defaultChecked
            onChange={onChange}
            checked={value.enable}
            id={value.id}
          />
        </>
      ),
    },
    {
      title: intl.formatMessage({ id: 'blog.action' }),
      dataIndex: 'user',
      align: 'center',
      render: (_, value) => (
        <EditOutlined
        className="action"
        onClick={() => handleEdit(value.id)}
        />
      ),
    },
  ];
  const handleEdit = (id) => {
    navigate(`/blogs/edit/${id}`);
  };
  const onChange = (checked, e) => {
    const id = e.target.id || e.target.parentElement.id;
    mutate(id);
  };
  const handleChange = ({ current, pageSize }) =>
    setSearch({ ...search, page: current, page_size: pageSize });
  const renderExtra = <></>;
  return (
    <>
      <FeatureKeyWordStyles>
        <TitleWrapper>
          {intl.formatMessage({ id: 'views.blogs.list.title' })}
        </TitleWrapper>
        <ContentWrapper>
          <TableWrapper
            rowKey="id"
            columns={columns}
            dataSource={data?.data || []}
            loading={isPreviousData && isFetching}
            pagination={{
              current: search.page,
              pageSize: search.page_size,
              total: data?.meta?.total ?? 0,
              position: ['bottomCenter'],
            }}
            onChange={handleChange}
            extra={renderExtra}
          />
        </ContentWrapper>
      </FeatureKeyWordStyles>
    </>
  );
}

export default Featuredkeyword;
