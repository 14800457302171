import { useIntl } from 'react-intl';

import { useCustomSearchParams } from 'hooks/useCustomSearchParams';
import { TableWrapper } from 'components';
import { useCategory } from 'views/category/queries';

import { calcIndexByOrder } from 'utils/common';
function Category({ value, onChange }) {
  const intl = useIntl();
  const [search, setSearch] = useCustomSearchParams();
  // table
  const argument = {
    params: search,
    options: {
      keepPreviousData: true,
    },
  };
  //
  const { data, isPreviousData, isFetching } = useCategory(argument);
  const List = data?.data.data || [];
  const onSelectChange = (newSelectedRowKeys) => {
    onChange(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys: value,
    onChange: onSelectChange,
  };
  const columns = [
    {
      title: intl.formatMessage({ id: 'common.index' }),
      render: (_, __, index) =>
        calcIndexByOrder({ index, page: search.page, limit: search.page_size }),
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'category.level_1' }),
      dataIndex: 'level_1',
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'category.level_2' }),
      dataIndex: 'level_2',
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'category.level_3' }),
      dataIndex: 'level_3',
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'category.level_4' }),
      dataIndex: 'level_4',
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'category.level_5' }),
      dataIndex: 'level_5',
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'category.name' }),
      dataIndex: 'name',
      align: 'center',
    },
  ];
  const handleChange = ({ current, pageSize }) =>
    setSearch({ ...search, page: current, page_size: pageSize });
  const renderExtra = <></>;
  return (
    <>
      <TableWrapper
        rowKey="id"
        columns={columns}
        dataSource={List}
        loading={isPreviousData && isFetching}
        rowSelection={rowSelection}
        Hideheader={true}
        pagination={{
          current: search.page,
          position: ['bottomCenter'],
          pageSize: search.page_size,
          total: List.meta?.total ?? 0,
        }}
        onChange={handleChange}
        extra={renderExtra}
      />
    </>
  );
}

export default Category;
