import { useQuery } from 'react-query';

import { AddressApi } from 'api/AddressApi';

const userAddress = {
  all: ['adddress'],
  lists: () => [...userAddress.all, 'lists'],
  list: (filter) => [...userAddress.lists(), filter],
  details: () => [...userAddress.all, 'detail'],
  detail: (id) => [...userAddress.details(), id],
};

export const useGetAllProvince = () => {
  return useQuery({
    queryKey: "useGetAllProvince",
    queryFn: () => AddressApi.GetAllProvince(),
  })
}
export const useGetListDistrict = ({ provinceId }) => {
  return useQuery({
    queryKey: `useGetListDistrict${provinceId}`,
    queryFn: () => AddressApi.GetListDistrict(provinceId),
  })
}
export const useGetListStreet = ({ districtId }) => {
  return useQuery({
    queryKey: `useGetListStreet${districtId}`,
    queryFn: () => AddressApi.GetListStreet(districtId),
  })
}
export const useGetListBuilding = ({ streestId }) => {
  return useQuery({
    queryKey: `useGetListBuilding${streestId}`,
    queryFn: () => AddressApi.GetListBuilding(streestId),
  })
}
