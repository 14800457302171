import React, { useEffect, useRef, useState, memo } from 'react';

import { TitleWrapper } from "components/layout";
import { useNavigate, useParams } from "react-router-dom";
import styled from 'styled-components';
import { Button, Form, Input, Select } from 'antd';
import { toolbarOptions } from 'config/draftConfig';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import HtmlToDraftfc from '../../../utils/HtmlToDraftfc';
import WrapperCenter from 'components/common/button/wrapperCenter';
import { settingsApi } from 'api/settingsApi';

import { Editor } from 'react-draft-wysiwyg';
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from 'draft-js';
import { useIntl } from 'react-intl';

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 0,
    span: 24,
  },
};

const AddSettingStyles = styled.div`
  .form {
    margin-top: 10px;
  }
  .editor {
    padding: 10px;
  }
  .demo-editor {
    ${'' /* border: 1px solid #000; */}
    padding: 10px;
    border-radius: 4px;
    background-color: #fff;
    min-height: 200px;
  }
`;

function AddSetting (props) {

  const intl = useIntl();
  let navigate = useNavigate();
  const formElemt = useRef();
  const title = useRef();
  const content = useRef();
  const type = useRef();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [detailData, setDetailData] = useState(null);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const onEditorStateChange = (newState) => setEditorState(newState);

  const onFinish = async (values) => {
    values.content = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    let data = id
      ? await settingsApi.edit(id, values)
      : await settingsApi.add(values);

    if (data.error) {
      console.log('message', data.error);
    } else {
    navigate('/settings');
    }
  };

  async function fetchData(id) {
    const { data } = await settingsApi.get(id);

    setDetailData(data);
    formElemt.current.setFieldsValue({
      type: data.type,
      title: data.title,
      content: data.content,
    });
    const blocksFromHTML = convertFromHTML(data.content);
    const editorContent = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    );
    setEditorState(EditorState.createWithContent(editorContent));
  }

  useEffect(() => {
    async function fetchData(id) {
      const { data } = await settingsApi.get(id);
      setDetailData(data[0]);
      formElemt.current.setFieldsValue({
        type: data.type,
        title: data.title,
        content: data.content,
      });
      const blocksFromHTML = convertFromHTML(data.content);
      const editorContent = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
      );
      console.log('data-detail: ', HtmlToDraftfc(data.content));
      setEditorState(HtmlToDraftfc(data.content));
    }
    if (id) {
      fetchData(id);
    }
    title.current.focus();
  }, []);

  return (
    <AddSettingStyles>
      <TitleWrapper>
        {intl.formatMessage({
          id: id ? 'views.settings.title.edit' : 'views.settings.title.add',
        })}
      </TitleWrapper>

      <Form
        ref={formElemt}
        {...layout}
        form={form}
        name="control-hooks"
        onFinish={onFinish}
        className="form"
      >
        <Form.Item
          name="title"
          label="Title: "
          rules={[
            {
              required: true,
              message: 'không để trường này trống',
            },
          ]}
        >
          <Input ref={title} />
        </Form.Item>
        <Form.Item name="content" label="Content: ">
          <Editor
            ref={content}
            editorState={editorState}
            toolbar={{
              ...toolbarOptions,
            }}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={onEditorStateChange}
          />
        </Form.Item>

        <Form.Item name="type" label="Type">
          <Select
            defaultValue={{
              label: 'Loại cấu hình',
            }}
            ref={type}
            style={{
              width: '100%',
              outline: '1px solid #40a9ff',
              color: '#007575',
              fontSize: 14,
              fontWeight: 400,
            }}
          >
            <Select.Option value="rules">Điều khoản</Select.Option>
            <Select.Option value="recharge_form">Mẫu nạp tiền</Select.Option>
            <Select.Option value="guide">Hướng dẫn</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <WrapperCenter>
          <Button
            style={{ padding: '0 !important' }}
            type="primary"
            htmlType="submit"
          >
            {id ? 'Sửa' : 'Thêm   '}
          </Button>
          </WrapperCenter>
        </Form.Item>
      </Form>
    </AddSettingStyles>
  )
}

export default memo(AddSetting)