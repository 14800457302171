import axiosClient from './axiosClient';

export const userApi = {
  getAll(params) {
    return axiosClient.get('/admin/users', { params });
  },
  getById(id) {
    return axiosClient.get(`/admin/users/${id}`);
  },
  delete(id) {
    return axiosClient.delete(`/admin/users/${id}/`);
  },
  edit(keyword, id) {
    return axiosClient.patch(`/admin/users/${id}`,keyword);
  },
  
  updateImages(id, data) {
    return axiosClient.post(`/admin/users/avatar/${id}`, data);
  },
};
