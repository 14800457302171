const theme = {
  colors: {
    white: '#fff',
    black: '#000',

    primary: '#007575',
    secondary: '#057642',
    success: '#2ecc71',
    error: '#e74c3c',
  },
  fontSize: {
    default: '16px',
  },
  borderRadius: {
    default: '4px',
  },
};

export default theme;
