import { useIntl } from 'react-intl';
import { Select, Col, Row, Form, Button } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { TitleWrapper, ContentWrapper } from 'components/layout';
import { TableWrapper } from 'components/common/table';
import { useCustomSearchParams } from 'hooks/useCustomSearchParams';
import UseAddSearch from '../../hooks/useAddSearch';
import {
  useGetAllProvince,
  useGetListDistrict,
  useGetListFilter,
} from './queries';

const { Option } = Select;
export default function Users() {
  const intl = useIntl();
  const { getColumnSearchProps } = UseAddSearch();
  const [search, setSearch] = useCustomSearchParams();
  const [data, setData] = useState([]);
  const [type, setType] = useState('province');
  const [text, setText] = useState('Tỉnh');
  const [objectAddress, setObjectAddress] = useState({
    province: null,
    district: null,
    filter: 'ward',
  });
  const HandleValuesChange = (value) => {
    if (value.province) {
      setObjectAddress((prev) => {
        return { ...prev, ...value, district: null };
      });
    } else {
      setObjectAddress((prev) => {
        return { ...prev, ...value };
      });
    }
  };
  const { data: ProvinceData } = useGetListDistrict({
    id: objectAddress.province,
    setData,
    setType,
    setText,
  });
  useGetListFilter({
    id: objectAddress.district,
    setData,
    filter: objectAddress.filter,
    setType,
    setText,
  });
  const { data: ProvinceAllData } = useGetAllProvince({
    params: 'ProvinceAllData',
    setData,
    setType,
  });
  const handleDelete = (id) => {};
  const renderExtra = (
    <Link
      to={`/address/create/${type}?province_id=${objectAddress.province}&district_id=${objectAddress.district}`}
    >
      <Button type="primary">Thêm {text}</Button>
    </Link>
  );
  const List = ProvinceAllData?.data?.data || [];
  const ListDistrict = ProvinceData?.data?.data || [];
  const columns = [
    {
      title: intl.formatMessage({ id: 'address.index' }),
      align: 'center',
      render: (_, item, index) => <p>{item.id}</p>,
    },
    {
      title: intl.formatMessage({ id: 'address.name' }),
      dataIndex: 'name',
      align: 'center',
      ...getColumnSearchProps('name'),
    },
    {
      title: intl.formatMessage({ id: 'address.action' }),
      render: (_, item, index) => (
        <div>
          <EditOutlined className="address__icon" />
          <DeleteOutlined
            className="address__icon"
            onClick={() => handleDelete(item.id)}
          />
        </div>
      ),
      align: 'center',
    },
  ];

  const handleChange = ({ current, pageSize }) => {
    setSearch({ ...search, page: current, page_size: pageSize });
  };
  const AddressContainer = styled.div`
    .address__icon {
      margin-left: 10px;
      cursor: pointer;
    }
  `;
  return (
    <>
      <TitleWrapper>
        {intl.formatMessage({ id: 'views.address.title.list' })}
      </TitleWrapper>

      <ContentWrapper>
        <AddressContainer>
          <Form name="basic" onValuesChange={HandleValuesChange}>
            <Row>
              <Col span={8}>
                <Form.Item
                  name="province"
                  initialValue={objectAddress.province}
                  label="Tỉnh"
                  style={{
                    width: '98%',
                  }}
                  rules={[
                    {
                      required: true,
                      message: 'không để trường này trống',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Chọn tỉnh"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {List.map((province) => (
                      <Option value={province.id} key={province.id}>
                        {province.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="district"
                  label="Huyện"
                  initialValue={objectAddress.district}
                  rules={[
                    {
                      required: true,
                      message: 'không để trường này trống',
                    },
                  ]}
                  style={{
                    width: '98%',
                  }}
                >
                  <Select
                    showSearch
                    placeholder="Chọn huyện và quận"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {ListDistrict.map((district) => (
                      <Option value={district.id} key={district.id}>
                        {district.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="filter"
                  label="Lọc đường và xã"
                  initialValue={objectAddress.filter}
                  rules={[
                    {
                      required: true,
                      message: 'không để trường này trống',
                    },
                  ]}
                  style={{
                    width: '98%',
                  }}
                >
                  <Select
                    showSearch
                    placeholder="Lọc đường và Xã/Phường/Thị trấn/Đường "
                    optionFilterProp="children"
                    onChange={handleChange}
                    filterOption={(input, option) =>
                      option.children.includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    <Option value="ward">Xã và Phường và Thị trấn</Option>
                    <Option value="street">Đường</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <TableWrapper
            rowKey="id"
            columns={columns}
            dataSource={data || []}
            onValuesChange={HandleValuesChange}
            onChange={handleChange}
            extra={renderExtra}
            pagination={{
              current: search.page,
              pageSize: search.page_size,
              total: data?.meta?.total ?? 0,
            }}
          />
        </AddressContainer>
      </ContentWrapper>
    </>
  );
}
