import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Navigate } from 'react-router-dom';
import { Form} from 'antd';
import { unwrapResult } from '@reduxjs/toolkit';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

import bgLogin from 'assets/images/login/bg.jpg';
import { ButtonStyle } from 'components';
import { STORAGE_KEY } from 'constants/common';
import { parseJwt } from 'utils/common';
import { doLogin } from 'store/slices/authSlice';

import { PATH_NAME } from 'constants/routes';
import { FormWrapper, InputField } from 'components/form';
import logo from '../../assets/png/logo.svg'

export default function SignIn() {
  const intl = useIntl();
  const navigate = useNavigate();
  // const location = useLocation();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const [isLoading, setIsLoading] = useState(false);
  // const from = location.state?.from?.pathname || PATH_NAME.ROOT;

  const handleSubmit = async (values) => {
    setIsLoading(true);
    try {
      const result = await dispatch(doLogin(values));
      unwrapResult(result);

      navigate(PATH_NAME.ROOT);
    } catch (error) {
      setIsLoading(false);
    }
  };

  if (isLoggedIn || parseJwt(localStorage.getItem(STORAGE_KEY.TOKEN))) {
    return <Navigate to={PATH_NAME.ROOT} />;
  }

  return (
    <SignInStyles>
      <div className="container-signinbox">
        <div className="logginBox">
          <div className="loginLogo">
            <img src={logo} alt="logo" />
          </div>
          <p className="wellcome">Wellcome You!</p>
          <div className="loginContentWrapper">
            <h3 className="title">Đăng nhập</h3>
            <FormWrapper
              onFinish={handleSubmit}
              initialValues={{
                email: 'admin@gmail.com',
                password: 'pix113114',
              }}
              autoComplete="off"
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'validate.required' }),
                  },
                  {
                    type: 'email',
                    message: intl.formatMessage({
                      id: 'validate.invalidEmail',
                    }),
                  },
                ]}
              >
                <InputField
                  autoFocus
                  placeholder={intl.formatMessage({ id: 'input.email' })}
                  prefix={
                    <img src="./assets/image/login/User.png" alt="user" />
                  }
                />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'validate.required' }),
                  },
                ]}
              >
                <InputField.Password
                  placeholder={intl.formatMessage({ id: 'input.password' })}
                  prefix={
                    <img src="./assets/image/login/lock.png" alt="user" />
                  }
                />
              </Form.Item>

              <Form.Item>
                <ButtonStyle
                  htmlType="submit"
                  type="green"
                  block
                  loading={isLoading}
                  className="large"
                >
                  {intl.formatMessage({ id: 'btn.submit' })}
                </ButtonStyle>
              </Form.Item>
            </FormWrapper>
          </div>
        </div>
        <div className="footer">
          <p className="year">
            Copyright © {new Date().getUTCFullYear()}. Công ty Cổ phần ...
          </p>
        </div>
      </div>
    </SignInStyles>
  );
}

const SignInStyles = styled.div`
  max-width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(${bgLogin});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .container-signinbox {
    width: 38%;
  }
  .loginLogo {
    display: flex;
    justify-content: center;
    margin-top: 18px;
    margin-bottom: 58px;

    img{
      width: 100px;
      object-fit: cover;
    }
  }
  .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 24px;
    text-align: center;
  }
  .year {
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #ffffff;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
  }
  .wellcome {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #fbfbfb;
    margin-bottom: 58px;
    width: 100%;
    text-align: center;
  }
  .logginBox {
    background: linear-gradient(180deg, #007575 33.95%, #ffffff 134.65%);
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
    padding: 38px;
    border-radius: 24px;
  }
  .large {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    background: ${({ theme }) => theme.colors.primary};
  }
  .loginContentWrapper {
    width: 100%;
    z-index: 10;
    display: flex;
    flex-direction: column;
    padding: 29px 24px;
    padding-bottom: 69px;
    background-color: var(--white);
    box-shadow: rgb(0 0 0 / 40%) 0px 5px 7px 0px;
    border-radius: 8px;
  }
  @media screen and (max-width: 912px) and (min-width: 212px)  {
    .container-signinbox {
      width: 85%;
  }
    .title{
      font-size:19px !important;
  }
`;
