export const toolbarOptions = {
    options: [
      'inline',
      'blockType',
      'fontSize',
      'fontFamily',
      'list',
      'textAlign',
      'link',
      'image',
      'remove',
      'history',
      'embedded',
    ],
    colorPicker: false,
    emoji: false,
    inline: {
      options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
    },
  };