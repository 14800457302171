import axiosClient from './axiosClient';

export const AddressApi = {
  GetAllProvince() {
    return axiosClient.get(`/admin/province?page_size=1000`);
  },
  GetListDistrict(id) {
    return axiosClient.get(`/admin/district?province_id=${id}&page_size=1000`);
  },
  GetListWarn(id) {
    return axiosClient.get(`/admin/ward?district_id=${id}&page_size=1000`);
  },
  GetListStreet(id) {
    return axiosClient.get(`/admin/street?district_id=${id}&page_size=1000`);
  },
  GetListBuilding(id) {
    return axiosClient.get(`/admin/buildings?street_id=${id}&page_size=1000`);
  },
  // Thêm sữa xóa tỉnh
  AddProvince(value) {
    return axiosClient.post(`/admin/province`, value);
  },
  EditProvince(value, id) {
    return axiosClient.patch(`/admin/province/${id}`, value);
  },
  DeleteProvince(id) {
    return axiosClient.delete(`/admin/province/${id}`);
  },
  GetProvince(id) {
    return axiosClient.get(`/admin/province/${id}`);
  },
  //
  // Huyện
  AddDistrict(value) {
    return axiosClient.post(`/admin/district`, value);
  },
  EditDistrict(value, id) {
    return axiosClient.patch(`/admin/district/${id}`, value);
  },
  DeleteDistrict(id) {
    return axiosClient.delete(`/admin/district/${id}`);
  },
  GetDistrict(id) {
    return axiosClient.get(`/admin/district/${id}`);
  },
  //  Thị trấn và xã và quận
  AddWard(value) {
    return axiosClient.post(`/admin/ward`, value);
  },
  EditWard(value, id) {
    return axiosClient.patch(`/admin/ward/${id}`, value);
  },
  DeleteWard(id) {
    return axiosClient.delete(`/admin/ward/${id}`);
  },
  GetWard(id) {
    return axiosClient.get(`/admin/ward/${id}`);
  },
  // thêm đường
  AddStreet(value) {
    return axiosClient.post(`/admin/street`, value);
  },
  EditStreet(value, id) {
    return axiosClient.patch(`/admin/street/${id}`, value);
  },
  DeleteStreet(id) {
    return axiosClient.delete(`/admin/street/${id}`);
  },
  GetStreet(id) {
    return axiosClient.get(`/admin/street/${id}`);
  },
};
