import React, { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Button, Form, Input, Row, Col } from 'antd';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';

import { TitleWrapper } from 'components/layout';
import WrapperCenter from 'components/common/button/wrapperCenter';

import { categoryApi } from 'api/categoryApi';

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 0,
    span: 24,
  },
};
const Acolumn = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
const FeatureAddStyles = styled.div`
  .form {
    margin-top: 10px;
  }
`;
function AddCategory(props) {
  const intl = useIntl();
  let navigate = useNavigate();
  const formElemt = useRef();
  const name = useRef();
  const { id } = useParams();
  const [form] = Form.useForm();
  const onFinish = async (values) => {
    const ValueForm = {
      ...values,
      level_2: !values.level_2 ? 0 : values.level_2,
      level_3: !values.level_3 ? 0 : values.level_3,
      level_4: !values.level_4 ? 0 : values.level_4,
      level_5: !values.level_5 ? 0 : values.level_5,
    };
    let data = id
      ? await categoryApi.edit(ValueForm, id)
      : await categoryApi.add(ValueForm);
    if (data.error) {
      console.log('message', data.error);
    } else {
      navigate('/category');
    }
  };
  async function fetchKeyWord(id) {
    const { data } = await categoryApi.get(id);
    formElemt.current.setFieldsValue({ ...data });
  }
  useEffect(() => {
    if (id) {
      fetchKeyWord(id);
    }
    name.current.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <FeatureAddStyles>
      <TitleWrapper>
        {intl.formatMessage({
          id: id ? 'views.category.title.edit' : 'views.category.title.add',
        })}
      </TitleWrapper>
      <Form
        ref={formElemt}
        {...layout}
        form={form}
        name="control-hooks"
        onFinish={onFinish}
        className="form"
      >
        <Row>
          <Col span={24}>
            <Form.Item
              name="name"
              label="Tên"
              style={{ width: '98%' }}
              rules={[
                {
                  required: true,
                  message: 'không để trường này trống',
                },
              ]}
            >
              <Input ref={name} placeholder="Nhập tên" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="level_1"
              label="Cấp độ 1"
              style={{ width: '98%' }}
              rules={[
                {
                  required: true,
                  message: 'không để trường này trống',
                },
              ]}
            >
              <Input placeholder="Nhập cập độ 1" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="level_2"
              label="Cấp độ 2"
              {...Acolumn}
              style={{ width: '98%', marginLeft: '2%' }}
            >
              <Input placeholder="Nhập cập độ 2" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="level_3"
              label="Cấp độ 3"
              {...Acolumn}
              style={{ width: '98%', marginLeft: '2%' }}
            >
              <Input placeholder="Nhập cập độ 3" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="level_4"
              label="Cấp độ 4"
              {...Acolumn}
              style={{ width: '98%', marginLeft: '2%' }}
            >
              <Input placeholder="Nhập cập độ 4" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="level_5"
              label="Cấp độ 5"
              {...Acolumn}
              style={{ width: '98%', marginLeft: '2%' }}
            >
              <Input placeholder="Nhập cập độ 5" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="Character"
              label="Từ khóa ngành nghề"
              style={{ width: '98%' }}
            >
              <Input placeholder="Nhập character" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item {...tailLayout}>
          <WrapperCenter>
            <Button type="primary" htmlType="submit">
              {id ? 'Sửa ngành nghề' : 'Thêm ngành nghề'}
            </Button>
          </WrapperCenter>
        </Form.Item>
      </Form>
    </FeatureAddStyles>
  );
}

export default AddCategory;
