import React from 'react';
import { Form, Select } from 'antd';

import { isIncludeTextSearch } from 'utils/common';
import { useCustomSearchParams } from 'hooks/useCustomSearchParams';

import { useAdminList } from '../queries';

const { Option } = Select;

function SelectUser() {
  const [search] = useCustomSearchParams();
  const argument = {
    params: search,
    options: {
      keepPreviousData: true,
    },
  };
  const { data, isloading, isFetched } = useAdminList(argument.options);
  return (
    <>
      {!isloading && isFetched && (
        <Form.Item name="user_created_id">
          <Select
            className="selectType"
            showSearch
            placeholder="Tài khoản"
            optionFilterProp="children"
            filterOption={(input, option) =>
              isIncludeTextSearch(input, option.children)
            }
          >
            <Option value={"all"}>{'Tất cả'}</Option>
            {data.data.map((CompanyType) => (
              <Option value={CompanyType.id} key={CompanyType.id}>
                {CompanyType.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}
    </>
  );
}

export default SelectUser;
