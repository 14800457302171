import { useQuery, useQueryClient, useMutation } from 'react-query';

import { AddressApi } from 'api/AddressApi';

const userKeys = {
  all: ['users'],
  lists: () => [...userKeys.all, 'lists'],
  list: (filter) => [...userKeys.lists(), filter],
  details: () => [...userKeys.all, 'detail'],
  detail: (id) => [...userKeys.details(), id],
};
function ChangeName(data, text) {
  const Data = data;
  for (let i = 0; i < Data.length; i++) {
    const item = Data[i];
    item.name = (item.prefix || text || "") + " " + item.name;
  }
  return Data;
}
export const useGetAllProvince = ({ params, setData, id } = {}) => {
  return useQuery({
    queryKey: userKeys.list(params),
    queryFn: () => AddressApi.GetAllProvince(),
    onSuccess: (data) => {
      const Data = data?.data?.data;
      const NewData = ChangeName(Data, "Tỉnh");
      setData && setData([...NewData]);
    },
  })
}
export const useGetListDistrict = ({ id, district, setData, setObjectAddress } = {}) => {
  return useQuery({
    queryKey: userKeys.list(id),
    queryFn: () => AddressApi.GetListDistrict(id),
    onSuccess: (data) => {
      if (id) {
        const Data = data?.data?.data;
        const NewData = ChangeName(Data);
        setObjectAddress && setObjectAddress(prev => {
          if (!prev[district]) {
            return {
              ...prev,
              [district]: NewData[0].id
            }
          } else {
            return { ...prev }
          }
        });
        setData && setData([...NewData])
      }
    },
  })
}
export const useGetListWard = ({ id, setData } = {}) => {
  return useQuery({
    queryKey: userKeys.list(id + "warn"),
    queryFn: () => AddressApi.GetListWarn(id),
    onSuccess: (data) => {
      if (id) {
        const Data = data?.data?.data;
        const NewData = ChangeName(Data);
        setData && setData([...NewData])
      }
    },
  })
}
export const useGetListWardSelect = ({ id, setData } = {}) => {
  return useQuery({
    queryKey: userKeys.list(id),
    queryFn: () => AddressApi.GetListWarn(id),
    enabled: !!id,
    select: (data) => {
      if (id) {
        console.log("select", data);
      }
      return data;
    },
    onSuccess: (data) => {
      if (id) {
        const Data = data?.data?.data;
        const NewData = ChangeName(Data);
        setData && setData([...NewData])
      }
    },
  })
}
export const useGetListStreet = ({ id, setData } = {}) => {
  return useQuery({
    queryKey: userKeys.list(id),
    queryFn: () => AddressApi.GetListStreet(id),
    onSuccess: (data) => {
      if (id) {
        const Data = data?.data?.data;
        const NewData = ChangeName(Data);
        setData && setData([...NewData])
      }
    },
  })
}
export const useGetListFilter = ({ id, setData, filter, setType, setObjectAddress, setText } = {}) => {
  return useQuery({
    queryKey: userKeys.list(id + filter),
    queryFn: () => AddressApi.GetListFilter(id, filter),
    onSuccess: (data) => {
      setType(filter)
      if (id) {
        const Data = data?.data?.data;
        const NewData = ChangeName(Data);
        setData([...NewData])
        setText(filter === "ward" ? "Xã và Phường và Thị trấn" : "Đường")
      }
    },
  })
}
const deleteProvince = (id) => {
  return AddressApi.DeleteProvince(id);
}
export const useProvinceDelete = (id, type) => {
  const queryClient = useQueryClient()
  return useMutation(deleteProvince, {
    onSuccess: (data) => {
      queryClient.refetchQueries()
    }
  });
}
const deleteDistrict = (id) => {
  return AddressApi.DeleteDistrict(id);
}
export const useDistrictDelete = (id, type) => {
  const queryClient = useQueryClient()
  return useMutation(deleteDistrict, {
    onSuccess: (data) => {
      queryClient.refetchQueries()
    }
  });
}
const deleteWarn = (id) => {
  return AddressApi.DeleteWard(id);
}
export const useWarnDelete = (id, type) => {
  const queryClient = useQueryClient()
  return useMutation(deleteWarn, {
    onSuccess: (data) => {
      queryClient.refetchQueries()
    }
  });
}

const deleteStreet = (id) => {
  return AddressApi.DeleteStreet(id);
}

export const useStreetDelete = (id, type) => {
  const queryClient = useQueryClient()
  return useMutation(deleteStreet, {
    onSuccess: (data) => {
      queryClient.refetchQueries()
    }
  });
}
