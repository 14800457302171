import styled from 'styled-components';
import { Form } from 'antd';

const FormWrapperStyles = styled.div`
  .ant-input {
    min-height: 40px;
    border-radius: 8px;
    padding: 4px 11px;
  }

  .ant-switch-checked {
    background-color: var(--naviGreen);
  }
`;

export default function FormWrapper({ children, ...props }) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const form = props.form || Form.useForm()[0];

  return (
    <FormWrapperStyles>
      <Form layout="vertical" {...props} form={form}>
        {children}
      </Form>
    </FormWrapperStyles>
  );
}
