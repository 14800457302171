import { useIntl } from 'react-intl';

export const Gender = ({ gender }) => {
  const intl = useIntl();

  return gender === 1
    ? intl.formatMessage({ id: 'common.gender.male' })
    : gender === 0
    ? intl.formatMessage({ id: 'common.gender.female' })
    : null;
};
