import { useEffect } from 'react';
import { useQuery, useQueryClient, useMutation } from 'react-query';

import { featuredkeywordApi } from 'api/featuredkeywordApi';

export const FeaturedkeywordKey = {
  all: ['featuredkeyword'],
  lists: () => [...FeaturedkeywordKey.all, 'list'],
  list: (filter) => [...FeaturedkeywordKey.lists(), filter],
  details: () => [...FeaturedkeywordKey.all, 'detail'],
  detail: (id) => [...FeaturedkeywordKey.details(), id],
};

export const useFeaturedkeyword = ({ params, options }) => {
  return useQuery({
    queryKey: FeaturedkeywordKey.list(params),
    queryFn: () => featuredkeywordApi.getAll(params),
    ...options,
  });
};
//  action
const addfeaturedkeyword = async (value) => {
  await featuredkeywordApi.add(value);
  window.location.reload();
};

export const useAddFeaturedkeyword = ({ params, options }) => {
  const queryClient = useQueryClient();
  return useMutation(addfeaturedkeyword, {
    onSuccess: () => {
      queryClient.refetchQueries();
    },
  });
};

const editfeaturedkeyword = async (data) => {
  await featuredkeywordApi.edit(data);
  window.location.reload();
};
export const useDitFeaturedkeyword = ({ params, options }) => {
  const queryClient = useQueryClient();
  return useMutation(editfeaturedkeyword, {
    onSuccess: () => {
      queryClient.refetchQueries();
    },
  });
};
const deleteKeyword = (id) => {
  return featuredkeywordApi.delete(id);
};
export const useDeleteKeyword = ({ params, options }) => {
  const queryClient = useQueryClient();
  return useMutation(deleteKeyword, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: FeaturedkeywordKey.list(params),
        queryFn: () => featuredkeywordApi.getAll(params),
        ...options,
      });
    },
  });
};
export const usePrefetchCompanies = (data, queryParams) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    if (data?.link?.next) {
      const newQueryParams = { ...queryParams, page: queryParams.page + 1 };

      queryClient.prefetchQuery(FeaturedkeywordKey.list(newQueryParams), () =>
        featuredkeywordApi.getAll(newQueryParams),
      );
    }
  }, [data, queryParams, queryClient]);
};
