import { useIntl } from 'react-intl';
import { Avatar, Form, Input } from 'antd';

import { TitleWrapper, ContentWrapper } from 'components/layout';
import { TableWrapper } from 'components/common/table';
import { useCustomSearchParams } from 'hooks/useCustomSearchParams';
import { formatDate } from 'utils/format';
import { EnableStatus } from 'components/common/status';
import { Gender } from 'components/common/Gender';
import { useDeleteUser, useUsers } from './queries';
import { serializeSearchParams, calcIndexByOrder } from 'utils/common';
import { SearchForm } from 'components/form';
import { DEFAULT_PAGINATION } from 'constants/common';
import { EditOutlined } from '@ant-design/icons';
import {  useNavigate } from 'react-router-dom';
import { useState } from 'react';
import ButtonOther from 'components/common/button/ButtonOther';

export default function Users() {
  const intl = useIntl();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loadingReload, setLoadingReload] = useState(false);
  const [loadingDelete, setLoadingDetele] = useState(false);
  const [search, setSearch] = useCustomSearchParams();
  let navigate = useNavigate();
  const argument = {
    params: search,
    options: {
      keepPreviousData: true,
    },
  };

  const { data, isPreviousData, isFetching } = useUsers(argument);

  const { mutate } = useDeleteUser(argument);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const hasSelected = selectedRowKeys.length > 0;
  const deleteSelected =
    selectedRowKeys.length > 0 && selectedRowKeys.length < 2;
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: intl.formatMessage({ id: 'common.index' }),
      render: (_, __, index) =>
        calcIndexByOrder({ index, page: search.page, limit: search.page_size }),
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'views.users.avatar' }),
      dataIndex: 'avatar',
      render: (value) => <Avatar src={value} />,
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'views.users.name' }),
      dataIndex: 'name',
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'views.users.phone' }),
      dataIndex: 'phone',
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'views.users.email' }),
      dataIndex: 'email',
      align: 'center',
    },

    {
      title: intl.formatMessage({ id: 'views.users.sex' }),
      dataIndex: 'sex',
      align: 'center',
      render: (value) => <Gender gender={value} />,
    },
    {
      title: intl.formatMessage({ id: 'views.users.dateOfBirth' }),
      dataIndex: 'date_of_birth',
      render: (value) => formatDate(value),
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'common.status' }),
      dataIndex: 'enable',
      render: (value) => <EnableStatus status={!!value} />,
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'about_us.action' }),
      dataIndex: 'action',
      align: 'center',
      render: (_, value) => (
        <>
          <EditOutlined
            className={'action'}
            onClick={() => handleEdit(value.id)}
          />
        </>
      ),
    },
  ];

  const handleReload = () => {
    setLoadingReload(true); // ajax request after empty completing
    setTimeout(() => {
      setLoadingReload(false);
    }, 1000);
    setSelectedRowKeys([]);
  };

  const handleEdit = (id) => {
    navigate(`/users/edit/${id}`);
  };
  const handleDelete = () => {
    setLoadingDetele(true); // ajax request after empty completing
    setTimeout(() => {
      setLoadingDetele(false);
      mutate(selectedRowKeys[0]);
      setSelectedRowKeys([]);
    }, 1000);
    setSelectedRowKeys([]);
  };

  const renderExtra = (
    <>
      {/* <ButtonAddNew to={`${PATH_NAME.USERS}/${PATH_NAME_ACTION.CREATE}`} /> */}
      <ButtonOther
        type="default"
        onClick={handleDelete}
        disabled={!deleteSelected}
        loading={loadingDelete}
      >
        Xóa
      </ButtonOther>
      <ButtonOther
        type="default"
        onClick={handleReload}
        disabled={!hasSelected}
        loading={loadingReload}
      >
        Làm mới
      </ButtonOther>
    </>
  );
  const handleChange = ({ current, pageSize }) => {
    setSearch({ ...search, page: current, page_size: pageSize });
  };

  const handleSearch = (values) => {
    const params = serializeSearchParams({
      ...search,
      ...values,
      page: DEFAULT_PAGINATION.PAGE,
    });

    setSearch(params);
  };

  return (
    <>
      <TitleWrapper>
        {intl.formatMessage({ id: 'views.users.title.list' })}
      </TitleWrapper>

      <ContentWrapper>
        <SearchForm
          initialValues={{ search: search.search }}
          onFinish={handleSearch}
        >
          <Form.Item name="search">
            <Input placeholder={intl.formatMessage({ id: 'input.search' })} />
          </Form.Item>
        </SearchForm>
        <TableWrapper
          rowKey="id"
          columns={columns}
          dataSource={data?.data || []}
          loading={isPreviousData && isFetching}
          rowSelection={rowSelection}
          pagination={{
            position: ['bottomCenter'],
            current: search.page,
            pageSize: search.page_size,
            total: data?.meta?.total || 0,
          }}
          onChange={handleChange}
          extra={renderExtra}
        />
      </ContentWrapper>
    </>
  );
}
