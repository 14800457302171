
import { useIntl } from 'react-intl';


function useManager() {
  const intl = useIntl();
  const columns = [
    {
      title: intl.formatMessage({ id: 'company.manager' }),
      dataIndex: 'manager',
      align: 'center',
      width: 200,
    },
    {
      title: intl.formatMessage({ id: 'company.manager_phone' }),
      dataIndex: 'manager_phone',
      align: 'center',
      width: 200,
    },
  ]
  return { columns };
}

export default useManager;
