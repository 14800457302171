import React, { useEffect, useRef } from 'react';
import { Form, Input, Col, Select } from 'antd';

import { useSelectCompanytype } from '../../companyTypes/queries';
import { useSelectCompanies } from '../queries';
import { isIncludeTextSearch } from 'utils';

const { TextArea } = Input;
const { Option } = Select;

const Acolumn = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

function FormCompanies({ type, parent, form, setObjectFormFetch }) {
  const argument = {
    options: {
      keepPreviousData: true,
    },
  };
  const keyword = useRef();
  const {
    data: Company,
    isLoading: isLoadingCompany,
    isFetched: isFetchedCompany,
  } = useSelectCompanies({
    ...argument,
    name: parent,
  });
  const { data, isLoading, isFetched } = useSelectCompanytype({
    ...argument,
    name: type,
  });

  useEffect(() => {
    if (!isLoadingCompany && isFetchedCompany) {
      form.current.setFieldsValue({
        parent_id: Company.index,
      });
      setObjectFormFetch((prev) => {
        return {
          ...prev,
          parent_id: Company.index,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Company, isLoadingCompany, isFetchedCompany]);
  useEffect(() => {
    if (!isLoading && isFetched) {
      form.current.setFieldsValue({
        type_id: data.index,
      });
      setObjectFormFetch((prev) => {
        return {
          ...prev,
          type_id: data.index,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetched]);
  return (
    <>
      <Col span={24}>
        <Form.Item
          name="name"
          label="Tên công ty"
          rules={[
            {
              required: true,
              message: 'không để trường này trống',
            },
          ]}
          {...Acolumn}
          style={{
            width: '98%',
          }}
        >
          <Input ref={keyword} placeholder="Nhập tên công ty" />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name="description"
          label="Mô tả công ty"
          {...Acolumn}
          style={{
            width: '98%',
          }}
        >
          <TextArea
            placeholder="Nhập tên mô tả công ty"
            autoSize={{
              minRows: 3,
              maxRows: 10,
            }}
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name="work_time"
          label="Thời gian làm việc"
          rules={[
            {
              required: false,
              message: 'không để trường này trống',
            },
          ]}
          {...Acolumn}
          style={{
            width: '98%',
          }}
        >
          <Input placeholder="Nhập Thời gian làm việc" />
        </Form.Item>
      </Col>
      {!isLoading && isFetched ? (
        <Col span={24}>
          <Form.Item
            name="type_id"
            label="Loại công ty"
            style={{
              width: '98%',
            }}
            {...Acolumn}
          >
            <Select
              showSearch
              placeholder="Chọn loại công ty"
              optionFilterProp="children"
              filterOption={(input, option) =>
                isIncludeTextSearch(input, option.children)
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {data.data.map((CompanyType) => (
                <Option value={CompanyType.id} key={CompanyType.id}>
                  {CompanyType.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      ) : (
        false
      )}

      {!isLoadingCompany && isFetchedCompany ? (
        <Col span={24}>
          <Form.Item
            name="parent_id"
            label="Thuộc công ty"
            {...Acolumn}
            style={{
              width: '98%',
            }}
          >
            <Select
              showSearch
              placeholder="Thuộc công ty"
              optionFilterProp="children"
              filterOption={(input, option) =>
                isIncludeTextSearch(input, option.children)
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {Company.data.map((Company) => (
                <Option value={Company.id} key={Company.id}>
                  {Company.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      ) : (
        false
      )}
    </>
  );
}

export default FormCompanies;
