import styled from "styled-components";

export const PreviewAdItemContainer = styled.div`
  padding: 4px 8px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => "#D3D3D3"};
  border-radius: 4px;

  position: relative;

  h3.ant-typography {
    font-size: 16px;
    font-weight: 400;
    color: ${({ theme }) => "#D2A10A !important"};
    transition: all 0.3s ease;
    margin-bottom: 3px;

    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  .info {
    font-size: 12px;
    color: ${({ theme }) => "#D3D3D3"};

    > span {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  .ad-box {
    border: 1px solid ${({ theme }) => "#D2A10A"};
    border-radius: 4px;
    color: #D3D3D3 !important;
    font-size: 10px;
    position: absolute;
    right: 5px;
    top: 5px;
    padding-left: 2px;
    padding-right: 2px;

  }
`;

export const PreviewAdItemTitle = styled.p``;
