import { TableWrapper } from 'components';
import { useCustomSearchParams } from 'hooks/useCustomSearchParams';
import { useIntl } from 'react-intl';
import { calcIndexByOrder } from 'utils/common';

const ModalError = ({ data }) => {
  const intl = useIntl();
  const [search, setSearch] = useCustomSearchParams();

  // table
  const columns = [
    {
      title: intl.formatMessage({ id: 'common.index' }),
      render: (_, __, index) =>
        calcIndexByOrder({ index, page: search.page, limit: 5 }),
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'company.name' }),
      dataIndex: 'name',
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'company.address' }),
      dataIndex: 'address',
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'company.phone' }),
      dataIndex: 'phone',
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'company.manager' }),
      dataIndex: 'manager',
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'company.action' }),
      dataIndex: 'error',
      align: 'center',
    },
  ];

  const handleChange = ({ current, pageSize }) =>
    setSearch({ ...search, page: current, page_size: pageSize });
  const renderExtra = <></>;

  return (
    <>
      <TableWrapper
        rowKey="id"
        columns={columns}
        dataSource={data?.address_not_found}
        pagination={{
          current: search.page,
          pageSize: 5,
          total: data?.meta?.total ?? 0,
          position: ['bottomCenter'],
        }}
        onChange={handleChange}
        extra={renderExtra}
      />
    </>
  );
};

export default ModalError;
