import styled from 'styled-components';

const TitleStyles = styled.div`
  padding: 7px;
  background-color: var(--grayDark);
  text-align: center;
  text-transform: uppercase;
  color: var(--white);

  .mainTitle {
    font-size: 20px;
    font-weight: 700;
  }
`;

export default function Title({ children }) {
  return (
    <TitleStyles>
      {typeof children === 'string' ? (
        <h3 className="mainTitle">{children}</h3>
      ) : (
        children
      )}
    </TitleStyles>
  );
}
