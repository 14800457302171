import _ from 'lodash';
import removeVietnameseTones from './stringUtils2';

export const formatFullAddress = (
  address,
  streetName,
  wardName,
  districtName,
  provinceName
) => {
  const joinAddress = [streetName, wardName, districtName, provinceName]
    .filter((item) => item)
    .join(', ');

  return `${address} ${joinAddress}`;
};

// export const serializeSearchParams = (params) => {
//   return _.omitBy(params, _.isNil);
// };

export const serializeSearchParams = (params) => {
  return Object.fromEntries(
    Object.entries(params).filter(([_, value]) => value)
  );
};

export const isIncludeTextSearch = (input, option) => {
  const NewInput = removeVietnameseTones(input).toLowerCase();
  const NewOption = removeVietnameseTones(
    option
  ).toLowerCase();
  return NewOption.indexOf(NewInput) > -1;
};
