import axiosClient from './axiosClient';

export const topicApi = {
  getAll(params) {
    return axiosClient.get('/admin/topics/', { params });
  },
  delete(ids) {
    return axiosClient.delete(`/admin/topics`, { data: { ids: ids } });
  },
  add(keyword) {
    return axiosClient.post(`/admin/topics`, keyword);
  },
  edit({ data, id }) {
    return axiosClient.patch(`/admin/topics/${id}`, data);
  },
  get(id) {
    return axiosClient.get(`/admin/topics/${id}`);
  }
};
