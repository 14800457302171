import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Table, Space, Typography } from 'antd';
import { TotalIcon } from 'assets/icons';

const TableWrapperStyles = styled.div`
  .ant-table-thead > tr > th {
    color: #fbfbfb;
    background-color: #007575;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    box-shadow: inset -3px 0px 0px #dfd0d0;
    border-bottom: 4px solid #dfd0d0;
  }
  .ant-table {
    background: transparent;
  }
  .ant-table-tbody > tr > td {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    box-shadow: inset -3px 0px 0px #dfd0d0;
    border-bottom: 4px solid rgba(0, 0, 0, 0.25);
  }
  .ant-pagination-item-active {
    background-color: var(--black);
    border-color: var(--black);

    a {
      color: var(--white);
    }
  }

  .ant-table-title {
    border: none !important;

    > .ant-space {
      width: 100%;
      display: flex;
      justify-content: center;
      > .ant-space-item {
        display: flex;
        .ant-space {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: var(--black);
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--black);
    border-color: var(--black);
  }
  .ant-space.extra {
    margin-bottom: 28px;
    display: flex;
    width: 100%;
    justify-content: flex-end !important;
  }
  .table table {
    // padding: 0px 130px;
  }
  .total {
    color: ${({ theme }) => theme.colors.primary};
    width: 100%;
    margin-bottom: 28px;
    display: flex;
    align-items: center;
    .ant-space-item {
      display: flex;
      align-items: center;
    }
    .text {
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export default function TableWrapper({ extra, Hideheader = false, ...props }) {
  const intl = useIntl();

  return (
    <TableWrapperStyles>
      {/*
        TODO:
          - Action button
          - Delete records
       */}

      <Table
        className="table"
        title={() => (
          <>
            {!Hideheader && extra && (
              <>
                <Space className="extra">{extra}</Space>
                <Space>
                  <Space className={'total'}>
                    <TotalIcon className={'icon'} />
                    <Typography.Text className="text">
                      {intl.formatMessage({ id: 'table.total' })}:{' '}
                      {props.pagination?.total || props.dataSource.length}
                    </Typography.Text>
                  </Space>
                </Space>
              </>
            )}
          </>
        )}
        bordered
        size="small"
        {...props}
      />
    </TableWrapperStyles>
  );
}
