import { Select } from "antd";
import { useState } from "react";

export const VISIBLE_TIME_RANGE = [
  {
    value: "1-24",
    label: "All",
  },
  {
    value: "1-2",
    label: "1h -> 2h",
  },
  {
    value: "2-3",
    label: "2h -> 3h",
  },
  {
    value: "3-4",
    label: "3h -> 4h",
  },
  {
    value: "4-5",
    label: "4h -> 5h",
  },

  {
    value: "6-7",
    label: "7h -> 8h",
  },
  {
    value: "8-9",
    label: "8h -> 9h",
  },
  {
    value: "9-10",
    label: "9h -> 10h",
  },
  {
    value: "10-11",
    label: "10h -> 11h",
  },
  {
    value: "11-12",
    label: "11h -> 12h",
  },
  {
    value: "13-14",
    label: "13h -> 14h",
  },
  {
    value: "14-15",
    label: "14h -> 14h",
  },
  {
    value: "15-16",
    label: "15h -> 16h",
  },
  {
    value: "16-17",
    label: "16h -> 17h",
  },
  {
    value: "17-18",
    label: "17h -> 18h",
  },
  {
    value: "18-19",
    label: "18h -> 19h",
  },
  {
    value: "19-20",
    label: "19h -> 20h",
  },
  {
    value: "20-21",
    label: "20h -> 21h",
  },
  {
    value: "21-22",
    label: "21h -> 22h",
  },
  {
    value: "22-23",
    label: "22h -> 23h",
  },
  {
    value: "23-24",
    label: "23h -> 24h",
  },
  {
    value: "24-1",
    label: "24h -> 1h",
  },
];

export default function InputPickVisibleTime({ value: inputValue, onChange, ...rest }) {
  const [value, setValue] = useState([]);

  const handleOnChange = (val) => {
    const lastChoose = val[val.length - 1];
    if (lastChoose === "1-24") {
      setValue(VISIBLE_TIME_RANGE[0].value);
      onChange?.(VISIBLE_TIME_RANGE[0].value);
      return;
    }

    if (
      lastChoose !== "1-24" &&
      !!val.find((i) => i === "1-24") &&
      val.length > 1
    ) {
      const valWithoutAll = val.filter((i) => i != "1-24");
      setValue(valWithoutAll);
      onChange?.(valWithoutAll);
      return;
    }

    setValue(val);
    onChange?.(val);
  };

  return (
    <div>
      <Select
        mode="multiple"
        allowClear
        style={{ width: "100%" }}
        placeholder="Please select"
        defaultValue={["all"]}
        onChange={handleOnChange}
        options={VISIBLE_TIME_RANGE}
        value={value}
        {...rest}
      />
    </div>
  );
}
