
import { useIntl } from 'react-intl';


function useUser() {
  const intl = useIntl();
  const columns = [
    {
      title: intl.formatMessage({ id: 'company.evaluate' }),
      dataIndex: 'star_number',
      align: 'center',
      width: 200,
    },
  ]
  return { columns };
}

export default useUser;
