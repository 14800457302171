import { useEffect } from 'react';
import { useQuery, useQueryClient, useMutation } from 'react-query';

import { companyApi } from 'api/companyApi';
import publicApi from 'api/publicApi';

export const companyKeys = {
  all: ['companies'],
  lists: () => [...companyKeys.all, 'list'],
  list: (filter) => [...companyKeys.lists(), filter],
  details: () => [...companyKeys.all, 'detail'],
  detail: (id) => [...companyKeys.details(), id],
};

// const deletecompantKeys = (ids) => {
//   return companyApi.delete(ids);
// };

export const useCompanies = ({ params, options }) => {
  return useQuery({
    queryKey: companyKeys.list(params),
    queryFn: () => companyApi.getAll(params),
    select: (data) => {
      return data || [];
    },
    ...options,
  });
};

export const useAdminList = ({ options }) => {
  return useQuery({
    queryKey: companyKeys.list('useadminList'),
    queryFn: () => publicApi.adminList(),
    select: (data) => {
      return data || [];
    },
    ...options,
  });
};

const enableCompanies = (item) => {
  return companyApi.update(item, item.id);
};

export const useEnableCompanies = ({ params, options }) => {
  return useMutation(enableCompanies, {
    queryKey: companyKeys.list(params),
    queryFn: () => companyApi.getAll(params),
    select: (data) => {
      return data || [];
    },
    ...options,
  });
};
export const useSelectCompanies = ({ params, options, name }) => {
  return useQuery({
    queryKey: companyKeys.list(params),
    queryFn: () => companyApi.getAll(params),
    select: (data) => {
      const index = data?.data.findIndex((type) => type.name === name);
      return {
        data: data?.data,
        index: index > -1 ? data?.data[index].id : null,
      };
    },
    options: {},
  });
};
// export const useDeleteCompanies = ({ params, options }) => {
//   const queryClient = useQueryClient();
//   return useMutation(deletecompantKeys, {
//     onSuccess: (data) => {
//       queryClient.refetchQueries();
//     },
//   });
// };

const deletesCompany = (ids) => {
  return companyApi.delete(ids);
};
export const useDeletesCompany = ({ params, options }) => {
  const queryClient = useQueryClient();
  return useMutation(deletesCompany, {
    onSuccess: (data) => {
      queryClient.refetchQueries();
    },
  });
};
export const usePrefetchCompanies = (data, queryParams) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    if (data?.link?.next) {
      const newQueryParams = { ...queryParams, page: queryParams.page + 1 };

      queryClient.prefetchQuery(companyKeys.list(newQueryParams), () =>
        companyApi.getAll(newQueryParams),
      );
    }
  }, [data, queryParams, queryClient]);
};
