import publicApi from 'api/publicApi';
import { tagPublicApi } from 'api/tagPublicApi';
import useSWR from 'swr';

export const useProvinces = (params) => {
  const result = useSWR(
    ['/public/provinces', params],
    () => publicApi.getAllProvinces(params),
    {
      dedupingInterval: 60 * 60 * 1000,
    },
  );

  return {
    ...result,
    isLoading: !result.error && !result.data,
    isError: result.error,
  };
};

export const useTags = ({ options, type }) => {
  const { data, error } = useSWR(
    type ? `/public/tags?type=${type}` : null,
    () => tagPublicApi.getAll(type),
    {
      dedupingInterval: 60 * 60 * 1000, // 1hr
      ...options,
    },
  );

  const loading = data === undefined && error === undefined;
  return {
    loading,
    data,
    error,
  };
};
