import styled from "styled-components";
import {useCustomSearchParams} from "../../hooks/useCustomSearchParams";
import {useIntl} from "react-intl";
import {calcIndexByOrder, serializeSearchParams} from "../../utils";
import {EyeOutlined} from "@ant-design/icons";
import {ContentWrapper, TitleWrapper} from "../../components/layout";
import {TableWrapper} from "../../components";
import {usePaymentTransaction} from "../../hooks/usePaymentTransaction";
import {useEffect} from "react";
import {Form, Input, Tag} from "antd";
import {SearchForm} from "../../components/form";
import {DEFAULT_PAGINATION} from "../../constants/common";
import {useNavigate} from "react-router-dom";

const TransactionManagementContainer = styled.div`

  .action {
    display: flex;
    justify-content: center;
  }
  .action__icon {
    margin: 0px 5px;
  }
  .action:hover {
    opacity: 0.7;
    cursor: pointer;
  }
  .ant-table-wrapper {
  }
  .action__box {
    padding: 2px 1px;
    border-radius: 2px;
  }
  .action__edit {
    background: #fff;
    margin-right: 5px;
    border: 1px solid #333;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  }
  .action__delete {
    color: #fff;
    background: #ff4d4f;
    border-color: #ff4d4f;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  }
  .image {
    height: 100px;
    width: 100px;
  }
  .boxLoading {
    height: 100%;
    width: 100%;
    justify-content: center;
    margin-top: 100px;
  }
`

function  Transactionmanagement (props) {
  const intl = useIntl();
  const [search, setSearch] = useCustomSearchParams();
  let navigate = useNavigate();

  const argument = {
    params: search,
    options: {
      keepPreviousData: true,
    },
  };

  const { data, loading, fetch } = usePaymentTransaction()
  // Fetch Data First Time
  useEffect(() => {
    fetch(argument);
  }, [])

  const handleSearch = (values) => {
    const params = serializeSearchParams({
      ...search,
      ...values,
      page: DEFAULT_PAGINATION.PAGE,
    });
    setSearch(params);
    fetch({params});
  };

  const handleViewTransactionDetail = (id) => {
    navigate(`/transactionmanagement/view/${id}`);
  }

  // Column Table
  const columns = [
    {
      title: intl.formatMessage({ id: 'transactionmanagement.index' }),
      dataIndex: 'index',
      align: 'center',
      render: (_, __, index) =>
        calcIndexByOrder({ index, page: search.page, limit: search.page_size }),
    },
    {
      title: intl.formatMessage({ id: 'transactionmanagement.code' }),
      dataIndex: 'code',
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'transactionmanagement.user' }),
      dataIndex: 'user',
      align: 'center',
      render: (_, values) => (
        <a style={{textTransform: 'capitalize'}} href={`users/edit/${values?.user?.id}`}>
          {values?.user?.name}
        </a>
      ),
    },
    {
      title: intl.formatMessage({ id: 'transactionmanagement.amount' }),
      dataIndex: 'amount',
      align: 'center',
      render: (_, value) => (
        <div>
          {value.amount.toLocaleString('en-US')}
        </div>
      ),
    },
    {
      title: intl.formatMessage({ id: 'transactionmanagement.type' }),
      dataIndex: 'type',
      align: 'center',
      render: (_, value) => (
        <div>
          {value.type == "deposit" ? "Nạp Tiền" : "Thanh Toán"}
        </div>
      ),
    },
    {
      title: intl.formatMessage({ id: 'transactionmanagement.status' }),
      dataIndex: 'status',
      align: 'center',
      render: (_, value) => (
        <div>
          {
            value?.status === 'completed' ? <Tag color="#87d068">Thành công</Tag>
              : value?.status === 'pending' ? <Tag color="#0000000a">pending</Tag>
              : value?.status === 'processing' ? <Tag color="#2db7f5">Đang xử lý</Tag>
                : <Tag color="#f50">Bị Huỷ</Tag>
          }
        </div>
      ),
    },
    {
      title: intl.formatMessage({ id: 'transactionmanagement.created_at' }),
      dataIndex: 'created_at',
      align: 'center',
      render: (_, value) => (
        <div>
          {value?.created_at}
        </div>
      ),
    },
    {
      title: intl.formatMessage({ id: 'transactionmanagement.action' }),
      dataIndex: 'action',
      align: 'center',
      render: (_, value) => (
        <div className="action">
          <div className="action__edit action__box">
            <EyeOutlined
              className={'action__icon'}
              onClick={() => handleViewTransactionDetail(value.id)}
            />
          </div>
        </div>
      ),
    },
  ];

  return(
    <TransactionManagementContainer>
      <TitleWrapper>
        {intl.formatMessage({ id: 'views.transactionmanagement.title.list' })}
      </TitleWrapper>
      <ContentWrapper>

        <SearchForm
          initialValues={{ search: search.search }}
          onFinish={handleSearch}
        >
          <Form.Item name="search">
            <Input placeholder={intl.formatMessage({ id: 'input.search' })} />
          </Form.Item>
        </SearchForm>

        <TableWrapper
          rowKey="id"
          columns={columns}
          dataSource={data?.data || []}
          loading={loading}
          rowSelection={1}
          pagination={{
            current: search.page,
            pageSize: search.page_size,
            total: data?.total,
            position: ['bottomCenter'],
          }}
          onChange={() => console.log("AAA")}
          extra={<></>}
        />
      </ContentWrapper>
    </TransactionManagementContainer>
  )
}

export default Transactionmanagement
