import styled from 'styled-components';
import { Collapse, Descriptions, Space, Typography } from 'antd';

import { capitalizeFirstLetter, formatTime, formatterCurrency } from '../utils';

const PendingWagerItemContainer = styled(Collapse.Panel)`
  > .ant-collapse-header {
    align-items: center !important;

    .ant-collapse-extra {
      .ant-space-item {
        min-width: 200px;
        text-align: right;
      }
    }
  }

  .ant-descriptions-header {
    margin-bottom: 0;

    .ant-descriptions-title {
      padding: 8px 12px;
      color: var(--white);
      background-color: var(--naviGreen);
    }
  }
`;

export default function PendingWagerItem({ item, ...props }) {
  return (
    <PendingWagerItemContainer
      {...props}
      showArrow={false}
      key={item.sid}
      header={
        <Typography.Text strong type="danger" style={{ fontSize: 18 }}>
          {item.data.name}
        </Typography.Text>
      }
      extra={
        <Space>
          <Typography.Text strong type="danger">
            {formatterCurrency.format(item.risk)}
          </Typography.Text>
          <Typography.Text strong>
            {formatterCurrency.format(item.win)}
          </Typography.Text>
        </Space>
      }
    >
      <Descriptions
        column={1}
        bordered
        labelStyle={{ width: 300 }}
        size="small"
        title={
          <>
            {item.odd.match.team1}({item.odd.match.team1_initials}) -{' '}
            {item.odd.match.team2}({item.odd.match.team2_initials})
          </>
        }
      >
        <Descriptions.Item label="Ticket #">{item.ticket}</Descriptions.Item>
        <Descriptions.Item label="Sport">
          {capitalizeFirstLetter(item.odd.match.league.sport.name)} -{' '}
          {item.odd.match.league.name}
        </Descriptions.Item>
        <Descriptions.Item label="Created at">
          {formatTime(item.created_at)}
        </Descriptions.Item>
        <Descriptions.Item label="Risk/Win">
          <Typography.Text strong type="danger">
            {formatterCurrency.format(item.risk)}
          </Typography.Text>
          {' / '}
          <Typography.Text strong type="success">
            {formatterCurrency.format(item.win)}
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item label="Outcome">
          {capitalizeFirstLetter(item.outcome)}
        </Descriptions.Item>
      </Descriptions>
    </PendingWagerItemContainer>
  );
}
