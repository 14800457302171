import { useIntl } from 'react-intl';
import { useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Switch } from 'antd';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { TitleWrapper, ContentWrapper } from 'components/layout';

import { useCustomSearchParams } from 'hooks/useCustomSearchParams';
import { useDeletenews, useNews, useEnablenews } from './queries';
import { ButtonAddNew } from 'components/common/button';
import { calcIndexByOrder } from 'utils/common';
import { TableWrapper } from 'components';
import ButtonOther from 'components/common/button/ButtonOther';
import { PATH_NAME, PATH_NAME_ACTION } from 'constants/routes';

const FeatureKeyWordStyles = styled.div`
  .action {
    display: flex;
    justify-content: center;
  }
  .action__icon {
    margin: 0px 5px;
  }
  .action:hover {
    opacity: 0.7;
    cursor: pointer;
  }
  .ant-table-wrapper {
  }
  .action__box {
    padding: 2px 1px;
    border-radius: 2px;
  }
  .action__edit {
    background: #fff;
    margin-right: 5px;
    border: 1px solid #333;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  }
  .action__delete {
    color: #fff;
    background: #ff4d4f;
    border-color: #ff4d4f;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  }
  .image {
    height: 100px;
    width: 100px;
  }
`;
function Featuredkeyword(props) {
  const intl = useIntl();
  const [search, setSearch] = useCustomSearchParams();
  let navigate = useNavigate();
  const argument = {
    params: search,
    options: {
      keepPreviousData: true,
    },
  };
  const { mutate: MutateDelete } = useDeletenews(argument);
  const { mutate: MutateEnable } = useEnablenews(argument);
  //

  const { data, isPreviousData, isFetching } = useNews(argument);
  const columns = [
    {
      title: intl.formatMessage({ id: 'newsmanagement.index' }),
      dataIndex: 'index',
      align: 'center',
      render: (_, __, index) =>
        calcIndexByOrder({ index, page: search.page, limit: search.page_size }),
    },
    {
      title: intl.formatMessage({ id: 'newsmanagement.title' }),
      dataIndex: 'title',
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'newsmanagement.avatar' }),
      dataIndex: 'avatar',
      align: 'center',
      render: (_, values) => (
        <img src={values.avatar} alt={''} className="image" />
      ),
    },
    {
      title: intl.formatMessage({ id: 'newsmanagement.action' }),
      dataIndex: 'detail',
      align: 'center',
      render: (_, value) => (
        <div className="action">
          <div className="action__edit action__box">
            <EditOutlined
              className={'action__icon'}
              onClick={() => handleEdit(value.id)}
            />
          </div>
        </div>
      ),
    },
    {
      title: intl.formatMessage({ id: 'newsmanagement.show' }),
      dataIndex: 'detail',
      align: 'center',
      render: (_, item) => (
        <>
          <Switch
            defaultChecked={item.enable}
            onChange={() => onChange(item.id)}
          />
        </>
      ),
    },
  ];
  //  table
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loadingReload, setLoadingReload] = useState(false);
  const [loadingDelete, setLoadingDetele] = useState(false);
  const hasSelected = selectedRowKeys.length > 0;
  const deleteSelected =
    selectedRowKeys.length > 0 && selectedRowKeys.length < 2;
  const handleReload = () => {
    setLoadingReload(true); // ajax request after empty completing
    setTimeout(() => {
      setLoadingReload(false);
    }, 1000);
    setSelectedRowKeys([]);
  };
  const handleDelete = () => {
    setLoadingDetele(true); // ajax request after empty completing
    setTimeout(() => {
      setLoadingDetele(false);
      MutateDelete(selectedRowKeys[0]);
      setSelectedRowKeys([]);
    }, 1000);
    setSelectedRowKeys([]);
  };
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const onChange = (id) => {
    MutateEnable(id);
  };
  const handleEdit = (id) => {
    navigate(`/newsmanagement/edit/${id}`);
  };
  const handleChange = ({ current, pageSize }) =>
    setSearch({ ...search, page: current, page_size: pageSize });
  const renderExtra = (
    <>
      <ButtonAddNew
        to={`${PATH_NAME.NEWSMANAGEMENT}/${PATH_NAME_ACTION.CREATE}`}
      />
      <ButtonOther
        type="default"
        onClick={handleDelete}
        disabled={!deleteSelected}
        loading={loadingDelete}
      >
        Xóa
      </ButtonOther>
      <ButtonOther
        type="default"
        onClick={handleReload}
        disabled={!hasSelected}
        loading={loadingReload}
      >
        Làm mới
      </ButtonOther>
    </>
  );
  return (
    <>
      <FeatureKeyWordStyles>
        <TitleWrapper>
          {intl.formatMessage({ id: 'views.newsmanagement.title.list' })}
        </TitleWrapper>
        <ContentWrapper>
          <TableWrapper
            rowKey="id"
            columns={columns}
            dataSource={data?.data || []}
            loading={isPreviousData && isFetching}
            rowSelection={rowSelection}
            pagination={{
              current: search.page,
              pageSize: search.page_size,
              total: data?.meta?.total ?? 0,
              position: ['bottomCenter'],
            }}
            onChange={handleChange}
            extra={renderExtra}
          />
        </ContentWrapper>
      </FeatureKeyWordStyles>
    </>
  );
}

export default Featuredkeyword;
