import axiosClient from './axiosClient';

const publicApi = {
  adminList() {
    return axiosClient.get('/public/admin/lists');
  },
  getAllProvinces(params) {
    return axiosClient.get('/province', { params });
  },
};

export default publicApi;
