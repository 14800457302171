import axiosClient from './axiosClient';


export const settingsApi = {
  getAll() {
    return axiosClient.get('/admin/settings');
  },

  get(id) {
    return axiosClient.get(`/admin/settings/${id}`);
  },

  add(data) {
    return axiosClient.post(`/admin/settings`, data);
  },

  edit(id, data) {
    return axiosClient.patch(`/admin/settings/${id}`, data);
  },

  delete(id) {
    return axiosClient.delete(`/admin/settings/${id}`);
  }
}