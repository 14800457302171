
import { useIntl } from 'react-intl';


function useAddress() {
  const intl = useIntl();
  const columns = [
    {
      title: intl.formatMessage({ id: 'company.address' }),
      dataIndex: 'address',
      align: 'center',
      width: 400,
    },
    {
      title: intl.formatMessage({ id: 'company.street' }),
      dataIndex: ['street', 'name'],
      align: 'center',
      width: 200,
    },
    {
      title: intl.formatMessage({ id: 'company.ward' }),
      dataIndex: ['ward', 'name'],
      align: 'center',
      width: 200,
    },
    {
      title: intl.formatMessage({ id: 'company.district' }),
      dataIndex: ['district', 'name'],
      align: 'center',
      width: 150,
    },
    {
      title: intl.formatMessage({ id: 'company.province' }),
      dataIndex: ['province', 'name'],
      align: 'center',
      width: 200,
    },
  ]
  return { columns };
}

export default useAddress;
