import styled from 'styled-components';

const FilterLocationItemStyles = styled.div`
  padding: 4px 12px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #fafafa;
  }
`;

export const FilterLocationItem = (props) => {
  return <FilterLocationItemStyles {...props} />;
};
