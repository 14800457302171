import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import { ThemeProvider } from 'styled-components';
import flatten from 'flat';
import moment from 'moment';
import { Routes, Route, Navigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import THEME from 'config/themes';
import AppLocale from 'config/translations';
import { validateMessages } from 'constants/common';
import { PATH_NAME } from 'constants/routes';
import GlobalStyle from './globalStyle';
import { PrivateRoute } from 'components';

// Companies

import Companies from './views/companies/Companies';
import AddCompanies from './views/companies/add/AddCompanies';

// TODO:
import DashboardLayout from './layout/DashboardLayout';
import AuthLayout from './layout/AuthLayout';
import SignIn from './views/auth/SignIn';
import Users from './views/users/Users';

// Address
import Address from './views/address/Address';
import Province from 'views/address/province/province';
import AddProvince from './views/address/province/add/AddProvince';
import District from 'views/address/district/district';
import AddDistrict from 'views/address/district/add/Adddistrict';
import Ward from 'views/address/ward/Ward';
import AddWard from 'views/address/ward/add/AddWard';
import Street from 'views/address/street/Street';
import AddStreet from 'views/address/street/add/AddStreet';

// FeatureKeyWord
import Featuredkeyword from 'views/featuredkeywords/Featuredkeyword';
import AddFeatureKeyWords from 'views/featuredkeywords/add/AddFeatureKeyWords';

// Newsmanagement
import Newsmanagement from 'views/newsmanagement/Newsmanagement';
import ADDNewsmanagement from 'views/newsmanagement/add/Addnewsmanagement';

// Transactionmanagement
import Transactionmanagement from 'views/transaction/Transactionmanagement';
import PaymentTransactionDetail from 'views/transaction/view/ViewTransactionmanagement';

import AboutUs from './views/aboutus/AboutUs';
import Footer from './views/footer/Footer';
import AddFooter from './views/footer/add/AddFooter';

import AddAboutUs from './views/aboutus/add/AddAboutUs';
//News Categories
import NewsCategories from './views/newsCategories/NewsCategories';
import AddNewsCategories from './views/newsCategories/add/AddNewsCategories';

// category
import Category from 'views/category/Category';
import AddCategory from 'views/category/add/AddCategory';

// companytype
import Categorytype from 'views/companyTypes/CompanyType';

// companyconfirm
import CompanyConfirm from 'views/companyConfirm/CompanyConfirm';
import AddCompanyConfirm from 'views/companyConfirm/add/Addcompanyconfirm';

// Building
import Building from 'views/address/building/building';
import Addbuilding from 'views/address/building/add/Addbuilding';

// PATH_NAME_ACTION
import { PATH_NAME_ACTION } from './constants/routes';
import AddUser from 'views/users/add/AddUser';

// PATH_NAME_ACTION
import Blogs from './views/blogs/Blogs';
import AddBlog from 'views/blogs/AddBlog';

// ADS MANAGER
import AdManager from './views/adManager';
import AddAd from './views/adManager/add/AddAd';

// Topic

import Topic from 'views/topic/Topic';

// Tag
import Tag from 'views/tag/Tag';
import Settingsmanagement from 'views/settings/Settingsmanagement';
import AddSetting from 'views/settings/modal/AddSetting';

// test

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

function App() {
  const locale = 'vi';

  moment.locale(locale);

  return (
    <ConfigProvider
      locale={AppLocale[locale].antd}
      form={{ validateMessages: validateMessages[locale] }}
      // componentSize="small"
    >
      <IntlProvider
        locale={locale}
        messages={flatten(AppLocale[locale].messages)}
      >
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={THEME}>
            <GlobalStyle />

            <Routes>
              <Route path="/" element={<PrivateRoute />}>
                <Route element={<DashboardLayout />}>
                  <Route
                    index
                    element={<Navigate to={PATH_NAME.COMPANIES} />}
                  />
                  {/* COMPANIES CONSCIOUS */}
                  <Route path={PATH_NAME.ADDRESS.index}>
                    <Route index element={<Address />} />
                    <Route
                      path={`/${PATH_NAME.ADDRESS.index}/${PATH_NAME.ADDRESS.province}`}
                      element={<Province />}
                    />
                    <Route
                      path={`/${PATH_NAME.ADDRESS.index}/${PATH_NAME_ACTION.CREATE}/${PATH_NAME.ADDRESS.province}`}
                      element={<AddProvince />}
                    />
                    <Route
                      path={`/${PATH_NAME.ADDRESS.index}/${PATH_NAME_ACTION.EDIT}/${PATH_NAME.ADDRESS.province}/:id`}
                      element={<AddProvince />}
                    />
                    <Route
                      path={`/${PATH_NAME.ADDRESS.index}/${PATH_NAME.ADDRESS.district}`}
                      element={<District />}
                    />
                    <Route
                      path={`/${PATH_NAME.ADDRESS.index}/${PATH_NAME_ACTION.CREATE}/${PATH_NAME.ADDRESS.district}`}
                      element={<AddDistrict />}
                    />
                    <Route
                      path={`/${PATH_NAME.ADDRESS.index}/${PATH_NAME_ACTION.EDIT}/${PATH_NAME.ADDRESS.district}/:id`}
                      element={<AddDistrict />}
                    />
                    <Route
                      path={`/${PATH_NAME.ADDRESS.index}/${PATH_NAME.ADDRESS.ward}`}
                      element={<Ward />}
                    />
                    <Route
                      path={`/${PATH_NAME.ADDRESS.index}/${PATH_NAME_ACTION.CREATE}/${PATH_NAME.ADDRESS.ward}`}
                      element={<AddWard />}
                    />
                    <Route
                      path={`/${PATH_NAME.ADDRESS.index}/${PATH_NAME_ACTION.EDIT}/${PATH_NAME.ADDRESS.ward}/:id`}
                      element={<AddWard />}
                    />
                    <Route
                      path={`/${PATH_NAME.ADDRESS.index}/${PATH_NAME.ADDRESS.street}`}
                      element={<Street />}
                    />
                    <Route
                      path={`/${PATH_NAME.ADDRESS.index}/${PATH_NAME_ACTION.CREATE}/${PATH_NAME.ADDRESS.street}`}
                      element={<AddStreet />}
                    />
                    <Route
                      path={`/${PATH_NAME.ADDRESS.index}/${PATH_NAME_ACTION.EDIT}/${PATH_NAME.ADDRESS.street}/:id`}
                      element={<AddStreet />}
                    />
                    {/* buildings MANAGEMENT */}
                    <Route
                      path={`/${PATH_NAME.ADDRESS.index}/${PATH_NAME.ADDRESS.buildings}`}
                      element={<Building />}
                    />
                    <Route
                      path={`/${PATH_NAME.ADDRESS.index}/${PATH_NAME_ACTION.CREATE}/${PATH_NAME.ADDRESS.buildings}`}
                      element={<Addbuilding />}
                    />
                    <Route
                      path={`/${PATH_NAME.ADDRESS.index}/${PATH_NAME_ACTION.EDIT}/${PATH_NAME.ADDRESS.buildings}/:id`}
                      element={<Addbuilding />}
                    />
                  </Route>

                  {/* COMPANIES MANAGEMENT */}
                  <Route path={PATH_NAME.COMPANIES}>
                    <Route index element={<Companies />} />
                    <Route
                      path={`${PATH_NAME.COMPANIES}/${PATH_NAME_ACTION.CREATE}`}
                      element={<AddCompanies />}
                    />
                    <Route
                      path={`${PATH_NAME.COMPANIES}/${PATH_NAME_ACTION.EDIT}/:id`}
                      element={<AddCompanies />}
                    />
                  </Route>

                  {/* COMPANIES TYPE MANAGEMENT */}
                  <Route path={PATH_NAME.COMPANYTYPE}>
                    <Route index element={<Categorytype />} />
                    <Route
                      path={`${PATH_NAME.COMPANYTYPE}/*`}
                      element={<Categorytype />}
                    />
                  </Route>
                  {/* news Blog   */}

                  <Route path={PATH_NAME.BLOGS}>
                    <Route index element={<Blogs />} />
                    <Route
                      path={`${PATH_NAME.BLOGS}/${PATH_NAME_ACTION.EDIT}/:id`}
                      element={<AddBlog />}
                    />
                  </Route>
                  {/* news management   */}
                  <Route path={PATH_NAME.NEWSMANAGEMENT}>
                    <Route index element={<Newsmanagement />} />
                    <Route
                      path={`${PATH_NAME.NEWSMANAGEMENT}/${PATH_NAME_ACTION.CREATE}`}
                      element={<ADDNewsmanagement />}
                    />
                    <Route
                      path={`${PATH_NAME.NEWSMANAGEMENT}/${PATH_NAME_ACTION.EDIT}/:id`}
                      element={<ADDNewsmanagement />}
                    />
                  </Route>

                  {/* transaction management   */}
                  <Route path={PATH_NAME.TRANSACTIONMANAGEMENT}>
                    <Route index element={<Transactionmanagement />} />
                    <Route
                      path={`${PATH_NAME.TRANSACTIONMANAGEMENT}/${PATH_NAME_ACTION.VIEW}/:id`}
                      element={<PaymentTransactionDetail />}
                    ></Route>
                  </Route>

                  {/* FEATUREDKEYWORD MANAGEMENT */}
                  <Route path={PATH_NAME.FEATUREDKEYWORD}>
                    <Route index element={<Featuredkeyword />} />
                    <Route
                      path={`${PATH_NAME.FEATUREDKEYWORD}/${PATH_NAME_ACTION.CREATE}`}
                      element={<AddFeatureKeyWords />}
                    />
                    <Route
                      path={`${PATH_NAME.FEATUREDKEYWORD}/${PATH_NAME_ACTION.EDIT}/:id`}
                      element={<AddFeatureKeyWords />}
                    />
                  </Route>

                  <Route path={PATH_NAME.ABOUTUS}>
                    <Route index element={<AboutUs />} />
                    <Route
                      path={`${PATH_NAME.ABOUTUS}/${PATH_NAME_ACTION.CREATE}`}
                      element={<AddAboutUs />}
                    ></Route>
                  </Route>
                  <Route
                    path={`${PATH_NAME.ABOUTUS}/${PATH_NAME_ACTION.EDIT}/:id`}
                    element={<AddAboutUs />}
                  ></Route>
                  {/* </Route> */}

                  {/* FOOTER MANAGEMENT */}
                  <Route path={PATH_NAME.FOOTER}>
                    <Route index element={<Footer />} />
                    <Route
                      path={`${PATH_NAME.FOOTER}/${PATH_NAME_ACTION.CREATE}`}
                      element={<AddFooter />}
                    ></Route>
                    <Route
                      path={`${PATH_NAME.FOOTER}/${PATH_NAME_ACTION.EDIT}/:id`}
                      element={<AddFooter />}
                    ></Route>
                  </Route>
                  <Route path={PATH_NAME.NEWS_CATEGORIES}>
                    <Route index element={<NewsCategories />} />
                    <Route
                      path={`${PATH_NAME.NEWS_CATEGORIES}/${PATH_NAME_ACTION.CREATE}`}
                      element={<AddNewsCategories />}
                    ></Route>
                    <Route
                      path={`${PATH_NAME.NEWS_CATEGORIES}/${PATH_NAME_ACTION.EDIT}/:id`}
                      element={<AddNewsCategories />}
                    ></Route>
                  </Route>

                  {/* category MANAGEMENT */}
                  <Route path={PATH_NAME.CATEGORY}>
                    <Route index element={<Category />} />
                    <Route
                      path={`${PATH_NAME.CATEGORY}/${PATH_NAME_ACTION.CREATE}`}
                      element={<AddCategory />}
                    ></Route>
                    <Route
                      path={`${PATH_NAME.CATEGORY}/${PATH_NAME_ACTION.EDIT}/:id`}
                      element={<AddCategory />}
                    ></Route>
                  </Route>
                  {/* companyconfirm MANAGEMENT */}
                  <Route path={PATH_NAME.COMPANYCONFIRM}>
                    <Route index element={<CompanyConfirm />} />
                    <Route
                      path={`${PATH_NAME.COMPANYCONFIRM}/${PATH_NAME_ACTION.CREATE}`}
                      element={<AddCompanyConfirm />}
                    />
                    <Route
                      path={`${PATH_NAME.COMPANYCONFIRM}/${PATH_NAME_ACTION.EDIT}/:id`}
                      element={<AddCompanyConfirm />}
                    />
                  </Route>
                  {/* TOPIC MANAGEMENT */}
                  <Route path={PATH_NAME.TOPIC}>
                    <Route index element={<Topic />} />
                  </Route>

                  {/* TAG */}
                  <Route path={PATH_NAME.TAG}>
                    <Route index element={<Tag />} />
                  </Route>

                  {/* AD MANAGER */}
                  <Route path={PATH_NAME.ADMANAGER}>
                    <Route index element={<AdManager />} />
                    {/* <Route
                      path={`${PATH_NAME.ADMANAGER}/${PATH_NAME_ACTION.CREATE}`}
                      element={<AddAd />}
                    /> */}
                    <Route
                      path={`${PATH_NAME.ADMANAGER}/${PATH_NAME_ACTION.EDIT}/:id`}
                      element={<AddAd />}
                    />
                  </Route>

                  {/* USER MANAGEMENT */}
                  <Route path={PATH_NAME.USERS}>
                    <Route index element={<Users />} />
                    <Route
                      path={`${PATH_NAME.USERS}/${PATH_NAME_ACTION.CREATE}`}
                      element={<AddUser />}
                    ></Route>
                    <Route
                      path={`${PATH_NAME.USERS}/${PATH_NAME_ACTION.EDIT}/:id`}
                      element={<AddUser />}
                    ></Route>
                  </Route>


                  <Route path={PATH_NAME.SETTINGS}>
                    <Route index element={<Settingsmanagement />} />
                    <Route
                      path={`${PATH_NAME.SETTINGS}/${PATH_NAME_ACTION.CREATE}`}
                      element={<AddSetting />}
                    ></Route>
                    <Route
                      path={`${PATH_NAME.SETTINGS}/${PATH_NAME_ACTION.EDIT}/:id`}
                      element={<AddSetting />}
                    ></Route>
                  </Route>

                  <Route path="*" element={<p>Not found</p>} />
                </Route>

                <Route path="*" element={<p>404</p>} />
              </Route>

              <Route element={<AuthLayout />}>
                <Route path={PATH_NAME.SIGN_IN} element={<SignIn />} />
              </Route>
            </Routes>
          </ThemeProvider>

          <ReactQueryDevtools />
        </QueryClientProvider>
      </IntlProvider>
    </ConfigProvider>
  );
}

export default App;
