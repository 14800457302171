import { useState } from 'react';
import {TransactionManagementApi} from "../api/transactionmanagement";

export  const usePaymentTransaction = () => {
  const [data, setData] = useState({});
  const [loading, setIsLoading] = useState(false);

  const fetch = async ({params}) => {
    setIsLoading(true);

    // Fetch Data
    const _res = await TransactionManagementApi.getAllPaymentTransaction(params)
    setData(_res?.data || {});
    setIsLoading(false);
  }

  return {
    data,
    loading,
    fetch,
  }

}

export const usePaymentTransactionDetail = () => {

  const [data, setData] = useState({});
  const [loading, setIsLoading] = useState(false);

  const fetch = async (id) => {
    setIsLoading(true);

    // Fetch Data
    const _res = await TransactionManagementApi.getPaymentTransactionDetail(id)
    setData(_res?.data || {});
    setIsLoading(false);
  }

  return {
    data,
    loading,
    fetch,
  }
}
