import { useEffect } from 'react';
import { useQuery, useQueryClient, useMutation } from 'react-query';

import { footerApi } from 'api/footerApi';


export const FooterKey = {
  all: ['footer'],
  lists: () => [...FooterKey.all, 'list'],
  list: (filter) => [...FooterKey.lists(), filter],
  details: () => [...FooterKey.all, 'detail'],
  detail: (id) => [...FooterKey.details(), id],
};

export const useFooter = ({ params, options}) => {
    return useQuery({
        queryKey: FooterKey.list(params),
        queryFn: () => footerApi.getAll(params),
        ...options,
    });
};

const deleteFooter = (id) => {
    return footerApi.delete(id);
}


export const useDeleteFooter = ({ params, options }) => {
  const queryClient = useQueryClient();
  return useMutation(deleteFooter, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: FooterKey.list(params),
        queryFn: () => footerApi.getAll(params),
        ...options,
      })
    }
  });
}


export const usePrefetchCompanies = (data, queryParams) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    if (data?.link?.next) {
      const newQueryParams = { ...queryParams, page: queryParams.page + 1 };

      queryClient.prefetchQuery(FooterKey.list(newQueryParams), () =>
        footerApi.getAll(newQueryParams),
      );
    }
  }, [data, queryParams, queryClient]);
};
