
import { useIntl } from 'react-intl';

import useAddress from './useAddress';
import useContact from './useContact';
import useManager from './useManager';
import useUser from './useUser';

function useCompanyinformation() {
  const intl = useIntl();
  const { columns: Address } = useAddress();
  const { columns: Contact } = useContact();
  const { columns: Manager } = useManager();
  const { columns: User } = useUser();
  const columns = [
    {
      title: intl.formatMessage({ id: 'company.name' }),
      dataIndex: 'name',
      align: 'center',
      width: 400,
    },
    {
      title: intl.formatMessage({ id: 'company.address' }),
      dataIndex: 'address',
      align: 'center',
      width: 400,
      children: Address
    },
    {
      title: intl.formatMessage({ id: 'company.contact' }),
      align: 'center',
      width: 200,
      children: Contact
    },
    {
      title: intl.formatMessage({ id: 'company.manager' }),
      align: 'center',
      width: 200,
      children: Manager
    },
  ]
  return { columns };
}

export default useCompanyinformation;
