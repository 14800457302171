import { useEffect } from 'react';
import { useQuery, useQueryClient, useMutation } from 'react-query';


import { tagApi } from 'api/tagApi';

export const TagKey = {
  all: ['Tag'],
  lists: () => [...TagKey.all, 'list'],
  list: (filter) => [...TagKey.lists(), filter],
  details: () => [...TagKey.all, 'detail'],
  detail: (id) => [...TagKey.details(), id],
};

export const useTag = ({ params, options }) => {
  return useQuery({
    queryKey: TagKey.list(params),
    queryFn: () => tagApi.getAll(params),
    ...options,
  });
};
//  action
const addTag = async (value) => {
  await tagApi.add(value);
};

export const useAddTag = ({ params, options }) => {
  const queryClient = useQueryClient();
  return useMutation(addTag, {
    onSuccess: () => {
      queryClient.refetchQueries();
    },
  });
};

const editTag = async (data) => {
  await tagApi.edit(data);
};
export const useDitTag = ({ params, options }) => {
  const queryClient = useQueryClient();
  return useMutation(editTag, {
    onSuccess: () => {
      queryClient.refetchQueries();
    },
  });
};
const deleteTag = (ids) => {
  return tagApi.delete(ids);
};
export const useDeleteTag = ({ params, options }) => {
  const queryClient = useQueryClient();
  return useMutation(deleteTag, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: TagKey.list(params),
        queryFn: () => tagApi.getAll(params),
        ...options,
      });
    },
  });
};
export const usePrefetchCompanies = (data, queryParams) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    if (data?.link?.next) {
      const newQueryParams = { ...queryParams, page: queryParams.page + 1 };

      queryClient.prefetchQuery(TagKey.list(newQueryParams), () =>
        tagApi.getAll(newQueryParams),
      );
    }
  }, [data, queryParams, queryClient]);
};
