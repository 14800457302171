import React, { useRef } from 'react';
import { Select, Col, Row, Form, Spin } from 'antd';

import {
  useGetAllProvince,
  useGetListDistrict,
  useGetListStreet,
} from '../queries/selectBuilding';
import { useEffect } from 'react';

const { Option } = Select;

function FilterBuilding({
  objectAddress,
  setObjectAddress,
  col = 6,
  disabled = false,
}) {
  const formRef = useRef(null);
  const { data, isLoading } = useGetAllProvince();
  const {
    data: ListDistrict,
    isLoading: LoadingDt,
    isFetching: isFetchingDt,
  } = useGetListDistrict({
    provinceId: objectAddress.provinceId,
  });
  const {
    data: ListStreet,
    isLoading: LoadingSt,
    isFetching: isFetchingSt,
  } = useGetListStreet({
    districtId: objectAddress.districtId,
  });
  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldsValue({ ...objectAddress });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [LoadingDt, LoadingSt, isLoading]);
  const loading = !isLoading && !LoadingDt && !LoadingSt;
  const SaveData = (object) => {
    formRef.current.setFieldsValue({ ...object });
  };
  useEffect(() => {
    if (!objectAddress.districtId && !isFetchingDt && !isFetchingDt) {
      if (ListDistrict?.data?.data[0]?.id) {
        setObjectAddress((prev) => ({
          ...prev,
          districtId: ListDistrict.data.data[0].id,
        }));
        SaveData({ districtId: ListDistrict.data.data[0].id });
      }
    }
    if (!objectAddress.streetId && !isFetchingSt && !LoadingSt) {
      if (ListStreet?.data?.data[0]?.id) {
        setObjectAddress((prev) => ({
          ...prev,
          streetId: ListStreet.data.data[0]?.id,
        }));
        SaveData({
          streetId: ListStreet.data.data[0]?.id,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [LoadingDt, LoadingSt, isLoading, isFetchingDt, isFetchingSt]);
  const HandleValuesChange = (values) => {
    switch (true) {
      case !!values.provinceId:
        // code block
        const NewData = {
          provinceId: values.provinceId,
          districtId: null,
          streetId: null,
        };
        setObjectAddress(NewData);
        SaveData(NewData);
        break;
      case !!values.districtId:
        // code block
        const NewDataDt = {
          provinceId: objectAddress.provinceId,
          districtId: values.districtId,
          streetId: null,
        };
        setObjectAddress(NewDataDt);
        SaveData(NewDataDt);
        break;
      default:
        const NewDataSt = {
          provinceId: objectAddress.provinceId,
          districtId: objectAddress.districtId,
          streetId: values.streetId,
        };
        setObjectAddress(NewDataSt);
        SaveData(NewDataSt);
      // code block
    }
  };
  return (
    <Form name="basic" onValuesChange={HandleValuesChange} ref={formRef}>
      {loading ? (
        <Row>
          <Col span={col}>
            <Form.Item
              name="provinceId"
              label="Tỉnh"
              style={{
                width: '98%',
              }}
            >
              <Select
                showSearch
                placeholder="Chọn tỉnh"
                optionFilterProp="children"
                disabled={disabled}
                filterOption={(input, option) =>
                  option.children.includes(input)
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {data.data.data.map((province) => (
                  <Option value={province.id} key={province.id}>
                    {province.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={col}>
            <Form.Item
              name="districtId"
              label="Huyện"
              style={{
                width: '98%',
              }}
            >
              <Select
                showSearch
                placeholder="Chọn Thị trấn và Huyện và Phường"
                optionFilterProp="children"
                disabled={disabled}
                filterOption={(input, option) =>
                  option.children.includes(input)
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {ListDistrict.data.data.map((district) => (
                  <Option value={district.id} key={district.id}>
                    {district.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={col}>
            <Form.Item
              name="streetId"
              label="Đường"
              style={{
                width: '98%',
              }}
            >
              <Select
                showSearch
                placeholder="Chọn Đường"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.includes(input)
                }
                disabled={disabled}
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {(ListStreet?.data?.data || []).map((district) => (
                  <Option value={district.id} key={district.id}>
                    {district.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      ) : (
        <Spin />
      )}
    </Form>
  );
}

export default FilterBuilding;
