import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Form, Input, Row, Col, Select } from 'antd';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { isIncludeTextSearch } from 'utils';

import { TitleWrapper } from 'components/layout';
import UseGetaddressData from 'hooks/useGetaddresData';
import { companyApi } from 'api/companyApi';
import GoogleMapWithSearch from 'components/GoogleMap/GoogleMapWithSearch';
import FormCompanies from './FormCompanies';
import EnableCompanies from './EnableCompanies';
import EnableSendEmailCompanies from './EnableSendEmailCompanies';
import AddCategory from './AddCategory';
import WrapperCenter from 'components/common/button/wrapperCenter';
import { useTagPublic } from 'views/tag/queriesTagPublic';
import { useTag } from 'views/tag/queries';

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
const Acolumn = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
const AcolumnGoogle = {
  labelCol: {
    span: 0,
  },
  wrapperCol: {
    span: 24,
  },
};
const { Option } = Select;
const CompaniesAddStyles = styled.div`
  .form {
    margin-top: 10px;
  }
  .wrapper__from {
    width: 100%;
    margin: auto;
  }
`;
function AddFeatureKeyWords(props) {
  const intl = useIntl();
  let navigate = useNavigate();
  const formElemt = useRef();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [TagValue, setTagValue] = useState([]);

  const { data: dataTags } = useTagPublic({ type: 'company' });

  const dataTag = dataTags?.data.map((item) => {
    return { value: item.id, label: item.name, key: item.id };
  });
  const [objectForm, setObjectForm] = useState({
    name: '',
    district_id: null,
    province_id: null,
    street_id: null,
    ward_id: null,
    address: '',
    categories: [],
    enable: 1,
    send_mail: 1,
    tag: TagValue,
  });
  const [objectFormFetch, setObjectFormFetch] = useState({
    name: '',
    district_id: null,
    province_id: null,
    street_id: null,
    ward_id: null,
    address: '',
    categories: [],
    enable: 1,
    send_mail: 1,
    tag: TagValue,
  });
  // console.log(' useState objectForm', objectForm);

  // console.log(' useState objectFormFetch', objectFormFetch);
  const parent = objectForm.parent_company || {};
  const valuesGoogle =
    objectForm.horizontal_axis && objectForm.vertical_axis
      ? {
          lat: +objectForm.horizontal_axis,
          lng: +objectForm.vertical_axis,
        }
      : null;
  const { listProvince, listDistrict, listWarn, listStreet } =
    UseGetaddressData({
      province: objectForm.province_id,
      district: objectForm.district_id,
    });

  // change google map
  const onChange = (value) => {
    setObjectFormFetch((prev) => {
      return {
        ...prev,
        horizontal_axis: value.lat().toString(),
        vertical_axis: value.lng().toString(),
      };
    });
    setObjectForm((prev) => {
      return {
        ...prev,
        horizontal_axis: value.lat().toString(),
        vertical_axis: value.lng().toString(),
      };
    });
  };

  const onFinish = async () => {
    delete objectFormFetch.coordinates;
    delete objectFormFetch.created_by;
    objectFormFetch.user_id = 1;
    objectFormFetch.avatar = null;
    objectFormFetch.main_marketing = null;
    objectFormFetch.ounded_year = objectFormFetch.fax_code;
    objectFormFetch.number_of_employees = objectFormFetch.fax_code;
    objectFormFetch.status = 1;
    objectFormFetch.tag = TagValue?.join('-');

    let data = id
      ? await companyApi.update(objectFormFetch, id)
      : await companyApi.create(objectFormFetch);
    console.log('submit form goi API', objectFormFetch);
    if (data.error) {
      console.log('message', data.error);
    } else {
      navigate(-1);
    }
  };

  const SortCategories = (data) => {
    const Sort = data?.categories.reduce(
      (previousValue, currentValue) => [
        ...previousValue,
        currentValue.category_id,
      ],
      [],
    );
    data.categories = Sort;
    return data;
  };

  // call api lan dau tien set lai value
  async function fetchKeyWord(id) {
    const { data } = await companyApi.get(id);
    data.district_id = data.district?.id;
    data.province_id = data.province?.id;
    data.ward_id = data.ward?.id;
    data.street_id = data.street?.id;
    data.type_id = data.type?.id;
    data.tag = data.tag?.map((item) => {
      return item.id;
    });
    const NewData = SortCategories(data);
    setObjectForm(NewData);
    setObjectFormFetch(NewData);
    formElemt.current.setFieldsValue({ ...NewData });
  }

  useEffect(() => {
    if (id) {
      fetchKeyWord(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  function GetObjectData(id, data) {
    const index = data.findIndex((item) => item.id === id);
    return data[index].id;
  }
  const HandleValuesChange = (values) => {
    // const convertTag = values.tag.join("-");
    // values = { ...values, tag: convertTag };
    // console.log('values', values)
    setTagValue(values.tag);

    switch (true) {
      case !!values.province_id:
        values.district_id = null;
        values.street_id = null;
        values.ward_id = null;

        setObjectForm((prev) => {
          return {
            ...prev,
            ...values,
          };
        });
        setObjectFormFetch((prev) => {
          return {
            ...prev,
            ...values,
            province_id: GetObjectData(values.province_id, listProvince),
          };
        });
        formElemt.current.setFieldsValue(values);
        break;
      case !!values.district_id:
        values.street_id = null;
        values.ward_id = null;
        setObjectForm((prev) => {
          return {
            ...prev,
            ...values,
          };
        });
        setObjectFormFetch((prev) => {
          return {
            ...prev,
            ...values,
            district_id: GetObjectData(values.district_id, listDistrict),
          };
        });
        formElemt.current.setFieldsValue(values);
        break;

      case !!values.ward_id:
        setObjectForm((prev) => {
          return {
            ...prev,
            ...values,
          };
        });
        setObjectFormFetch((prev) => {
          return {
            ...prev,
            ...values,
            ward_id: GetObjectData(values.ward_id, listWarn),
          };
        });
        formElemt.current.setFieldsValue(values);
        break;
      case !!values.street_id:
        setObjectForm((prev) => {
          return {
            ...prev,
            ...values,
          };
        });
        setObjectFormFetch((prev) => {
          return {
            ...prev,
            ...values,
            street_id: GetObjectData(values.street_id, listStreet),
          };
        });
        formElemt.current.setFieldsValue(values);
        break;
      default:
        setObjectForm((prev) => {
          return {
            ...prev,
            ...values,
          };
        });
        setObjectFormFetch((prev) => {
          return {
            ...prev,
            ...values,
          };
        });
        formElemt.current.setFieldsValue(values);
        break;
    }
  };
  return (
    <CompaniesAddStyles>
      <TitleWrapper>
        {intl.formatMessage({
          id: id ? 'views.companies.title.edit' : 'views.companies.title.add',
        })}
      </TitleWrapper>
      <div className="wrapper__from">
        <Form
          ref={formElemt}
          form={form}
          name="control-hooks"
          onFinish={onFinish}
          className="form"
          onValuesChange={HandleValuesChange}
        >
          <Row>
            <FormCompanies
              type={objectForm.type}
              parent={parent.name}
              form={formElemt}
              setObjectFormFetch={setObjectFormFetch}
            />

            <Col span={24}>
              <Form.Item
                name="tag"
                initialValue={objectForm?.tag}
                label="Tag"
                style={{
                  width: '98%',
                }}
                {...Acolumn}
              >
                <Select
                  showArrow
                  mode="multiple"
                  style={{
                    width: '100%',
                  }}
                  placeholder="Chọn Tag"
                  optionLabelProp="label"
                  options={dataTag}
                  filterOption={(input, option) => {
                    return isIncludeTextSearch(input, option.children);
                  }}
                  defaultValue={objectForm.tag ? objectForm.tag : []}
                >
                  {dataTag?.length > 0 &&
                    dataTag.map(({ name, id }) => (
                      <Option label={name} value={id} key={id}>
                        {name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>

            <AddCategory
              value={objectFormFetch.categories}
              form={formElemt}
              setObjectFormFetch={setObjectFormFetch}
            />
            <EnableSendEmailCompanies
              send_mail={objectFormFetch.send_mail}
              form={formElemt}
              setObjectFormFetch={setObjectFormFetch}
            />
            <EnableCompanies
              enable={objectFormFetch.enable}
              form={formElemt}
              setObjectFormFetch={setObjectFormFetch}
            />

            <Col span={24}>
              <Form.Item
                name="province_id"
                initialValue={objectForm.province_id}
                label="Tỉnh"
                {...Acolumn}
                style={{
                  width: '98%',
                }}
                rules={[
                  {
                    required: true,
                    message: 'không để trường này trống',
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Chọn tỉnh"
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return isIncludeTextSearch(input, option.children);
                  }}
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {listProvince?.map((province) => (
                    <Option value={province.id} key={province.id}>
                      {province.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                {...Acolumn}
                name="district_id"
                initialValue={objectForm.district_id}
                label="Thị trấn, quận"
                style={{
                  width: '98%',
                }}
                rules={[
                  {
                    required: true,
                    message: 'không để trường này trống',
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Chọn Thị trấn, quận huyện"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    isIncludeTextSearch(input, option.children)
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {listDistrict.map((district) => (
                    <Option value={district.id} key={district.id}>
                      {district.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                {...layout}
                name="ward_id"
                initialValue={objectForm.ward_id}
                label="Phường, xã"
                style={{
                  width: '96%',
                }}
                rules={[
                  {
                    required: true,
                    message: 'không để trường này trống',
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Chọn phường, xã "
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    isIncludeTextSearch(input, option.children)
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {listWarn.map((ward) => (
                    <Option value={ward.id} key={ward.id}>
                      {ward.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                {...layout}
                name="street_id"
                initialValue={objectForm.street_id}
                label="Đường"
                style={{
                  width: '96%',
                }}
                rules={[
                  {
                    required: true,
                    message: 'không để trường này trống',
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Chọn đường"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    isIncludeTextSearch(input, option.children)
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {listStreet.map((street) => (
                    <Option value={street.id} key={street.id}>
                      {street.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                {...layout}
                name="address"
                label="Địa chỉ"
                style={{
                  width: '98%',
                }}
                rules={[
                  {
                    required: true,
                    message: 'không để trường này trống',
                  },
                ]}
              >
                <Input placeholder="Nhập tên địa chỉ" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                {...layout}
                name="keyword"
                label="Từ khóa"
                style={{
                  width: '98%',
                }}
              >
                <Input placeholder="Nhập Từ khóa" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                {...layout}
                name="email"
                label="Email"
                style={{
                  width: '96%',
                }}
              >
                <Input placeholder="Nhập tên Email" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                {...layout}
                name="phone"
                label="Số điện thoại"
                style={{
                  width: '96%',
                }}
              >
                <Input placeholder="Nhập tên số điện thoại" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                {...layout}
                name="website"
                label="Trang Website"
                style={{
                  width: '96%',
                }}
              >
                <Input placeholder="Nhập website" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                {...layout}
                name="manager"
                label="Người quản lý"
                style={{
                  width: '96%',
                }}
              >
                <Input placeholder="Nhập Người quản lý" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                {...layout}
                name="manager_phone"
                label="Liên hệ người quản lý"
                style={{
                  width: '96%',
                }}
              >
                <Input placeholder="Nhập Liên hệ người quản lý" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Bản đồ" {...AcolumnGoogle}>
                {console.log()}
                <GoogleMapWithSearch onChange={onChange} value={valuesGoogle} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <WrapperCenter>
                <Button type="primary" htmlType="submit">
                  {id ? 'Sửa record' : 'Thêm record '}
                </Button>
              </WrapperCenter>
            </Col>
          </Row>
        </Form>
      </div>
    </CompaniesAddStyles>
  );
}

export default AddFeatureKeyWords;
