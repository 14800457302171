import styled from 'styled-components';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { ContentWrapper, TitleWrapper } from 'components/layout';
import { TableWrapper } from 'components';
import { ButtonNewOn } from 'components/common/button/ButtonAddNew';
import ButtonOther from 'components/common/button/ButtonOther';
import {
  calcIndexByOrder,
  isIncludeTextSearch,
  serializeSearchParams,
} from 'utils';
import { useCustomSearchParams } from 'hooks/useCustomSearchParams';
import { EditOutlined } from '@ant-design/icons';
import { useDeleteTag, useTag } from './queries';
import { Form, Input, Modal, Select } from 'antd';
import AddTag from './add/AddTag';
import { SearchForm } from 'components/form';
import { DEFAULT_PAGINATION } from 'constants/common';

const { Option } = Select;

const TagStyles = styled.div`
  .action {
    margin: 0px 5px;
  }
  .action:hover {
    opacity: 0.7;
    cursor: pointer;
  }
  .selectType {
    margin-left: 10px;
    padding: 4px 0px;
    background: #fff;
    border-radius: 3px;
    min-width: 150px;
    width: auto;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border: none;
    box-shadow: none;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    outline: none;
  }
`;

const Tag = () => {
  const intl = useIntl();
  const [id, setId] = useState(null);
  const [search, setSearch] = useCustomSearchParams();
  const [visible, setVisible] = useState(false);

  // table
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loadingReload, setLoadingReload] = useState(false);
  const [loadingDelete, setLoadingDetele] = useState(false);

  const argument = {
    params: search,
    options: {
      keepPreviousData: true,
    },
  };
  const { mutate } = useDeleteTag(argument);

  const { data, isPreviousData, isFetching } = useTag(argument);
  console.log('search', data);
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const hasSelected = selectedRowKeys.length > 0;
  const deleteSelected = selectedRowKeys.length > 0;
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleEdit = (id) => {
    setId(id);
    setVisible(true);
  };

  const handleDelete = () => {
    setLoadingDetele(true); // ajax request after empty completing
    setTimeout(() => {
      setLoadingDetele(false);
      mutate(selectedRowKeys);
      setSelectedRowKeys([]);
    }, 1000);
    setSelectedRowKeys([]);
  };

  const handleReload = () => {
    setLoadingReload(true); // ajax request after empty completing
    setTimeout(() => {
      setLoadingReload(false);
    }, 1000);
    setSelectedRowKeys([]);
  };

  const handleNew = () => {
    setId(null);
    setVisible(true);
  };

  const handleChange = ({ current, pageSize }) =>
    setSearch({ ...search, page: current, page_size: pageSize });

  const HandleSearch = (values) => {
    if (values.type === 'all') {
      values.type = null;
    }

    if (values.user_created_id === 'all') {
      values.user_created_id = null;
    }

    const params = serializeSearchParams({
      ...search,
      ...values,
      page: DEFAULT_PAGINATION.PAGE,
    });

    setSearch(params);
  };

  const columns = [
    {
      title: intl.formatMessage({ id: 'common.index' }),
      render: (_, __, index) =>
        calcIndexByOrder({ index, page: search.page, limit: search.page_size }),
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'topic.name' }),
      dataIndex: 'name',
      align: 'center',
    },

    {
      title: intl.formatMessage({ id: 'import.type' }),
      dataIndex: 'type',
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'topic.action' }),
      dataIndex: 'action',
      align: 'center',
      render: (_, value) => (
        <>
          <EditOutlined
            className={'action'}
            onClick={() => handleEdit(value.id)}
          />
        </>
      ),
    },
    {
      title: intl.formatMessage({ id: 'import.stt' }),
      dataIndex: 'no_',
      align: 'center',
    },
  ];

  const renderExtra = (
    <>
      <ButtonNewOn onClick={handleNew} />
      <ButtonOther
        type="default"
        onClick={handleDelete}
        disabled={!deleteSelected}
        loading={loadingDelete}
      >
        Xóa
      </ButtonOther>
      <ButtonOther
        type="default"
        onClick={handleReload}
        disabled={!hasSelected}
        loading={loadingReload}
      >
        Làm mới
      </ButtonOther>
    </>
  );

  return (
    <TagStyles>
      <TitleWrapper>
        {intl.formatMessage({ id: 'views.tag.list.title' })}
      </TitleWrapper>

      <ContentWrapper>
        {/* Search */}
        <SearchForm
          initialValues={{
            ...search,
            type: search.type ? search.type : 'all',
            user_created_id: search.user_created_id
              ? +search.user_created_id
              : 'all',
          }}
          onFinish={HandleSearch}
        >
          <Form.Item name="search">
            <Input
              placeholder={intl.formatMessage({ id: 'input.search' })}
              value={search.search}
            />
          </Form.Item>

          <Form.Item name="type">
            <Select
              className="selectType"
              showSearch
              placeholder="Chọn loại hình"
              optionFilterProp="children"
              filterOption={(input, option) =>
                isIncludeTextSearch(input, option.children)
              }
            >
              <Option value={'all'}>Tất cả</Option>
              <Option value="company">Company</Option>
              <Option value="user">User</Option>
            </Select>
          </Form.Item>
        </SearchForm>

        {/* List render Tag */}
        <TableWrapper
          rowKey="id"
          columns={columns}
          dataSource={data?.data || []}
          loading={isPreviousData && isFetching}
          rowSelection={rowSelection}
          pagination={{
            current: search.page,
            position: ['bottomCenter'],
            pageSize: search.page_size,
            total: data?.meta?.total ?? 0,
          }}
          onChange={handleChange}
          extra={renderExtra}
        />
      </ContentWrapper>
      {visible && (
        <Modal
          onCancel={() => setVisible(false)}
          visible={visible}
          title={null}
          footer={null}
          closable={false}
        >
          <AddTag id={id} setVisible={setVisible} />
        </Modal>
      )}
    </TagStyles>
  );
};

export default Tag;
