import { useEffect } from 'react';
import { useQuery, useQueryClient, useMutation } from 'react-query';

import { companyApi } from 'api/companyApi';


export const companytypeKey = {
  all: ['companytypeKey'],
  lists: () => [...companytypeKey.all, 'list'],
  list: (filter) => [...companytypeKey.lists(), filter],
  details: () => [...companytypeKey.all, 'detail'],
  detail: (id) => [...companytypeKey.details(), id],
};


export const useCompanytype = ({ params, options, id }) => {
  return useQuery({
    queryKey: companytypeKey.list(params + id),
    queryFn: () => companyApi.getAllType(params),
    ...options,
  });
};
export const useSelectCompanytype = ({ params, options, name }) => {
  return useQuery({
    queryKey: companytypeKey.list(params),
    queryFn: () => companyApi.getAllType(params),
    select: (data) => {
      const index = data?.data.findIndex((type) => type.name === name);
      return {
        data: data?.data,
        index: index > -1 ? data?.data[index].id : null,
      };
    },
    ...options,
  });
};
export const useFeaturedcompanytype = ({ params, options }) => {
  return useQuery({
    queryKey: companytypeKey.list(params),
    queryFn: () => companyApi.getAllType(params),
    ...options,
  });
};


//  action
const addcompanytyoe = (value) => {
  companyApi.createType(value);
};

export const useAddcompanytype = ({ params, options }) => {
  const queryClient = useQueryClient();
  return useMutation(addcompanytyoe, {
    onSuccess: () => {
      queryClient.refetchQueries()
    },
  });
};

const editcompanytyoe = (data) => {
  companyApi.updateType(data);
};
export const useDitcompanytype = ({ params, options }) => {
  const queryClient = useQueryClient();
  return useMutation(editcompanytyoe, {
    onSuccess: () => {
      queryClient.refetchQueries()
    },
  });
};
const deletecompanytype = (id) => {
  return companyApi.deleteType(id);
};

export const useDeletecompanytype = ({ params, options }) => {
  const queryClient = useQueryClient();
  return useMutation(deletecompanytype, {
    onSuccess: () => {
      queryClient.refetchQueries();
    },
  });
};
export const usePrefetchCompanies = (data, queryParams) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    if (data?.link?.next) {
      const newQueryParams = { ...queryParams, page: queryParams.page + 1 };

      queryClient.prefetchQuery(companytypeKey.list(newQueryParams), () =>
        companyApi.getAllType(newQueryParams),
      );
    }
  }, [data, queryParams, queryClient]);
};
