export const PATH_NAME = {
  ROOT: '/',
  SIGN_IN: '/sign-in',
  SIGN_UP: '/sign-up',

  COMPANIES: '/companies',
  USERS: '/users',
  FEATUREDKEYWORD: '/featuredkeyword',
  BLOGS: '/blogs',
  ABOUTUS: '/about_us',
  FOOTER: '/footer',
  NEWSMANAGEMENT: '/newsmanagement',
  TRANSACTIONMANAGEMENT: '/transactionmanagement',
  COMPANYTYPE: '/companytype',
  CATEGORY: '/category',
  NEWS_CATEGORIES: '/news_categories',
  COMPANYCONFIRM: '/companyconfirm',
  TOPIC: '/topics',
  TAG: '/tag',
  ADMANAGER: '/ad-manager',
  SETTINGS: '/settings',
  ADDRESS: {
    index: 'address',
    province: 'province',
    district: 'district',
    ward: 'ward',
    street: 'street',
    buildings: "building"
  },
};

export const PATH_NAME_ACTION = {
  CREATE: 'create',
  EDIT: 'edit',
  VIEW: 'view',
};
