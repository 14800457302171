import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Col, Form, Input, Row } from 'antd';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { toolbarOptions } from 'config/draftConfig';
import { TitleWrapper } from 'components/layout';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { aboutusApi } from 'api/aboutusApi';
import { Editor } from 'react-draft-wysiwyg';
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from 'draft-js';
import HtmlToDraftfc from '../../../utils/HtmlToDraftfc'
const layout = {
  labelCol: {
    span: 2,
  },
  wrapperCol: {
    span: 21,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 2,
    span: 16,
  },
};
const FeatureAddStyles = styled.div`
  .form {
    margin-top: 10px;
  }
  .editor {
    padding: 10px;
  }
  .demo-editor {
    ${'' /* border: 1px solid #000; */}
    padding: 10px;
    border-radius: 4px;
    background-color: #fff;
    min-height: 200px;

  }
`;

function AddAboutUs(props) {
  const intl = useIntl();
  let navigate = useNavigate();
  const formElemt = useRef();
  const title = useRef();
  const detail = useRef();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [detailData, setDetailData] = useState(null);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const onEditorStateChange = (newState) => setEditorState(newState);

  const onFinish = async (values) => {
    // values.detail = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    values.detail = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    
    // console.log('lấy được detail: ', values.detail.replace(/<[^>]+>/g,''));
//  function MyComponent() {
//     return <div dangerouslySetInnerHTML={{__html: data}}/>;
//   }
//   MyComponent();
    let data = id
      ? await aboutusApi.edit(values, id)
      : await aboutusApi.add(values);

    if (data.error) {
      console.log('message', data.error);
    } else {
      navigate('/about_us');
    }
  
  };

  // const onReset = () => {
  //   form.resetFields();
  //   // setEditorState('123');
  // };

  // const onFill = () => {
  //   form.setFieldsValue({
  //     title: 'Title .....',
  //     detail: 'detail .......',
  //   });
  // };
  async function fetchData(id) {
    const { data } = await aboutusApi.get(id);
    setDetailData(data);
    formElemt.current.setFieldsValue({
      title: data.title,
      detail: data.detail,
    });
    const blocksFromHTML = convertFromHTML(data.detail);
    const editorContent = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    );
    setEditorState(EditorState.createWithContent(editorContent));
  }

  useEffect( () => {
    async function fetchData(id) {
      const { data } = await aboutusApi.get(id);
      setDetailData(data[0]);
      formElemt.current.setFieldsValue({
        title: data.title,
        detail: data.detail,
      });
      const blocksFromHTML = convertFromHTML(data.detail);
      const editorContent = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
      );
      console.log('data-detail: ', HtmlToDraftfc(data.detail));
      setEditorState( HtmlToDraftfc(data.detail));
    }
    if (id) {
      fetchData(id);
    }
    title.current.focus();
  }, []);
  return (
    <FeatureAddStyles>
      <TitleWrapper>
        {intl.formatMessage({
          id: id ? 'views.about_us.title.edit' : 'views.about_us.title.add',
        })}
      </TitleWrapper>
      <Form
        ref={formElemt}
        {...layout}
        form={form}
        name="control-hooks"
        onFinish={onFinish}
        className="form"
      >
        <Form.Item
          name="title"
          label="Title: "
          rules={[
            {
              required: true,
              message: 'không để trường này trống',
            },
          ]}
        >
          <Input ref={title} />
        </Form.Item>
        <Form.Item name="detail" label="Detail: ">
          <Editor
            ref={detail}
            editorState={editorState}
            toolbar={{
              ...toolbarOptions,
              embedded: {
                embedCallback: (link) => {
                  // not work ??
                  if (
                    link.indexOf('youtube') >= 0 ||
                    link.indexOf('youtu.be/') >= 0
                  ) {
                    link = link.replace('watch?v=', 'embed/');
                    link = link.replace('/watch/', '/embed/');
                    link = link.replace('youtu.be/', 'youtube.com/embed/');
                  }
                  return link;
                },
              },
            }}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={onEditorStateChange}
          />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button
            style = {{padding: '0 !important'}}
           type="primary" htmlType="submit">
            {id ? 'Sửa' : 'Thêm   '}
          </Button>
          {/* <Button htmlType="button" onClick={onReset}>
            Reset
          </Button> */}
          {/* <Button type="link" htmlType="button" onClick={onFill}>
            Fill form
          </Button> */}
        </Form.Item>
      </Form>
    </FeatureAddStyles>
  );
}

export default AddAboutUs;
