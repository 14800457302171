import axiosClient from './axiosClient';

export const companyApi = {
  getAll(params) {
    var str = '';
    for (var p in params) {
      if (Object.prototype.hasOwnProperty.call(params, p)) {
        str += p + '=' + params[p] + '&';
      }
    }
    return axiosClient.get(`/admin/companies?${str.slice(0, str.length - 1)}`);
  },
  get(id) {
    return axiosClient.get(`/admin/companies/${id}`);
  },
  create(data) {
    return axiosClient.post('/admin/companies', data);
  },
  update(data, id) {
    return axiosClient.patch(`/admin/companies/${id}`, data);
  },
  // delete(id) {
  //   return axiosClient.delete(`/admin/companies/${id}`);
  // },
  delete(ids) {
    return axiosClient.delete(`/admin/companies/delete/multiple`, {
      data: { ids: ids },
    });
  },

  getAllType() {
    return axiosClient.get('/admin/company/types?page_size=100');
  },
  getType(id) {
    return axiosClient.get(`/admin/company/types/${id}`);
  },
  createType(data) {
    return axiosClient.post('/admin/company/types', data);
  },
  updateType({ data, id }) {
    return axiosClient.patch(`/admin/company/types/${id}`, data);
  },
  deleteType(ids) {
    return axiosClient.delete(`/admin/company/types`, { data: { ids: ids } });
  },
  import(data) {
    return axiosClient.post('/admin/companies/import', data);
  },
};
