import { useEffect } from 'react';
import { useQuery, useQueryClient, useMutation } from 'react-query';

import { topicApi } from 'api/topicApi';

export const TopicKey = {
  all: ['Topic'],
  lists: () => [...TopicKey.all, 'list'],
  list: (filter) => [...TopicKey.lists(), filter],
  details: () => [...TopicKey.all, 'detail'],
  detail: (id) => [...TopicKey.details(), id],
};

export const useTopic = ({ params, options }) => {
  return useQuery({
    queryKey: TopicKey.list(params),
    queryFn: () => topicApi.getAll(params),
    ...options,
  });
};
//  action
const addTopic = async (value) => {
  await topicApi.add(value);
};

export const useAddTopic = ({ params, options }) => {
  const queryClient = useQueryClient();
  return useMutation(addTopic, {
    onSuccess: () => {
      queryClient.refetchQueries();
    },
  });
};

const editTopic = async (data) => {
  await topicApi.edit(data);
};
export const useDitTopic = ({ params, options }) => {
  const queryClient = useQueryClient();
  return useMutation(editTopic, {
    onSuccess: () => {
      queryClient.refetchQueries();
    },
  });
};
const deleteTopic = (ids) => {
  return topicApi.delete(ids);
};
export const useDeleteTopic = ({ params, options }) => {
  const queryClient = useQueryClient();
  return useMutation(deleteTopic, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: TopicKey.list(params),
        queryFn: () => topicApi.getAll(params),
        ...options,
      });
    },
  });
};
export const usePrefetchCompanies = (data, queryParams) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    if (data?.link?.next) {
      const newQueryParams = { ...queryParams, page: queryParams.page + 1 };

      queryClient.prefetchQuery(TopicKey.list(newQueryParams), () =>
        topicApi.getAll(newQueryParams),
      );
    }
  }, [data, queryParams, queryClient]);
};
