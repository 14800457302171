export const IMPORT_FILE_TYPES = ['csv', 'xls' , "xlsx"];
export const EXPORT_FILE_TYPES = ['csv', 'xls' , "xlsx"];

export const FILE_TYPE = {
  PNG: 'image/png',
  JPEG: 'image/jpeg',
  CSV: 'text/csv',
  XLS: 'application/vnd.ms-excel',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
};
