import React from 'react';
import { Form, Col } from 'antd';

import Category from './CategoryTable';

const Acolumn = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

function AddCategory({ value, setObjectFormFetch }) {
  const handleChange = (newValue) => {
    setObjectFormFetch((prev) => {
      return {
        ...prev,
        categories: newValue,
      };
    });
  };
  return (
    <>
      <Col span={24}>
        <Form.Item
          name="categories"
          label="Nghành nghề"
          rules={[
            {
              required: false,
              message: 'không để trường này trống',
            },
          ]}
          {...Acolumn}
          style={{
            width: '98%',
          }}
        >
          <Category value={value} onChange={handleChange} />
        </Form.Item>
      </Col>
    </>
  );
}

export default AddCategory;
