import { useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { tagPublicApi } from 'api/tagPublicApi';

export const TagPublicKey = {
  all: ['TagPublic'],
  lists: () => [...TagPublicKey.all, 'list'],
  list: (filter) => [...TagPublicKey.lists(), filter],
  details: () => [...TagPublicKey.all, 'detail'],
  detail: (id) => [...TagPublicKey.details(), id],
};

export const useTagPublic = ({ type, options }) => {
  return useQuery({
    queryKey: TagPublicKey.list(type),
    queryFn: () => tagPublicApi.getAll(type),
    ...options,
  });
};

export const usePrefetchTagPublic = (data, queryParams) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    if (data?.link?.next) {
      const newQueryParams = { ...queryParams, page: queryParams.page + 1 };

      queryClient.prefetchQuery(TagPublicKey.list(newQueryParams), () =>
        tagPublicApi.getAll(newQueryParams),
      );
    }
  }, [data, queryParams, queryClient]);
};
