import React from 'react';
import { Form, Col , Switch} from 'antd';

const Acolumn = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

function EnableCompanies({ form ,enable , setObjectFormFetch }) {
    const onChange = (value) => {
        form.current.setFieldsValue({
            enable: value ? 1 : 0,
          });
          setObjectFormFetch(prev => ({
            ...prev ,
            enable: value ? 1 : 0
          }))
    }
  return (
    <Col span={24}>
      <Form.Item
        name="enable"
        label="Hiện tức tin"
        {...Acolumn}
      >
        <Switch onChange={onChange} checked={enable === 1} />
      </Form.Item>
    </Col>
  );
}

export default EnableCompanies;
