import axiosClient from './axiosClient';

export const companyConfirmApi = {
  getAll(parmas) {
    const send = parmas.send ? `&send=${parmas.send}` : '';
    const confirm = parmas.confirm ? `&confirm=${parmas.confirm}` : '';
    return axiosClient.get(
      `/admin/company/confirm?page=${parmas.page}&page_size=${parmas.page_size}${send}${confirm}`,
    );
  },
  confirm(data) {
    return axiosClient.post('/admin/company/confirm', data);
  },
  refuse(data) {
    return axiosClient.post('/admin/company/confirm/refuse', data);
  },
  getid(id) {
    return axiosClient.get(`/admin/company/confirm/${id}`);
  },
};
