import React from 'react';
import styled from 'styled-components';

const CenterStyles = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px ;
`;

function WrapperCenter({ children }) {
  return <CenterStyles>{children}</CenterStyles>;
}

export default WrapperCenter;
