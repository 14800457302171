import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Form, Input, Select } from 'antd';
import { convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import styled from 'styled-components';
import FormData from 'form-data';
import { useNavigate, useParams } from 'react-router-dom';

import { TitleWrapper } from 'components/layout';
import HtmlToDraftfc from 'utils/HtmlToDraftfc';
import WrapperCenter from 'components/common/button/wrapperCenter';

import newsmanagementApi from 'api/newsmanagementApi';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useNewsCategories } from 'views/newsCategories/queries';
import UploadForm from 'components/form/Upload';
import { isIncludeTextSearch } from 'utils/utils';
import './styles.css';

const { Option } = Select;
const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
const tailLayout = {
  wrapperCol: {
    span: 24,
  },
};
const FeatureAddStyles = styled.div`
  .form {
    margin-top: 10px;
  }
  .select {
    min-width: 150px;
    width: auto !important;
  }
`;
const toolbarOptions = {
  options: [
    'inline',
    'blockType',
    'fontSize',
    'fontFamily',
    'list',
    'textAlign',
    'link',
    'image',
    'remove',
    'history',
    'embedded',
  ],
  colorPicker: false,
  emoji: false,
  inline: {
    options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
  },
};
const Acolumn = {
  labelCol: {
    span: 24,
  },
};

function AddFeatureKeyWords(props) {
  const intl = useIntl();
  let navigate = useNavigate();
  const [form] = Form.useForm();
  const formElemt = useRef();
  const keyword = useRef();
  const { id } = useParams();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [images, setImage] = useState('');
  const {
    data: ListNewsCategories,
    isLoading,
    isFetched,
  } = useNewsCategories({
    options: {
      keepPreviousData: true,
    },
  });
  const onEditorStateChange = (newState) => {
    setEditorState(newState);
  };
  const onFinish = async (values) => {
    const htmlString = draftToHtml(
      convertToRaw(editorState.getCurrentContent()),
    );
    values.detail = htmlString;
    const file = values.link?.file;
    values.link = '';
    let data = id
      ? await newsmanagementApi.edit(values, id)
      : await newsmanagementApi.add(values);
    if (data.error) {
      console.log('message', data.error);
    } else {
      if (file) {
        var form = new FormData();
        form.append('image', file);
        await newsmanagementApi.uploadAvatar(data.data.id, form);
      }
      navigate('/newsmanagement');
    }
  };
  async function fetchKeyWord(id) {
    const { data } = await newsmanagementApi.get(id);
    const NewDetail = HtmlToDraftfc(data.detail);
    setImage(data.avatar);
    data.detail = NewDetail;
    data.category_id = data?.category?.id;
    setEditorState(NewDetail);
    formElemt.current.setFieldsValue({ ...data });
  }
  useEffect(() => {
    if (id) {
      fetchKeyWord(id);
    }
    keyword.current.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <FeatureAddStyles>
      <TitleWrapper>
        {intl.formatMessage({
          id: id
            ? 'views.newsmanagement.title.edit'
            : 'views.newsmanagement.title.add',
        })}
      </TitleWrapper>
      <Form
        ref={formElemt}
        {...layout}
        form={form}
        name="control-hooks"
        onFinish={onFinish}
        className="form"
      >
        <Form.Item
          name="title"
          label="title"
          rules={[
            {
              required: true,
              message: 'không để trường này trống',
            },
          ]}
        >
          <Input ref={keyword} />
        </Form.Item>
        <Form.Item name="detail" label="detail">
          <Editor
            editorState={editorState}
            toolbar={{
              ...toolbarOptions,
              embedded: {
                embedCallback: (link) => {
                  // not work ??
                  if (
                    link.indexOf('youtube') >= 0 ||
                    link.indexOf('youtu.be/') >= 0
                  ) {
                    link = link.replace('watch?v=', 'embed/');
                    link = link.replace('/watch/', '/embed/');
                    link = link.replace('youtu.be/', 'youtube.com/embed/');
                  }
                  return link;
                },
              },
            }}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={onEditorStateChange}
          />
        </Form.Item>
        <UploadForm
          name="link"
          label="Hình ảnh đại diện"
          value={images}
          rules={!id}
        />
        {!isLoading && isFetched && (
          <Form.Item
            name="category_id"
            label="Thể loại tin tức"
            {...Acolumn}
            style={{
              width: '98%',
            }}
            rules={[
              {
                required: true,
                message: 'không để trường này trống',
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Thể loại tin tức"
              optionFilterProp="children"
              className="select"
              filterOption={(input, option) => {
                return isIncludeTextSearch(input, option.children);
              }}
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {ListNewsCategories.data.map((categories) => (
                <Option value={categories.id} key={categories.id}>
                  {categories.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item {...tailLayout}>
          <WrapperCenter>
            <Button type="primary" htmlType="submit">
              {id ? 'Sửa Tin tức' : 'Thêm Tin tức'}
            </Button>
          </WrapperCenter>
        </Form.Item>
      </Form>
    </FeatureAddStyles>
  );
}

export default AddFeatureKeyWords;
