import { useState, useMemo } from 'react';
import styled from 'styled-components';
import { ArrowLeftOutlined, SearchOutlined } from '@ant-design/icons';
import { Modal, Input, Radio } from 'antd';

import {
  useGetAllProvince,
  useGetListDistrict,
  useGetListWard,
  useGetListStreet,
} from '../../address/queries';
import { FilterLocationItem } from './FilterLocationItem';
import { MakerIcon } from '../../../assets/icons/index';
import { removeVietnameseFromString } from '../../../utils/stringUtils2';

const ModalStyles = styled(Modal)`
  .titleWrapper {
    display: flex;
    align-items: center;

    .titleInfo {
      flex-grow: 1;
      text-align: center;
    }
  }

  .filterInput {
    border: none;

    .ant-input-prefix {
      .anticon {
        font-size: 16px;
      }
    }

    .ant-input {
      &::-webkit-input-placeholder {
        color: #057642;
      }
      &::-moz-placeholder {
        color: #057642;
      }
      &:-ms-input-placeholder {
        color: #057642;
      }
      &:-moz-placeholder {
        color: #057642;
      }
    }
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: transparent;
  }

  .ant-input-affix-wrapper-focused {
    box-shadow: none;
  }
`;

export const FilterLocationModal = ({ data, setData }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [current, setCurrent] = useState(0);
  const [option, setOption] = useState('WARD');
  const [query, setQuery] = useState('');
  const { data: provinces = [] } = useGetAllProvince({ id: current });
  const { data: districts = [] } = useGetListDistrict({
    id: data.province?.id,
  });
  const { data: wards = [] } = useGetListWard({
    id: data.district?.id,
  });
  const { data: streets = [] } = useGetListStreet({
    id: data.district?.id,
  });
  const filteredData = useMemo(() => {
    const bool =
      provinces?.data?.data &&
      districts?.data?.data &&
      wards?.data?.data &&
      streets?.data?.data;
    if (current === 0 && bool) {
      return [{ id: '', name: 'Toàn quốc' }, ...provinces.data.data];
    } else if (current === 1 && bool) {
      return [{ id: '', name: 'Tất cả' }, ...districts.data.data];
    } else if (current === 2 && bool) {
      if (option === 'WARD' && bool) {
        return [{ id: '', name: 'Tất cả' }, ...wards.data.data];
      } else if (option === 'STREET' && bool) {
        return [{ id: '', name: 'Tất cả' }, ...streets.data.data];
      }
    }

    return [];
  }, [current, provinces, districts, wards, streets, option]);
  const filteredDataWithQuery = filteredData.filter((item) =>
    removeVietnameseFromString(item.name)
      .toUpperCase()
      .includes(removeVietnameseFromString(query).toUpperCase()),
  );

  const renderedTitle = useMemo(() => {
    return (
      <div className="titleWrapper">
        {current !== 0 && (
          <ArrowLeftOutlined onClick={() => setCurrent((prev) => prev - 1)} />
        )}
        <p className="titleInfo">
          {current === 0
            ? `Chọn Tỉnh/Thành phố`
            : current === 1
            ? `Chọn Quận/Huyện : ${data?.province?.name || 'Tất cả'} `
            : current === 2
            ? `Chọn Phường/Xã/Đường : ${data.district?.name || 'Tất cả'} `
            : ''}
        </p>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);

  const renderedLabel = useMemo(() => {
    const array = Object.values(data).filter((item) => item);
    return (
      array.reduce(
        (string, item) =>
          item?.id ? (string += `${item?.name} `) : (string += ''),
        '',
      ) || 'Toàn quốc'
    );
  }, [data]);
  const handleCancel = () => {
    setIsVisible(false);
  };

  const handleClick = (item) => {
    if (current === 0) {
      setData((prev) => ({
        ...prev,
        province: item,
        district: null,
        ward: null,
        street: null,
      }));
    } else if (current === 1) {
      setData((prev) => ({
        ...prev,
        district: item,
        ward: null,
        street: null,
      }));
    } else if (current === 2) {
      setData((prev) => ({
        ...prev,
        ward: option === 'WARD' ? item : null,
        street: option === 'STREET' ? item : null,
      }));
    }

    if (item.id === '') {
      handleCancel();
      return;
    }

    if (current < 2) {
      setCurrent((prev) => prev + 1);
    } else {
      handleCancel();
    }
    setQuery('');
  };

  return (
    <>
      <div
        onClick={() => setIsVisible(true)}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: '1px solid #ECECEC',
          borderRadius: 4,
          minWidth: 180,
          cursor: 'pointer',
          background: '#fff',
          padding: '9px 10px',
        }}
      >
        <MakerIcon style={{ fontSize: 16 }} />
        <span style={{ marginLeft: 4 }}>{renderedLabel}</span>
      </div>

      <ModalStyles
        visible={isVisible}
        footer={null}
        bodyStyle={{
          maxHeight: 500,
          height: '100%',
          overflowY: 'auto',
          padding: 0,
        }}
        title={renderedTitle}
        onCancel={handleCancel}
      >
        <Input
          placeholder="Tìm kiếm nhanh"
          prefix={<SearchOutlined />}
          className="filterInput"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />

        {current === 2 && (
          <Radio.Group
            value={option}
            buttonStyle="solid"
            onChange={(e) => setOption(e.target.value)}
          >
            <Radio.Button value="WARD">Phường/Xã</Radio.Button>
            <Radio.Button value="STREET">Đường</Radio.Button>
          </Radio.Group>
        )}
        {filteredDataWithQuery.map((item) => {
          return (
            <FilterLocationItem key={item.id} onClick={() => handleClick(item)}>
              {item.name}
            </FilterLocationItem>
          );
        })}
      </ModalStyles>
    </>
  );
};
