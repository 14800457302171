
import { useIntl } from 'react-intl';


import { calcIndexByOrder } from 'utils';

import useCompanyinformation from './useCompanyinformation';
import useAction from './useAction';

function useColumns({ search, onChange }) {

  const intl = useIntl();
  const { columns: Companyinformation } = useCompanyinformation();
  const { columns: Action } = useAction({ onChange });
  const columns = [
    {
      title: intl.formatMessage({ id: 'common.index' }),
      render: (_, __, index) =>
        calcIndexByOrder({ index, page: search.page, limit: search.page_size }),
      align: 'center',
      width: 100,
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'company.companyinformation' }),
      dataIndex: 'companyinformation',
      align: 'center',
      width: 200,
      children: Companyinformation
    },
    {
      title: intl.formatMessage({ id: 'company.boxAction' }),
      dataIndex: 'enable',
      align: 'center',
      width: 300,
      fixed: 'right',
      children: Action
    },
  ];
  return { columns }
}

export default useColumns
