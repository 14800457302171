import styled from 'styled-components';
import classNames from 'classnames';

const HistoryItemStyles = styled.div`
  font-weight: 700;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  min-width: 80px;
  text-align: center;

  .day {
    margin-bottom: 16px;
  }

  .day-label {
    font-weight: 400;
  }

  .day-summary {
    border: solid 1px #b5b5b5;
    border-right: none;
    border-left: none;
    > * {
      padding: 13px 5px;
    }
  }
  .date {
    border-bottom: solid 1px #b5b5b5;
  }

  &:first-child {
    .day-summary {
      border-left: solid 1px #b5b5b5;
    }
  }
  &:last-child {
    .day-summary {
      border-right: solid 1px #b5b5b5;
    }
  }

  &:nth-child(odd) {
    .day-summary {
      background-color: var(--white);
    }
  }
  &:nth-child(even) {
    .day-summary {
      background-color: var(--grayLight);
    }
  }

  &.highlight .day-summary {
    background-color: var(--yellow);
  }
`;

export default function HistoryItem({ day, date, amount, is_highlight }) {
  // Prop: isLabel
  // formatAmount function

  return (
    <HistoryItemStyles
      className={classNames({
        highlight: is_highlight,
      })}
    >
      {day && <div className="day">{day}</div>}
      <div className="day-summary">
        <div className="date">{date}</div>
        <div className="amount">{amount}</div>
      </div>
    </HistoryItemStyles>
  );
}

HistoryItem.defaultProps = {};

HistoryItem.propTypes = {};
