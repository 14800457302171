import React from 'react'
import { Switch } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';


function useAction({ onChange }) {
  const intl = useIntl();
  let navigate = useNavigate();
  const handleEdit = (id) => {
    navigate(`/companies/edit/${id}`);
  };
  const columns = [
    {
      title: intl.formatMessage({ id: 'company.enable' }),
      dataIndex: 'enable',
      align: 'center',
      width: 150,
      fixed: 'right',
      render: (_, item) => (
        <>
          <Switch
            checked={item.enable === 1}
            onChange={() => onChange(item)}
          />
        </>
      ),
    },
    {
      title: intl.formatMessage({ id: 'company.action' }),
      dataIndex: ['Tác vụ', 'name'],
      align: 'center',
      width: 150,
      fixed: 'right',
      render: (_, value) => {
        return (
          <>
            <EditOutlined
              className={'action'}
              onClick={() => handleEdit(value.id)}
            />
          </>
        );
      },
    },
  ];
  return { columns };
}

export default useAction;
