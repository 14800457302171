import axiosClient from './axiosClient';

export const tagApi = {
  getAll(params) {
    return axiosClient.get('/admin/tags/', { params });
  },
  delete(ids) {
    return axiosClient.delete(`/admin/tags`, { data: { ids: ids } });
  },
  add(keyword) {
    return axiosClient.post(`/admin/tags`, keyword);
  },
  edit({ data, id }) {
    return axiosClient.patch(`/admin/tags/${id}`, data);
  },
  get(id) {
    return axiosClient.get(`/admin/tags/${id}`);
  }
};
