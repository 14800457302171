import { memo, useState } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { ContentWrapper, TitleWrapper } from 'components/layout';
import { ButtonAddNew } from 'components/common/button';
import ButtonOther from 'components/common/button/ButtonOther';
import { TableWrapper } from "components";
import {
  calcIndexByOrder,
} from 'utils';
import { EditOutlined } from '@ant-design/icons';

import { PATH_NAME, PATH_NAME_ACTION } from 'constants/routes';
import { useNavigate } from "react-router-dom";

import { useDeleteSetting, useSettings } from './queries';
import { useCustomSearchParams } from "hooks/useCustomSearchParams";

const SettingsManagementStyles = styled.div`

`

const Settingsmanagement = () => {

  const intl = useIntl();

  const [search, setSearch] = useCustomSearchParams();

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loadingReload, setLoadingReload] = useState(false);
  const [loadingDelete, setLoadingDetele] = useState(false);

  let navigate = useNavigate();
  const argument = {
    // params: search,
    options: {
      keepPreviousData: true,
    },
  };

  const { mutate } = useDeleteSetting(argument);

  const { data, isPreviousData, isFetching } = useSettings(argument);
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };


  const hasSelected = selectedRowKeys.length > 0;
  const deleteSelected =
    selectedRowKeys.length > 0 && selectedRowKeys.length < 2;
    
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  
  const handleEdit = (id) => {
    navigate(`/settings/edit/${id}`);
  };

  const handleReload = () => {
    setLoadingReload(true); // ajax request after empty completing
    setTimeout(() => {
      setLoadingReload(false);
    }, 1000);
    setSelectedRowKeys([]);
  };

  const handleDelete = () => {
    setLoadingDetele(true); // ajax request after empty completing
    setTimeout(() => {
      setLoadingDetele(false);
      mutate(selectedRowKeys[0]);
      setSelectedRowKeys([]);
    }, 1000);
    setSelectedRowKeys([]);
  };
  const handleChange = ({ current, pageSize }) =>
    setSearch({ ...search, page: current, page_size: pageSize });


  const columns = [
    {
      title: intl.formatMessage({ id: 'common.index' }),
      render: (_, __, index) =>
        calcIndexByOrder({ index, page: 1, limit: 10 }),
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'settings.title' }),
      dataIndex: 'title',
      align: 'center',
    },
    // {
    //   title: intl.formatMessage({ id: 'settings.content' }),
    //   dataIndex: 'content',
    //   align: 'center',
    // },
    {
      title: intl.formatMessage({ id: 'settings.type' }),
      dataIndex: 'type',
      align: 'center',
      render:(_, value) => (
        <>
          {renderType(value?.type)}
        </>
      ),
    },
    {
      title: intl.formatMessage({ id: 'topic.action' }),
      dataIndex: 'action',
      align: 'center',
      render: (_, value) => (
        <>
          <EditOutlined
            className={'action'}
            onClick={() => handleEdit(value.id)}
          />
        </>
      ),
    },
  ];

  const renderType = (key) => {
    switch (key) {
      case 'rules':
        return 'Điều khoản'
      case 'recharge_form':
        return 'Mẫu nạp tiền'
      default:
        return 'Hướng dẫn'
    }
  }

  const renderExtra = (
    <>
      <ButtonAddNew to={`${PATH_NAME.SETTINGS}/${PATH_NAME_ACTION.CREATE}`} />
      <ButtonOther
        type="default"
        onClick={handleDelete}
        disabled={!deleteSelected}
        loading={loadingDelete}
      >
        Xóa
      </ButtonOther>
      <ButtonOther
        type="default"
        onClick={handleReload}
        disabled={!hasSelected}
        loading={loadingReload}
      >
        Làm mới
      </ButtonOther>
    </>
  );

  return (
    <SettingsManagementStyles>
      <TitleWrapper>
        {intl.formatMessage({ id: 'views.settings.title.list' })}
      </TitleWrapper>

      <ContentWrapper>

        {/* List render Tag */}
        <TableWrapper
          rowKey="id"
          columns={columns}
          dataSource={data?.data || []}
          loading={isPreviousData && isFetching}
          rowSelection={rowSelection}
          pagination={{
            position: ['bottomCenter'],
            current: search.page,
            pageSize: search.page_size,
            total: data?.meta?.total ?? 0,
          }}
          onChange={handleChange}
          extra={renderExtra}
        />
      </ContentWrapper>

    </SettingsManagementStyles>
  )

}

export default memo(Settingsmanagement)