import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';

const ButtonStyles = styled.div`
  .btn {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: ${({ theme }) => theme.colors.primary};
    padding : 8px 16px;
    height : auto;
    border-radius: 4px;
  }
`;
function ButtonOther(props) {
  return (
    <ButtonStyles>
      <Button {...props} className="btn">
        {props.children}
      </Button>
    </ButtonStyles>
  );
}

export default ButtonOther;
