import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Modal } from 'antd';

import { MODAL_WIDTH } from 'constants/styles';
import { ButtonStyle } from 'components';

const ModalElement = styled(Modal)`
  .ant-modal-title {
    font-size: 20px;
    font-weight: 700;
  }

  &.import_export {
    .ant-radio-wrapper {
      align-items: center;
      span.ant-radio + span {
        font-weight: 600;
        padding-top: 5px;
      }
    }

    .ant-radio-inner {
      width: 24px;
      height: 24px;
      border-width: 2px;
      border-color: var(--blue);

      &::after {
        width: 24px;
        height: 24px;
        margin-top: -12px;
        margin-left: -12px;
        background-color: var(--white);
      }
    }

    .ant-radio-checked .ant-radio-inner {
      background-color: var(--blue);
      border-color: var(--blue);
    }
  }
`;

export default function ModalWrapper({ children, ...props }) {
  const intl = useIntl();
  return (
    <ModalElement
      footer={[
        <ButtonStyle
          key="submit"
          type="green"
          onClick={props.onOk}
          loading={props.confirmLoading}
        >
          {props.okText ?? intl.formatMessage({ id: 'btn.submit' })}
        </ButtonStyle>,
        <ButtonStyle key="back" onClick={props.onCancel}>
          {props.cancelText ?? intl.formatMessage({ id: 'btn.cancel' })}
        </ButtonStyle>,
      ]}
      width={MODAL_WIDTH}
      {...props}
    >
      {children}
    </ModalElement>
  );
}
