import { useState } from 'react';
import { useIntl } from 'react-intl';
import ButtonOther from 'components/common/button/ButtonOther';
import {
  EditOutlined

} from '@ant-design/icons';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { TitleWrapper, ContentWrapper } from 'components/layout';

import { useCustomSearchParams } from 'hooks/useCustomSearchParams';
import { useDeleteCategory, useCategory } from './queries';
import { ButtonAddNew } from 'components/common/button';
import { TableWrapper } from 'components';
import { PATH_NAME, PATH_NAME_ACTION } from 'constants/routes';

import { calcIndexByOrder } from 'utils/common';

const FeatureKeyWordStyles = styled.div`
  .action {
    margin: 0px 5px;
  }
  .action:hover {
    opacity: 0.7;
    cursor: pointer;
  }
  .ant-table-wrapper {
  }
`;
function Category(props) {
  const intl = useIntl();
  const [search, setSearch] = useCustomSearchParams();
  // table
  let navigate = useNavigate();
  const argument = {
    params: search,
    options: {
      keepPreviousData: true,
    },
  };
  const { mutate } = useDeleteCategory(argument);
  //

  const { data, isPreviousData, isFetching } = useCategory(argument);
  const List = data?.data.data || [];
  //  table
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loadingReload, setLoadingReload] = useState(false);
  const [loadingDelete, setLoadingDetele] = useState(false);
  const hasSelected = selectedRowKeys.length > 0;
  const deleteSelected =
    selectedRowKeys.length > 0 && selectedRowKeys.length < 2;
  const handleReload = () => {
    setLoadingReload(true); // ajax request after empty completing
    setTimeout(() => {
      setLoadingReload(false);
    }, 1000);
    setSelectedRowKeys([]);
  };
  const handleDelete = () => {
    setLoadingDetele(true); // ajax request after empty completing
    setTimeout(() => {
      setLoadingDetele(false);
      mutate(selectedRowKeys[0]);
      setSelectedRowKeys([]);
    }, 1000);
    setSelectedRowKeys([]);
  };
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const handleEdit = (id) => {
    navigate(`/category/edit/${id}`);
  };
  const columns = [
    {
      title: intl.formatMessage({ id: 'common.index' }),
      render: (_, __, index) =>
        calcIndexByOrder({ index, page: search.page, limit: search.page_size }),
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'category.level_1' }),
      dataIndex: 'level_1',
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'category.level_2' }),
      dataIndex: 'level_2',
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'category.level_3' }),
      dataIndex: 'level_3',
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'category.level_4' }),
      dataIndex: 'level_4',
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'category.level_5' }),
      dataIndex: 'level_5',
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'category.name' }),
      dataIndex: 'name',
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'featuredkeyword.action' }),
      dataIndex: 'action',
      align: 'center',
      render: (_, value) => (
        <>
          <EditOutlined
            className={'action'}
            onClick={() => handleEdit(value.id)}
          />
        </>
      ),
    },
  ];
  const handleChange = ({ current, pageSize }) =>
    setSearch({ ...search, page: current, page_size: pageSize });
  const renderExtra = (
    <>
      <ButtonAddNew to={`${PATH_NAME.CATEGORY}/${PATH_NAME_ACTION.CREATE}`} />
      <ButtonOther
         type="default"
        onClick={handleDelete}
        disabled={!deleteSelected}
        loading={loadingDelete}
      >
        Xóa
      </ButtonOther>
      <ButtonOther
         type="default"
        onClick={handleReload}
        disabled={!hasSelected}
        loading={loadingReload}
      >
        Làm mới
      </ButtonOther>
    </>
  );
  return (
    <>
      <FeatureKeyWordStyles>
        <TitleWrapper>
          {intl.formatMessage({ id: 'views.category.title.list' })}
        </TitleWrapper>
        <ContentWrapper>
          <TableWrapper
            rowKey="id"
            columns={columns}
            dataSource={List}
            loading={isPreviousData && isFetching}
            rowSelection={rowSelection}
            pagination={{
              current: search.page,
              position: ['bottomCenter'],
              pageSize: search.page_size,
              total: List.meta?.total ?? 0,
            }}
            onChange={handleChange}
            extra={renderExtra}
          />
        </ContentWrapper>
      </FeatureKeyWordStyles>
    </>
  );
}

export default Category;
