import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

export const EnableStatus = ({ status }) => {
  const theme = useTheme();
  const Icon = status ? CheckOutlined : CloseOutlined;

  return (
    <Icon
      style={{
        fontSize: 24,
        color: status ? theme.colors.success : theme.colors.error,
      }}
    />
  );
};

EnableStatus.defaultProps = {
  status: false,
};

EnableStatus.propTypes = {
  status: PropTypes.bool,
};
