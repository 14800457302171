import styled from 'styled-components';
import { Row, Col, InputNumber, Form, Space } from 'antd';

const OddItemStyles = styled.div`
  background-color: var(--grayWhite);
  font-weight: 700;

  > .ant-row {
    background-color: #fafafa;
    border-bottom: #ddd 1px solid;
    min-height: 50px;

    > .ant-col {
      display: flex;
      align-items: center;

      &:nth-child(even) {
        background-color: #63a0af10;
      }
    }
  }

  .name {
    padding: 0 8px;
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-input-number {
    width: 140px;
  }

  .price {
    min-width: 76px;
    text-align: center;
  }
`;

export default function OddItem({ data, sid }) {
  return (
    <OddItemStyles>
      {data.length > 0 &&
        data.map(({ code, name, price }) => (
          <Row key={code}>
            <Col span={6}>
              <div className="name">{name}</div>
            </Col>
            <Col span={4}></Col>
            <Col span={4}>
              <Space style={{ width: '100%', justifyContent: 'space-around' }}>
                <Form.Item name={`${sid}___${code}___${name}___${price}`}>
                  <InputNumber />
                </Form.Item>
                <div className="price">{price}</div>
              </Space>
            </Col>
            <Col span={4}></Col>
            <Col span={6}></Col>
          </Row>
        ))}
    </OddItemStyles>
  );
}
