import styled from 'styled-components';
import { formatTime } from 'utils/timeUtils';

import OddItem from './OddItem';

const MatchItemStyles = styled.div`
  .date {
    color: var(--white);
    background-color: var(--naviGreen);
    padding: 4px;
    text-align: center;
  }

  .info {
    display: flex;
    align-items: center;
    background-color: var(--grayLight);

    .time {
      min-width: 100px;
      text-align: center;
      color: var(--white);
      background-color: var(--green);
      padding: 9px;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        background-color: var(--red);
      }
    }
    .desc {
      padding: 0 12px;
    }
  }
`;

export default function MatchItem({
  team1,
  team1_initials,
  team2,
  team2_initials,
  time,
  odds,
}) {
  return (
    <MatchItemStyles>
      <div className="date">{formatTime(time, 'dddd MMM DD')}</div>
      <div className="info">
        <div className="time">{formatTime(time, 'h:mma')}</div>
        <div className="desc">
          {team1}({team1_initials}) vs {team2}({team2_initials})
        </div>
      </div>
      <div className="odds">{odds.length > 0 && <OddItem {...odds[0]} />}</div>
    </MatchItemStyles>
  );
}
