const GetTime = (date) => {
  let d = new Date(date);
  const AddZero = (DateTime) => {
    if (DateTime < 10) {
      return "0" + DateTime;
    }
    return DateTime;
  };
  let year = d.getFullYear();
  let month = AddZero(d.getMonth() + 1);
  let day = AddZero(d.getDate());
  let dayofweek = d.getDay();
  let hours = AddZero(d.getHours());
  let minutes = AddZero(d.getMinutes());
  let seconds = AddZero(d.getSeconds());
  return {
    year,
    month,
    day,
    dayofweek,
    hours,
    minutes,
    seconds,
  };
};

export default GetTime;
