import axiosClient from './axiosClient';

export const footerApi = {
  getAll(params) {
    return axiosClient.get('/admin/footer/', { params });
  },
  delete(id) {
    return axiosClient.delete(`/admin/footer/${id}/`);
  },
  add(footer) {
    return axiosClient.post(`/admin/footer`, footer);
  },
  edit(footer, id) {
    return axiosClient.patch(`/admin/footer/${id}`, footer);
  },
  get(id) {
    return axiosClient.get(`/admin/footer/${id}`);
  },
};
