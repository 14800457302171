import React, { useEffect, useState } from 'react';

import { AddressApi } from 'api/AddressApi';
function UseGetaddressData({ province, district }) {
  const [address, setAddress] = useState({
    listProvince: [],
    listDistrict: [],
    listWarn: [],
    listStreet: []
  })
  async function fetchApi() {
    const ObjectNew = {
      listProvince: [],
      listDistrict: [],
      listWarn: [],
      listStreet: []
    }
    const listProvince = await AddressApi.GetAllProvince();
    ObjectNew.listProvince = listProvince?.data?.data;
    if (province) {
      const ListDistrict = await AddressApi.GetListDistrict(province);
      ObjectNew.listDistrict = ListDistrict?.data?.data;
    }
    if (district) {
      const listWarn = await AddressApi.GetListWarn(district);
      ObjectNew.listWarn = listWarn?.data?.data;
      const listStreet = await AddressApi.GetListStreet(district);
      ObjectNew.listStreet = listStreet?.data?.data;
    }
    setAddress(ObjectNew);
  }
  useEffect(() => {
    fetchApi()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [province, district])
  return address || {};
}

export default UseGetaddressData;
