import { useIntl } from 'react-intl';
import { Select, Col, Row, Form } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';
import { EditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import { TitleWrapper, ContentWrapper } from 'components/layout';
import { ButtonAddNew } from 'components/common/button';
import ButtonOther from 'components/common/button/ButtonOther';
import { TableWrapper } from 'components/common/table';
import { useCustomSearchParams } from 'hooks/useCustomSearchParams';
import {
  useGetListDistrict,
  useGetListWard,
  useWarnDelete,
  useGetAllProvince,
} from '../queries';

const { Option } = Select;
export default function District() {
  const intl = useIntl();
  const { mutate } = useWarnDelete();
  const [search, setSearch] = useCustomSearchParams();
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const [objectAddress, setObjectAddress] = useState({
    province: 10,
    district: 145,
  });
  const HandleValuesChange = (value) => {
    if (value.province) {
      setObjectAddress(value);
    } else {
      setObjectAddress((prev) => {
        return {
          ...prev,
          ...value,
        };
      });
    }
  };
  const { data: AllProvince } = useGetAllProvince({
    params: 'ProvinceAllData',
    setData,
  });
  const { data: AllDistrict } = useGetListDistrict({
    id: objectAddress.province,
    district: 'district',
    setData,
    setObjectAddress,
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loadingReload, setLoadingReload] = useState(false);
  const [loadingDelete, setLoadingDetele] = useState(false);
  const hasSelected = selectedRowKeys.length > 0;
  const deleteSelected =
    selectedRowKeys.length > 0 && selectedRowKeys.length < 2;
  const handleReload = () => {
    setLoadingReload(true); // ajax request after empty completing
    setTimeout(() => {
      setLoadingReload(false);
    }, 1000);
    setSelectedRowKeys([]);
  };
  const handleDelete = () => {
    setLoadingDetele(true); // ajax request after empty completing
    setTimeout(() => {
      setLoadingDetele(false);
      mutate(selectedRowKeys[0]);
      setSelectedRowKeys([]);
    }, 1000);
    setSelectedRowKeys([]);
  };
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const handleEdit = (id) => {
    navigate(
      `/address/edit/ward/${id}?district_id=${objectAddress.district}&province_id=${objectAddress.province}`,
    );
  };
  const renderExtra = (
    <>
      <ButtonAddNew
        to={`/address/create/ward?district_id=${objectAddress.district}&province_id=${objectAddress.province}`}
      >
        Thêm mới
      </ButtonAddNew>
      <ButtonOther
        type="default"
        onClick={handleDelete}
        disabled={!deleteSelected}
        loading={loadingDelete}
      >
        Xóa
      </ButtonOther>
      <ButtonOther
        type="default"
        onClick={handleReload}
        disabled={!hasSelected}
        loading={loadingReload}
      >
        Làm mới
      </ButtonOther>
    </>
  );
  const List = AllProvince?.data?.data || [];
  const ListDistrict = AllDistrict?.data?.data || [];
  useGetListWard({
    id: objectAddress.district,
    setData,
    change: ListDistrict.length + List.length,
  });
  const columns = [
    {
      title: intl.formatMessage({ id: 'address.index' }),
      align: 'center',
      render: (_, item, index) => <p>{index + 1}</p>,
    },
    {
      title: intl.formatMessage({ id: 'address.name' }),
      dataIndex: 'name',
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'address.keyword' }),
      dataIndex: 'keyword',
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'address.action' }),
      render: (_, item, index) => (
        <div>
          <EditOutlined
            className="address__icon"
            onClick={() => handleEdit(item.id)}
          />
        </div>
      ),
      align: 'center',
    },
  ];

  const handleChange = ({ current, pageSize }) => {
    setSearch({ ...search, page: current, page_size: pageSize });
  };
  const DistrictContainer = styled.div`
    .address__icon {
      margin-left: 10px;
      cursor: pointer;
    }
  `;
  return (
    <>
      <TitleWrapper>
        {intl.formatMessage({ id: 'views.address.title.ward.title' })}
      </TitleWrapper>

      <ContentWrapper>
        <DistrictContainer>
          <Form name="basic" onValuesChange={HandleValuesChange}>
            <Row>
              <Col span={8}>
                <Form.Item
                  name="province"
                  initialValue={objectAddress.province}
                  label="Tỉnh"
                  style={{
                    width: '98%',
                  }}
                  rules={[
                    {
                      required: true,
                      message: 'không để trường này trống',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Chọn tỉnh"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.includes(input)
                    }
                    rowSelection={rowSelection}
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {List.map((province) => (
                      <Option value={province.id} key={province.id}>
                        {province.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="district"
                  initialValue={objectAddress.district}
                  label="Huyện"
                  style={{
                    width: '98%',
                  }}
                  rules={[
                    {
                      required: true,
                      message: 'không để trường này trống',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Chọn Thị trấn và Huyện và Phường"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {ListDistrict.map((district) => (
                      <Option value={district.id} key={district.id}>
                        {district.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <TableWrapper
            rowKey="id"
            columns={columns}
            dataSource={data || []}
            onValuesChange={HandleValuesChange}
            onChange={handleChange}
            extra={renderExtra}
            rowSelection={rowSelection}
            pagination={{
              position: ['bottomCenter'],
              current: search.page,
              pageSize: search.page_size,
              total: data?.meta?.total ?? 0,
            }}
          />
        </DistrictContainer>
      </ContentWrapper>
    </>
  );
}
