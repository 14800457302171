import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import styled from 'styled-components';

import { RightOutlined, LeftOutlined } from '@ant-design/icons';

import { SIDEBAR_MAX_WIDTH } from 'constants/styles';
import { PATH_NAME } from 'constants/routes';
import { useLocation } from 'react-router-dom';

import LogoBackground from '../../assets/jpg/sidebar.jpg';
import Logo from '../../assets/png/logo.svg';
const SiderStyles = styled(Layout.Sider)`
  min-height: 100%;
  background: url(${LogoBackground});
  background-position: center;
  background-size: cover;
  margin-right: 48px;
  background-repeat: no-repeat;
  .ant-menu-root.ant-menu-vertical,
  .ant-menu-root.ant-menu-vertical-left,
  .ant-menu-root.ant-menu-vertical-right,
  .ant-menu-root.ant-menu-inline {
    background: transparent !important;
    border-right: 1px solid transparent !important;
  }
  .ant-layout-sider {
    background: transparent;
  }
  .ant-menu-item.ant-menu-item-selected {
    margin: 0px;
  }
  .ant-menu-vertical .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after,
  .ant-menu-inline .ant-menu-item::after {
    border-right: 3px solid #3dd3be;
  }
  .ant-menu-item-selected a,
  .ant-menu-item-selected a:hover {
    color: #000000;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
  }
  .ant-menu-item a {
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
  }
  .zoomOut.ant-menu-light .ant-menu-item,
  li.ant-menu-submenu.ant-menu-submenu-vertical {
    padding: 0px !important;
    img,
    span {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .zoomOut .ant-menu-submenu .ant-menu-item {
    color: #333;
  }
  .ant-menu-light .ant-menu-item {
    padding: 15px 0px;
  }
  .ant-menu-light .ant-menu-item:hover {
    background-color: #fff;
    ::after {
      border-right: none;
      right: unset;
      left: 0;
      display: block;
      height: 46px;
      transform: none;
      opacity: 1;
      border-left: 3px solid #3dd3be;
      position: absolute;
    }
  }
  .ant-menu-item-selected,
  .ant-menu-light .ant-menu-item:hover {
    color: #000000;
  }
  .Boxicon {
    position: absolute;
    right: -2px;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    border: 2px solid #fbfbfb;
    padding: 5px 7px;
    font-size: 12px;
  }
  .header {
    margin-top: 27px;
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    cursor: pointer;
    .logo {
      img {
        width: 100px;
        object-fit: cover;
      }
    }
  }
`;
const MenuItemStyles = styled(Layout.Sider)`
  a {
    display: flex;
    align-items: center;
    background: transparent;
  }
  &.zoomOut {
    width: 0px !important;
    max-width: 0px !important;
    min-width: 0px !important;
  }
`;
const MenuItem = ({ to, title, collapsed = false }) => {
  const intl = useIntl();
  return (
    <MenuItemStyles className={collapsed ? 'zoomOut' : ''}>
      {intl.formatMessage({
        id: `sidebar.${to ? to.substring(1) : title.substring(1)}`,
      })}
    </MenuItemStyles>
  );
};

export default function Sidebar() {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);

  const items = [
    {
      label: <MenuItem to={PATH_NAME.COMPANIES} />,
      key: PATH_NAME.COMPANIES,
      icon: (
        <img
          src={process.env.PUBLIC_URL + '/assets/image/icon/Home.png'}
          alt={PATH_NAME.COMPANIES}
        />
      ),
    },
    {
      label: <MenuItem to={PATH_NAME.COMPANYTYPE} />,
      key: PATH_NAME.COMPANYTYPE,
      icon: (
        <img
          src={process.env.PUBLIC_URL + '/assets/image/icon/News.png'}
          alt={PATH_NAME.COMPANIES}
        />
      ),
    },
    {
      label: <MenuItem to={PATH_NAME.USERS} />,
      key: PATH_NAME.USERS,
      icon: (
        <img
          src={process.env.PUBLIC_URL + '/assets/image/icon/User.png'}
          alt={PATH_NAME.COMPANIES}
        />
      ),
    },
    {
      label: <MenuItem to={PATH_NAME.FEATUREDKEYWORD} />,
      key: PATH_NAME.FEATUREDKEYWORD,
      icon: (
        <img
          src={process.env.PUBLIC_URL + '/assets/image/icon/Search.png'}
          alt={PATH_NAME.COMPANIES}
        />
      ),
    },
    {
      label: <MenuItem to={PATH_NAME.BLOGS} />,
      key: PATH_NAME.BLOGS,
      icon: (
        <img
          src={process.env.PUBLIC_URL + '/assets/image/icon/News.png'}
          alt={PATH_NAME.COMPANIES}
        />
      ),
    },
    {
      label: <MenuItem to={PATH_NAME.NEWSMANAGEMENT} />,
      key: PATH_NAME.NEWSMANAGEMENT,
      icon: (
        <img
          src={process.env.PUBLIC_URL + '/assets/image/icon/Document.png'}
          alt={PATH_NAME.COMPANIES}
        />
      ),
    },
    {
      label: <MenuItem to={PATH_NAME.TRANSACTIONMANAGEMENT} />,
      key: PATH_NAME.TRANSACTIONMANAGEMENT,
      icon: (
        <img
          src={process.env.PUBLIC_URL + '/assets/image/icon/transaction.png'}
          alt={PATH_NAME.TRANSACTIONMANAGEMENT}
        />
      ),
    },
    {
      label: <MenuItem to={PATH_NAME.CATEGORY} />,
      key: PATH_NAME.CATEGORY,
      icon: (
        <img
          src={process.env.PUBLIC_URL + '/assets/image/icon/Category.png'}
          alt={PATH_NAME.COMPANIES}
        />
      ),
    },
    {
      label: <MenuItem to={PATH_NAME.FOOTER} />,
      key: PATH_NAME.FOOTER,
      icon: (
        <img
          src={process.env.PUBLIC_URL + '/assets/image/icon/Footer.png'}
          alt={PATH_NAME.COMPANIES}
        />
      ),
    },
    {
      label: <MenuItem to={PATH_NAME.NEWS_CATEGORIES} />,
      key: PATH_NAME.NEWS_CATEGORIES,
      icon: (
        <img
          src={process.env.PUBLIC_URL + '/assets/image/icon/news-category.png'}
          alt={PATH_NAME.COMPANIES}
        />
      ),
    },
    {
      label: <MenuItem to={false} title={'/address.index'} />,
      key: '/address.index',
      icon: (
        <img
          src={process.env.PUBLIC_URL + '/assets/image/icon/address.png'}
          alt={PATH_NAME.COMPANIES}
        />
      ),
      children: [
        {
          label: (
            <MenuItem
              to={`/${PATH_NAME.ADDRESS.province}`}
              collapsed={collapsed}
            />
          ),
          key: `/${PATH_NAME.ADDRESS.index}/${PATH_NAME.ADDRESS.province}`,
          icon: (
            <img
              src={
                process.env.PUBLIC_URL + '/assets/image/icon/chevron-right.png'
              }
              alt={PATH_NAME.COMPANIES}
            />
          ),
        },
        {
          label: (
            <MenuItem
              to={`/${PATH_NAME.ADDRESS.district}`}
              collapsed={collapsed}
            />
          ),
          key: `/${PATH_NAME.ADDRESS.index}/${PATH_NAME.ADDRESS.district}`,
          icon: (
            <img
              src={
                process.env.PUBLIC_URL + '/assets/image/icon/chevron-right.png'
              }
              alt={PATH_NAME.COMPANIES}
            />
          ),
        },
        {
          label: (
            <MenuItem to={`/${PATH_NAME.ADDRESS.ward}`} collapsed={collapsed} />
          ),
          key: `/${PATH_NAME.ADDRESS.index}/${PATH_NAME.ADDRESS.ward}`,
          icon: (
            <img
              src={
                process.env.PUBLIC_URL + '/assets/image/icon/chevron-right.png'
              }
              alt={PATH_NAME.COMPANIES}
            />
          ),
        },
        {
          label: (
            <MenuItem
              to={`/${PATH_NAME.ADDRESS.street}`}
              collapsed={collapsed}
            />
          ),
          key: `/${PATH_NAME.ADDRESS.index}/${PATH_NAME.ADDRESS.street}`,
          icon: (
            <img
              src={
                process.env.PUBLIC_URL + '/assets/image/icon/chevron-right.png'
              }
              alt={PATH_NAME.COMPANIES}
            />
          ),
        },
        {
          label: (
            <MenuItem
              to={`/${PATH_NAME.ADDRESS.buildings}`}
              collapsed={collapsed}
            />
          ),
          key: `/${PATH_NAME.ADDRESS.index}/${PATH_NAME.ADDRESS.buildings}`,
          icon: (
            <img
              src={
                process.env.PUBLIC_URL + '/assets/image/icon/chevron-right.png'
              }
              alt={PATH_NAME.COMPANIES}
            />
          ),
        },
      ],
    },
    {
      label: <MenuItem to={PATH_NAME.COMPANYCONFIRM} />,
      key: PATH_NAME.COMPANYCONFIRM,
      icon: (
        <img
          src={
            process.env.PUBLIC_URL + '/assets/image/icon/company-confirm.png'
          }
          alt={PATH_NAME.COMPANIES}
        />
      ),
    },
    // {
    //   label: <MenuItem to={PATH_NAME.TOPIC} />,
    //   key: PATH_NAME.TOPIC,
    //   icon: (
    //     <img
    //       src={process.env.PUBLIC_URL + '/assets/image/icon/News.png'}
    //       alt={PATH_NAME.COMPANIES}
    //     />
    //   ),
    // },
    {
      label: <MenuItem to={PATH_NAME.TAG} />,
      key: PATH_NAME.TAG,
      icon: (
        <img
          src={process.env.PUBLIC_URL + '/assets/image/icon/Tag.png'}
          alt={PATH_NAME.COMPANIES}
        />
      ),
    },
    {
      label: <MenuItem to={PATH_NAME.ADMANAGER} />,
      key: PATH_NAME.ADMANAGER,
      icon: (
        <img
          src={process.env.PUBLIC_URL + '/assets/image/icon/News.png'}
          alt={PATH_NAME.ADMANAGER}
        />
      ),
    },
    {
      label: <MenuItem to={PATH_NAME.SETTINGS} />,
      key: PATH_NAME.SETTINGS,
      icon: (
        <img
          src={process.env.PUBLIC_URL + '/assets/image/icon/gear.png'}
          alt={PATH_NAME.SETTINGS}
        />
      ),
    },
  ];

  let navigate = useNavigate();
  const handleClick = (e) => {
    navigate(e.key);
  };
  const toggleCollapsed = () => {
    setCollapsed((prev) => !prev);
  };
  return (
    <SiderStyles width={SIDEBAR_MAX_WIDTH} collapsed={collapsed}>
      <div className="header">
        {!collapsed && (
          <Link to="/" className="logo">
            <img src={Logo} alt="logo" />
          </Link>
        )}
        <div
          className="Boxicon"
          onClick={toggleCollapsed}
          style={{ position: collapsed ? 'unset' : 'absolute' }}
        >
          {collapsed ? <RightOutlined /> : <LeftOutlined />}
        </div>
      </div>
      <Menu
        selectedKeys={[location.pathname]}
        items={items}
        onClick={handleClick}
        mode="inline"
        className={`${collapsed ? 'zoomOut' : ''}`}
      ></Menu>
    </SiderStyles>
  );
}
