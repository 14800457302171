import { useIntl } from 'react-intl';
import { EditOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Form, Select } from 'antd';
import { useNavigate } from 'react-router-dom';

import { TitleWrapper, ContentWrapper } from 'components/layout';
import { SearchForm } from 'components/form';
import { serializeSearchParams } from 'utils/common';

import { useCustomSearchParams } from 'hooks/useCustomSearchParams';
import { EnableStatus } from 'components/common/status';
import { useCompanyConfirm } from './queries';
import { calcIndexByOrder } from 'utils/common';
import { isIncludeTextSearch } from 'utils/common';
import GetTime from 'utils/GetTime';
import { TableWrapper } from 'components';

const { Option } = Select;
const FeatureKeyWordStyles = styled.div`
  .action {
    display: flex;
    justify-content: center;
  }
  .action__icon {
    margin: 0px 5px;
  }
  .action:hover {
    opacity: 0.7;
    cursor: pointer;
  }
  .ant-table-wrapper {
  }
  .action__box {
    padding: 2px 1px;
    border-radius: 2px;
  }
  .action__edit {
    background: #fff;
    margin-right: 5px;
    border: 1px solid #333;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  }
  .action__delete {
    color: #fff;
    background: #ff4d4f;
    border-color: #ff4d4f;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  }
  .selectType {
    margin-left: 0px;
    padding: 4px 0px;
    min-width: 200px;
    background: #fff;
    border-radius: 3px;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border: none;
    box-shadow: none;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    outline: none;
  }
`;
function Featuredkeyword(props) {
  const intl = useIntl();
  const [search, setSearch] = useCustomSearchParams();
  let navigate = useNavigate();
  const argument = {
    params: search,
    options: {
      keepPreviousData: true,
    },
  };
  //
  const { data, isLoading } = useCompanyConfirm(argument);
  console.log('data', data);
  const columns = [
    {
      title: intl.formatMessage({ id: 'companyconfirm.index' }),
      dataIndex: 'index',
      align: 'center',
      render: (_, __, index) =>
        calcIndexByOrder({ index, page: search.page, limit: search.page_size }),
    },
    {
      title: intl.formatMessage({ id: 'companyconfirm.Username' }),
      dataIndex: 'Username',
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'companyconfirm.Companyname' }),
      dataIndex: 'Companyname',
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'common.status' }),
      dataIndex: 'confirm',
      render: (__, values) => <EnableStatus status={!!values.confirm} />,
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'companyconfirm.created_at' }),
      dataIndex: 'created_at',
      align: 'center',
      render: (_, value) => {
        const { year, month, day } = GetTime(value.created_at);
        return <p>{`${year}-${month}-${day}`}</p>;
      },
    },
    {
      title: intl.formatMessage({ id: 'common.send' }),
      dataIndex: 'send',
      render: (__, values) => <EnableStatus status={!!values.send} />,
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'companyconfirm.action' }),
      dataIndex: 'detail',
      align: 'center',
      render: (_, value) => (
        <div className="action" onClick={() => handleEdit(value.id)}>
          <div className="action__edit action__box">
            <EditOutlined className={'action__icon'} />
          </div>
        </div>
      ),
    },
  ];
  const handleEdit = (id) => {
    navigate(`/companyconfirm/edit/${id}`);
  };
  const handleChange = ({ current, pageSize }) =>
    setSearch({ ...search, page: current, page_size: pageSize });
  const handleSearch = (values) => {
    const params = serializeSearchParams({
      ...search,
      ...values,
    });

    setSearch(params);
  };
  const renderExtra = <></>;
  return (
    <>
      <FeatureKeyWordStyles>
        <TitleWrapper>
          {intl.formatMessage({ id: 'views.companyconfirm.title.list' })}
        </TitleWrapper>
        <ContentWrapper>
          {/* <SearchForm
            initialValues={{
              ...search,
            }}
            submit="Lọc"
            onFinish={handleSearch}
          >
            <Form.Item name="send">
              <Select
                className="selectType"
                showSearch
                placeholder="Lựa chọn công ty đã xem "
                optionFilterProp="children"
                filterOption={(input, option) =>
                  isIncludeTextSearch(input, option.children)
                }
              >
                <Option value={null}>Tất cả</Option>
                <Option value="1">Đã xem</Option>
                <Option value="0">Chưa xem</Option>
              </Select>
            </Form.Item>
            <Form.Item name="confirm">
              <Select
                className="selectType"
                showSearch
                placeholder="Lựa chọn công ty đã duyệt"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  isIncludeTextSearch(input, option.children)
                }
              >
                <Option value={null}>Tất cả</Option>
                <Option value="1">Đã duyệt</Option>
                <Option value="0">Từ chối</Option>
              </Select>
            </Form.Item>
          </SearchForm> */}
          <TableWrapper
            rowKey="id"
            columns={columns}
            dataSource={data?.data || []}
            loading={isLoading}
            pagination={{
              current: search.page,
              pageSize: search.page_size,
              total: data?.meta?.total ?? 0,
              position: ['bottomCenter'],
            }}
            onChange={handleChange}
            extra={renderExtra}
          />
        </ContentWrapper>
      </FeatureKeyWordStyles>
    </>
  );
}

export default Featuredkeyword;
