import { Button } from 'antd';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { PlusOutlined } from '@ant-design/icons';

const ButtonAddNewStyles = styled.div`
  .button {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: ${({ theme }) => theme.colors.primary};
    span {
      margin-left: 3px;
    }
    :hover {
      background: transparent;
    }
  }
`;
export default function ButtonAddNew({ to }) {
  const intl = useIntl();

  return (
    <ButtonAddNewStyles>
      <Link to={to}>
        <Button className="button" type="text">
          <PlusOutlined className="icon" />
          {intl.formatMessage({ id: 'btn.addNew' })}
        </Button>
      </Link>
    </ButtonAddNewStyles>
  );
}

export const ButtonNewOn = ({ onClick }) => {
  const intl = useIntl();
  return (
    <ButtonAddNewStyles>
      <Button className="button" type="text" onClick={onClick}>
        <PlusOutlined className="icon" />
        {intl.formatMessage({ id: 'btn.addNew' })}
      </Button>
    </ButtonAddNewStyles>
  );
};
