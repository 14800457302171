import styled from 'styled-components';

const ContentWrapperStyle = styled.div`
  margin-top: 24px;
  .delete__icon {
    cursor: pointer;
  }
`;

export const ContentWrapper = ({ children }) => {
  return <ContentWrapperStyle>{children}</ContentWrapperStyle>;
};
