import axiosClient from './axiosClient';

export const BuildingApi = {
  getAll({ params, street_id }) {
    return axiosClient.get(`/admin/buildings?page=${params.page}&page_size=${params.page_size}&street_id=${street_id}`);
  },
  delete(data) {
    return axiosClient.delete(`/admin/buildings`, { data: { ids: data } });
  },
  add(data) {
    return axiosClient.post(`/admin/buildings`, data);
  },
  edit(data, id) {
    return axiosClient.patch(`/admin/buildings/${id}`, data);
  },
  get(id) {
    return axiosClient.get(`/admin/buildings/${id}`);
  },
};
