import { Typography } from 'antd';
import { PreviewAdItemContainer } from './styles';

export default function NativeBannerItem({ name, content }) {
  return (
    <PreviewAdItemContainer style={{ marginTop: '10px' }}>
      <Typography.Title level={3}>
        {name || 'Tên của quảng cáo'}
      </Typography.Title>
      <span className="info">{content || 'Nội dung ngắn'}</span>

      <div className="ad-box">
        <Typography.Text style={{ color: '#D2A10A' }}>
          Quảng cáo
        </Typography.Text>
      </div>
    </PreviewAdItemContainer>
  );
}
