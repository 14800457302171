import { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Form,
  Radio,
  Space,
  Upload,
  message,
  Alert,
  Modal,
  Button,
} from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { Notification } from 'components';
import { IMPORT_FILE_TYPES, FILE_TYPE } from 'constants/file';
import { useResetFormModal } from 'hooks/useResetFormModal';
import { ImportIcon } from 'assets/icons';
import ButtonStyle from './ButtonStyle';
import ModalWrapper from './ModalWrapper';
import FormWrapper from './FormWrapper';

import ModalError from './layout/ModalError';
// export file error
import { CSVLink } from 'react-csv';

export default function Import({ request }) {
  const intl = useIntl();
  const [form] = Form.useForm();
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [modalError, setModalError] = useState(false);
  const [resultNotFound, setResultNotFound] = useState([]);
  const [loadingFileError, setLoadingFileError] = useState(false);

  useResetFormModal({
    form,
    visible: isVisible,
  });

  const handleSubmit = async (values) => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append('file', values.file.file);
      const result = await request(formData);
      setResultNotFound(result);
      // await request(formData);
      setIsLoading(false);
      handleCancel();
      if (result.address_not_found.length >= 1) {
        setModalError(true);
        Notification(
          'warning',
          intl.formatMessage({ id: 'message.uploadError' }),
          `${result.total_address_not_found} File Error`,
          10,
        );
      } else {
        setModalError(false);
        Notification(
          'success',
          intl.formatMessage({ id: 'message.uploadSuccess' }),
        );
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }

    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setIsVisible(false);
  };

  const handleOk = () => {
    setLoadingFileError(true);
    setTimeout(() => {
      setLoadingFileError(false);
      setModalError(false);
    }, 4000);
  };

  return (
    <div>
      <ButtonStyle
        icon={<ImportIcon />}
        type="edit"
        onClick={() => setIsVisible(true)}
      >
        {intl.formatMessage({ id: 'btn.import' })}
      </ButtonStyle>

      {/* Modal Error File */}
      {modalError && (
        <Modal
          title="File Records Error"
          centered
          open={modalError}
          onOk={() => setModalError(false)}
          onCancel={() => setModalError(false)}
          width={1000}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Exit
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={loadingFileError}
              icon={<DownloadOutlined />}
              onClick={handleOk}
            >
              <CSVLink data={resultNotFound?.address_not_found}>
                Export file error
              </CSVLink>
            </Button>,
          ]}
        >
          <ModalError data={resultNotFound} />
        </Modal>
      )}

      {/*
        IMPROVE: title with dynamic name
       */}
      <ModalWrapper
        visible={isVisible}
        title={intl.formatMessage({ id: 'import.title' })}
        className="import_export"
        onOk={() => {
          form.validateFields().then(handleSubmit);
        }}
        okText={intl.formatMessage({ id: 'btn.import' })}
        onCancel={handleCancel}
        confirmLoading={isLoading}
      >
        <FormWrapper form={form} layout="vertical">
          <Form.Item
            label={intl.formatMessage({ id: 'import.type' })}
            name="type"
            rules={[{ required: true }]}
          >
            <Radio.Group>
              <Space direction="vertical">
                {IMPORT_FILE_TYPES.map((type) => (
                  <Radio key={type} value={type}>
                    {intl.formatMessage({ id: 'import.via' }, { type })}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label={intl.formatMessage({ id: 'import.file' })}
            name="file"
            rules={[{ required: true }]}
            valuePropName="file"
          >
            <Upload
              multiple={false}
              accept=".csv, .xls, .xlsx"
              showUploadList={false}
              beforeUpload={(file) => {
                console.log('file', file);
                const isAccepted = [
                  FILE_TYPE.CSV,
                  FILE_TYPE.XLS,
                  FILE_TYPE.XLSX,
                ].includes(file.type);

                if (!isAccepted) {
                  message.error(`${file.name} is not valid format file`);
                }

                return isAccepted ? false : Upload.LIST_IGNORE;
              }}
            >
              <ButtonStyle>
                {intl.formatMessage({ id: 'btn.upload' })}
              </ButtonStyle>
            </Upload>
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.file !== currentValues.file
            }
          >
            {({ getFieldValue }) => {
              return getFieldValue('file') ? (
                <Alert message={getFieldValue('file').file.name} />
              ) : null;
            }}
          </Form.Item>
        </FormWrapper>
      </ModalWrapper>
    </div>
  );
}
