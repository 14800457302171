import React, { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Button, Form, Input } from 'antd';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';

import { TitleWrapper } from 'components/layout';
import { Notification } from 'components';
import { AddressApi } from 'api/AddressApi';
import WrapperCenter from 'components/common/button/wrapperCenter';

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 0,
    span: 24,
  },
};
const ProvinceAddStyles = styled.div`
  .form {
    margin-top: 10px;
  }
`;
function AddFeatureKeyWords(props) {
  const intl = useIntl();
  let navigate = useNavigate();
  const formElemt = useRef();
  const province = useRef();
  const { id } = useParams();
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    let data = id
      ? await AddressApi.EditProvince(values, id)
      : await AddressApi.AddProvince(values);
    if (data.error) {
      Notification('error', data.error.message);
    } else {
      navigate('/address/province');
    }
  };

  async function fetchProvice(id) {
    const { data } = await AddressApi.GetProvince(id);
    formElemt.current.setFieldsValue({
      ...data,
    });
  }
  useEffect(() => {
    if (id) {
      fetchProvice(id);
    }
    province.current.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return (
    <ProvinceAddStyles>
      <TitleWrapper>
        {intl.formatMessage({
          id: id
            ? 'views.address.title.province.edit'
            : 'views.address.title.province.add',
        })}
      </TitleWrapper>
      <Form
        ref={formElemt}
        {...layout}
        form={form}
        name="control-hooks"
        onFinish={onFinish}
        className="form"
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: 'không để trường này trống',
            },
          ]}
        >
          <Input ref={province} />
        </Form.Item>
        <Form.Item name="code" label="Code">
          <Input />
        </Form.Item>
        <Form.Item name="keyword" label="Nhập từ khóa">
          <Input />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <WrapperCenter>
            <Button type="primary" htmlType="submit">
              {id ? 'Sửa Tỉnh' : 'Thêm Tỉnh'}
            </Button>
          </WrapperCenter>
        </Form.Item>
      </Form>
    </ProvinceAddStyles>
  );
}

export default AddFeatureKeyWords;
