import styled from 'styled-components';

const CmsContentStyles = styled.div`
  padding: 16px;

  .title {
    text-align: center;
    position: relative;
    padding-bottom: 13px;
    border-bottom: 1px solid var(--grayDark);
    text-transform: uppercase;
    margin-bottom: 24px;

    h3 {
      font-size: 18px;
      font-weight: 600;
      line-height: 27px;
    }
  }
`;

export default function CmsContent({ title, children }) {
  return (
    <CmsContentStyles>
      {title && (
        <div className="title">
          <h3>{title}</h3>
        </div>
      )}

      {children}
    </CmsContentStyles>
  );
}
