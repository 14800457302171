import styled from 'styled-components';
import { LeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
// import { LanguageIcon } from 'assets/icons';
// import NotificationTitle from 'components/NotificationTitle';
import Menu from 'components/Menu';

const TitleWrapperStyles = styled.div`
  display: flex;
  justify-content: space-between;
  background: #b5f5ec;
  margin-top: 79px;
  padding: 19px 10px 19px 16px;
  border-radius: 8px;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-transform: uppercase;
  .title {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    .icon {
      margin-right: 20px;
      font-size: 30px;
    }
  }
  .header {
    display: flex;
  }
  .boxIcon {
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #e6ebf0;
    border-radius: 50%;
    margin-right: 4px;
    margin-left: 10px;
  }
  .language {
    display: flex;
    align-items: center;
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      padding-right: 10px;
      border-right: 1px solid #cacbce;
    }
  }
  .languageIcon {
    color: transparent;
    path {
      stroke: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const TitleWrapper = ({ children }) => {
  let navigate = useNavigate();
  return (
    <TitleWrapperStyles>
      <div className="title">
        <LeftOutlined className="icon" onClick={() => navigate(-1)} />
        {children}
      </div>
      <div className="header">
        {/* <div className="language">
          <div className="boxIcon">
            <LanguageIcon className={'languageIcon'} />
          </div>
          <p>VI</p>
        </div> */}
        {/* <NotificationTitle /> */}
        <Menu />
      </div>
    </TitleWrapperStyles>
  );
};
