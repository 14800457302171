import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Form, Input } from 'antd';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { Editor } from 'react-draft-wysiwyg';
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from 'draft-js';


import { toolbarOptions } from 'config/draftConfig';
import { TitleWrapper } from 'components/layout';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { footerApi } from 'api/footerApi';
import HtmlToDraftfc from '../../../utils/HtmlToDraftfc';
import WrapperCenter from 'components/common/button/wrapperCenter';

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 0,
    span: 24,
  },
};
const FeatureAddStyles = styled.div`
  .form {
    margin-top: 10px;
  }
  .editor {
    padding: 10px;
  }
  .demo-editor {
    ${'' /* border: 1px solid #000; */}
    padding: 10px;
    border-radius: 4px;
    background-color: #fff;
    min-height: 200px;
  }
`;

function AddFooter(props) {
  const intl = useIntl();
  let navigate = useNavigate();
  const formElemt = useRef();
  const title = useRef();
  const name = useRef();
  const content = useRef();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [detailData, setDetailData] = useState(null);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const onEditorStateChange = (newState) => setEditorState(newState);

  const onFinish = async (values) => {
    values.content = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    let data = id
      ? await footerApi.edit(values, id)
      : await footerApi.add(values);

    if (data.error) {
      console.log('message', data.error);
    } else {
      navigate('/footer');
    }
  };
  async function fetchData(id) {
    const { data } = await footerApi.get(id);
    setDetailData(data);
    formElemt.current.setFieldsValue({
      name: data.name,
      title: data.title,
      content: data.content,
    });
    const blocksFromHTML = convertFromHTML(data.content);
    const editorContent = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    );
    setEditorState(EditorState.createWithContent(editorContent));
  }

  useEffect(() => {
    async function fetchData(id) {
      const { data } = await footerApi.get(id);
      setDetailData(data[0]);
      formElemt.current.setFieldsValue({
        name: data.name,
        title: data.title,
        content: data.content,
      });
      const blocksFromHTML = convertFromHTML(data.content);
      const editorContent = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
      );
      console.log('data-detail: ', HtmlToDraftfc(data.content));
      setEditorState(HtmlToDraftfc(data.content));
    }
    if (id) {
      fetchData(id);
    }
    title.current.focus();
  }, []);
  return (
    <FeatureAddStyles>
      <TitleWrapper>
        {intl.formatMessage({
          id: id ? 'views.about_us.title.edit' : 'views.about_us.title.add',
        })}
      </TitleWrapper>
      <Form
        ref={formElemt}
        {...layout}
        form={form}
        name="control-hooks"
        onFinish={onFinish}
        className="form"
      >
        <Form.Item
          name="name"
          label="Name: "
          rules={[
            {
              required: true,
              message: 'không để trường này trống',
            },
          ]}
        >
          <Input ref={name} />
        </Form.Item>
        <Form.Item
          name="title"
          label="Title: "
          rules={[
            {
              required: true,
              message: 'không để trường này trống',
            },
          ]}
        >
          <Input ref={title} />
        </Form.Item>
        <Form.Item name="content" label="Content: ">
          <Editor
            ref={content}
            editorState={editorState}
            toolbar={{
              ...toolbarOptions,
              embedded: {
                embedCallback: (link) => {
                  // not work ??
                  if (
                    link.indexOf('youtube') >= 0 ||
                    link.indexOf('youtu.be/') >= 0
                  ) {
                    link = link.replace('watch?v=', 'embed/');
                    link = link.replace('/watch/', '/embed/');
                    link = link.replace('youtu.be/', 'youtube.com/embed/');
                  }
                  return link;
                },
              },
            }}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={onEditorStateChange}
          />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <WrapperCenter>
          <Button
            style={{ padding: '0 !important' }}
            type="primary"
            htmlType="submit"
          >
            {id ? 'Sửa' : 'Thêm   '}
          </Button>
          </WrapperCenter>
        </Form.Item>
      </Form>
    </FeatureAddStyles>
  );
}
export default AddFooter;
