import { useEffect } from 'react';
import { useQuery, useQueryClient, useMutation } from 'react-query';

import newsmanagementApi from 'api/newsmanagementApi';

export const NewsmanagementKey = {
  all: ['featuredkeyword'],
  lists: () => [...NewsmanagementKey.all, 'list'],
  list: (filter) => [...NewsmanagementKey.lists(), filter],
  details: () => [...NewsmanagementKey.all, 'detail'],
  detail: (id) => [...NewsmanagementKey.details(), id],
};

export const useNews = ({ params, options }) => {

  return useQuery({
    queryKey: NewsmanagementKey.list(params),
    queryFn: () => newsmanagementApi.getAll(params),
    ...options,
  });
};
const deleteNews = (id) => {
  return newsmanagementApi.delete(id);
}
export const useDeletenews = ({ params, options }) => {
  const queryClient = useQueryClient();
  return useMutation(deleteNews, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: NewsmanagementKey.list(params),
        queryFn: () => newsmanagementApi.getAll(params),
        ...options,
      })
    }
  });
}
const enableNews = (id) => {
  return newsmanagementApi.enable(id);
}
export const useEnablenews = ({ params, options }) => {
  const queryClient = useQueryClient();
  return useMutation(enableNews, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: NewsmanagementKey.list(params),
        queryFn: () => newsmanagementApi.getAll(params),
        ...options,
      })
    }
  });
}
export const usePrefetchCompanies = (data, queryParams) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    if (data?.link?.next) {
      const newQueryParams = { ...queryParams, page: queryParams.page + 1 };

      queryClient.prefetchQuery(NewsmanagementKey.list(newQueryParams), () =>
        newsmanagementApi.getAll(newQueryParams),
      );
    }
  }, [data, queryParams, queryClient]);
};
