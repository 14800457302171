import { useState } from 'react';
import { useIntl } from 'react-intl';
import { EditOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { TitleWrapper, ContentWrapper } from 'components/layout';

import { useCustomSearchParams } from 'hooks/useCustomSearchParams';
import { useBuilding, useDeleteBuilding } from './queries';
import { ButtonAddNew } from 'components/common/button';
import FilterBuilding from './FilterBuilding';
import { TableWrapper } from 'components';
import ButtonOther from 'components/common/button/ButtonOther';

import { calcIndexByOrder } from 'utils/common';

const BuildingStyles = styled.div`
  .action {
    margin: 0px 5px;
  }
  .action:hover {
    opacity: 0.7;
    cursor: pointer;
  }
  .ant-table-wrapper {
  }
`;

function BuildingManagement() {
  const intl = useIntl();
  const [search, setSearch] = useCustomSearchParams();
  const [objectAddress, setObjectAddress] = useState({
    provinceId: 10,
    districtId: 145,
    streetId: 19137,
  });
  // table
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loadingReload, setLoadingReload] = useState(false);
  let navigate = useNavigate();
  const argument = {
    params: search,
    street_id: objectAddress.streetId,
    options: {
      keepPreviousData: true,
    },
  };
  const { data, isLoading } = useBuilding(argument);
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const { mutate } = useDeleteBuilding(argument);

  const hasSelected = selectedRowKeys.length > 0;
  const deleteSelected = selectedRowKeys.length > 0;
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleEdit = (id, province_id, district_id, street_id) => {
    navigate(
      `/address/edit/building/${id}?province_id=${province_id}&district_id=${district_id}&street_id=${street_id}`,
    );
  };

  const columns = [
    {
      title: intl.formatMessage({ id: 'common.index' }),
      render: (_, __, index) =>
        calcIndexByOrder({ index, page: search.page, limit: search.page_size }),
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'building.name' }),
      dataIndex: 'name',
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'building.action' }),
      dataIndex: 'action',
      align: 'center',
      render: (_, values) => (
        <>
          {console.log('values', values)}
          <EditOutlined
            className={'action'}
            onClick={() =>
              handleEdit(
                values.id,
                values.province.id,
                values.district.id,
                values.street.id,
              )
            }
          />
        </>
      ),
    },
  ];

  const handleReload = () => {
    setLoadingReload(true); // ajax request after empty completing
    setTimeout(() => {
      setLoadingReload(false);
    }, 1000);
    setSelectedRowKeys([]);
  };

  const handleDelete = () => {
    mutate(selectedRowKeys);
  };
  const handleChange = ({ current, pageSize }) =>
    setSearch({ ...search, page: current, page_size: pageSize });

  const renderExtra = (
    <>
      <ButtonAddNew
        to={`/address/create/building?province_id=${objectAddress.provinceId}&district_id=${objectAddress.districtId}&street_id=${objectAddress.streetId}`}
      />
      <ButtonOther
        type="default"
        onClick={handleDelete}
        disabled={!deleteSelected}
      >
        Xóa
      </ButtonOther>
      <ButtonOther
        type="default"
        onClick={handleReload}
        disabled={!hasSelected}
        loading={loadingReload}
      >
        Làm mới
      </ButtonOther>
    </>
  );

  return (
    <>
      <BuildingStyles>
        <TitleWrapper>
          {intl.formatMessage({ id: 'views.building.title.list' })}
        </TitleWrapper>
        <ContentWrapper>
          <FilterBuilding
            objectAddress={objectAddress}
            setObjectAddress={setObjectAddress}
          />
          <TableWrapper
            // styled={{textAlign: 'center'}}
            rowKey="id"
            columns={columns}
            dataSource={data?.data || []}
            loading={isLoading}
            rowSelection={rowSelection}
            pagination={{
              position: ['bottomCenter'],
              current: search.page,
              pageSize: search.page_size,
              total: data?.meta?.total ?? 0,
            }}
            onChange={handleChange}
            extra={renderExtra}
          />
        </ContentWrapper>
      </BuildingStyles>
    </>
  );
}

export default BuildingManagement;
