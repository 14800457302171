import { useIntl } from 'react-intl';
import { Avatar, Button, Checkbox, Form, Input, Typography, notification } from 'antd';

import { TitleWrapper, ContentWrapper } from 'components/layout';
import { TableWrapper } from 'components/common/table';
import { useCustomSearchParams } from 'hooks/useCustomSearchParams';
import { formatDate } from 'utils/format';
import { EnableStatus } from 'components/common/status';
import { Gender } from 'components/common/Gender';
import { useDeleteAd, useAds } from './queries';
import { serializeSearchParams, calcIndexByOrder } from 'utils/common';
import { SearchForm } from 'components/form';
import { DEFAULT_PAGINATION } from 'constants/common';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import ButtonOther from 'components/common/button/ButtonOther';
import CreateAdModel from './modals/CreateAdModel';
import { adApi } from 'api/adApi';

const { Text } = Typography;

export default function AdManagers() {
  const intl = useIntl();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loadingReload, setLoadingReload] = useState(false);
  const [loadingDelete, setLoadingDetele] = useState(false);
  const [search, setSearch] = useCustomSearchParams();
  const [onlyShowNotApproved, setOnlyShowNotApproved] = useState(false);
  let navigate = useNavigate();
  const argument = {
    params: search,
    options: {
      keepPreviousData: true,
    },
  };

  const { data, isPreviousData, isFetching } = useAds(argument);

  const { mutate } = useDeleteAd(argument);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const hasSelected = selectedRowKeys.length > 0;
  const deleteSelected =
    selectedRowKeys.length > 0 && selectedRowKeys.length < 2;
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };


  const [loading, setLoading] = useState(false);
  const toggleApproved = async (e, adId) => {
    try {
      setLoading(true);
      // const nextValue = isApproved === 0 ? 1 : 0;
      const data = await adApi.approveAds(
        adId,
      );
    } catch (e) {
      // console.log("ERROR")
      notification.error({ message: `Lỗi cập nhật quảng cáo: ${e.message}` });
    } finally {
      setLoading(false);
      e.preventDefault();
      window.location.reload();
    }
  };

  const columns = [
    {
      title: intl.formatMessage({ id: 'common.index' }),
      render: (_, __, index) =>
        calcIndexByOrder({ index, page: search.page, limit: search.page_size }),
      align: 'center',
      fixed: "left",
      width: 100,
    },
    {
      title: intl.formatMessage({ id: 'views.ad_manager.ad_infomation' }),
      width: "100%",
      children: [
        {
          title: intl.formatMessage({ id: 'views.ad_manager.title' }),
          dataIndex: 'name',
          align: 'center',
          width: 300,
        },
        {
          title: intl.formatMessage({ id: 'views.ad_manager.content' }),
          dataIndex: 'content',
          align: 'center',
          width: 300,
        },
        {
          title: intl.formatMessage({ id: 'views.ad_manager.user' }),
          dataIndex: 'user',
          align: 'center',
          width: 130,
          render: (value) => <p>{value?.name || ''}</p>,
        },
        {
          title: intl.formatMessage({ id: 'views.ad_manager.more_infomation' }),
          children: [
            {
              title: intl.formatMessage({ id: 'views.ad_manager.website' }),
              dataIndex: 'website',
              align: 'center',
              width: 150,
              render: (value) => <p>{value || ''}</p>,
            },
            {
              title: intl.formatMessage({ id: 'views.ad_manager.email' }),
              dataIndex: 'email',
              align: 'center',
              width: 150,
              render: (value) => <p>{value || ''}</p>,
            },
            {
              title: intl.formatMessage({ id: 'views.ad_manager.phoneNumber' }),
              dataIndex: 'phoneNumber',
              align: 'center',
              width: 150,
              render: (value) => <p>{value || ''}</p>,
            },
          ]
        },
      ]
    },
    {
      title: intl.formatMessage({ id: 'about_us.action' }),
      children: [
        {
          title: intl.formatMessage({ id: 'views.ad_manager.approveStatus' }),
          dataIndex: 'isApproved',
          render: (_, value) => (
            <>
              {
                value.isApproved == 1 ? (
                  <span>{intl.formatMessage({ id: 'views.ad_manager.approved' })}</span>
                ) : (
                  <span>{intl.formatMessage({ id: 'views.ad_manager.not_approved' })}</span>
                )
              }
            </>
          ),
          align: 'center',
          width: 90,
          fixed: 'right',
        },
        {
          title: intl.formatMessage({ id: 'about_us.action' }),
          dataIndex: 'isApproved',
          render: (_, value) => (
            <>
              {
                value.isApproved == 1 ? (
                  <>
                    <Button
                      type="danger"
                      size="small"
                      onClick={(e) => toggleApproved(e, value?.id)}
                      // loading={loading}
                      // disabled={loading}
                    >
                      {intl.formatMessage({ id: 'views.ad_manager.cancel_approve' })}
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      type="primary"
                      size="small"
                      onClick={(e) => toggleApproved(e, value?.id)}
                      loading={loading}
                      disabled={loading}
                    >
                      {intl.formatMessage({ id: 'views.ad_manager.approved_ads' })}
                    </Button>
                  </>
                )
              }
            </>
          ),
          align: 'center',
          width: 90,
          fixed: 'right',
        },
        {
          title: intl.formatMessage({ id: 'about_us.action' }),
          dataIndex: 'action',
          align: 'center',
          width: 100,
          fixed: 'right',
          render: (_, value) => (
            <>
              <EditOutlined
                className={'action'}
                onClick={() => handleEdit(value.id)}
              />
            </>
          ),
        },
      ]
    }
  ];

  const handleReload = () => {
    setLoadingReload(true); // ajax request after empty completing
    setTimeout(() => {
      setLoadingReload(false);
    }, 1000);
    setSelectedRowKeys([]);
  };

  const handleEdit = (id) => {
    navigate(`/ad-manager/edit/${id}`);
  };
  const handleDelete = () => {
    setLoadingDetele(true); // ajax request after empty completing
    setTimeout(() => {
      setLoadingDetele(false);
      mutate(selectedRowKeys[0]);
      setSelectedRowKeys([]);
    }, 1000);
    setSelectedRowKeys([]);
  };

  const renderExtra = (
    <>
      <ButtonOther
        type="default"
        onClick={handleDelete}
        disabled={!deleteSelected}
        loading={loadingDelete}
      >
        Xóa
      </ButtonOther>
      <ButtonOther
        type="default"
        onClick={handleReload}
        disabled={!hasSelected}
        loading={loadingReload}
      >
        Làm mới
      </ButtonOther>
    </>
  );
  const handleChange = ({ current, pageSize }) => {
    setSearch({ ...search, page: current, page_size: pageSize });
  };

  const handleSearch = (values) => {
    const params = serializeSearchParams({
      ...search,
      ...values,
      page: DEFAULT_PAGINATION.PAGE,
    });

    setSearch(params);
  };

  return (
    <>
      <TitleWrapper>
        {intl.formatMessage({ id: 'views.ad_manager.list_title' })}
      </TitleWrapper>

      <ContentWrapper>
        <SearchForm
          initialValues={{ search: search.search }}
          onFinish={handleSearch}
        >
          <Form.Item name="search">
            <Input
              style={{ minWidth: '300px' }}
              placeholder={'Nhập vào tiêu đề'}
            />
          </Form.Item>
        </SearchForm>

        <Form.Item name="is_approved">
          <Checkbox
            checked={onlyShowNotApproved}
            onChange={(e) => {
              setOnlyShowNotApproved(e.target.checked);
              setSearch((prev) => {
                const { is_approved, ...rest } = prev;
                if (e.target.checked) {
                  return {
                    ...rest,
                    show_not_approved: 1,
                  };
                } else {
                  return {
                    ...rest,
                  };
                }
              });
            }}
          />{' '}
          <Text>Chỉ hiện chưa duyệt</Text>
        </Form.Item>

        <TableWrapper
          rowKey="id"
          columns={columns}
          dataSource={data?.data || []}
          loading={isPreviousData && isFetching}
          rowSelection={rowSelection}
          pagination={{
            position: ['bottomCenter'],
            current: search.page,
            pageSize: search.page_size,
            total: data?.meta?.total || 0,
          }}
          onChange={handleChange}
          extra={renderExtra}
          scroll={{
            x: 'calc(1500px + 50%)',
          }}
        />
      </ContentWrapper>

      {/* {createModalVisible && (
        <CreateAdModel
          // isOpen={isModalOpen}
          onClickOk={() => {}}
          onClickCancel={() => setCreateModalVisible(false)}
          // loading={}
          // error={error}
          // for Edit
          // editAdData={editAdData}
        />
      )} */}
    </>
  );
}
