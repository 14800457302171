import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Form, Input, Switch } from 'antd';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';

import { TitleWrapper } from 'components/layout';
import AddUpload from './Addupload';
import { blogApi } from 'api/blogApi';

const { TextArea } = Input;
const layout = {
  labelCol: {
    span: 0,
  },
  wrapperCol: {
    span: 22,
  },
};
const FeatureAddStyles = styled.div`
  .form {
    margin-top: 10px;
  }
`;
function AddBlog(props) {
  const intl = useIntl();
  const [Object, setObject] = useState(false);
  let navigate = useNavigate();
  const formElemt = useRef();
  const { id } = useParams();
  const [form] = Form.useForm();
  const onFinish = async (values) => {
    let data = id ? await blogApi.edit(values, id) : await blogApi.add(values);
    if (data.error) {
      console.log('message', data.error);
    } else {
      navigate('/featuredkeyword');
    }
  };
  async function fetchKeyWord(id) {
    const { data } = await blogApi.get(id);
    setObject(data);
    data.user = data.user_info.name || data.user_info.email;
    formElemt.current.setFieldsValue(data);
  }
  useEffect(() => {
    if (id) {
      fetchKeyWord(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <FeatureAddStyles>
      <TitleWrapper>
        {intl.formatMessage({
          id: id ? 'views.blogs.list.edit' : 'views.blogs.list.add',
        })}
      </TitleWrapper>
      <Form
        ref={formElemt}
        {...layout}
        form={form}
        name="control-hooks"
        onFinish={onFinish}
        className="form"
      >
        <Form.Item
          name="content"
          label="content"
          rules={[
            {
              required: true,
              message: 'không để trường này trống',
            },
          ]}
        >
          <TextArea disabled rows={4} />
        </Form.Item>
        <Form.Item
          name="user"
          label="user"
          rules={[
            {
              required: true,
              message: 'không để trường này trống',
            },
          ]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="image"
          label="image"
          rules={[
            {
              required: true,
              message: 'không để trường này trống',
            },
          ]}
        >
          <AddUpload url="https://webaffiliatevn.com/wp-content/uploads/2020/08/IMGLOGO_Primary_CMYK_Blue_Rel_webready.jpg" />
        </Form.Item>
        <Form.Item name="enable" label="Hiện tức tin">
          <Switch disabled checked={Object.enable} />
        </Form.Item>
      </Form>
    </FeatureAddStyles>
  );
}

export default AddBlog;
