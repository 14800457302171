import styled from 'styled-components';
import { Input } from 'antd';

const InputFieldElement = styled(Input)`
  &.ant-input {
    font-size: ${({ theme }) => theme.fontSize.default};
    color: ${({ theme }) => theme.colors.primary};
    border-color: ${({ theme }) => theme.colors.primary};
    border-radius: ${({ theme }) => theme.borderRadius.default};
    padding: 7px 11px;
  }
`;

const InputPasswordElement = styled(Input.Password)`
  &.ant-input-password {
    border-color: ${({ theme }) => theme.colors.primary};
    border-radius: ${({ theme }) => theme.borderRadius.default};
    padding: 7px 11px;

    .ant-input {
      font-size: ${({ theme }) => theme.fontSize.default};
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const InputPasswordField = (props) => {
  return <InputPasswordElement {...props} />;
};

export const InputField = (props) => {
  return <InputFieldElement {...props} />;
};

InputField.Password = InputPasswordField;
