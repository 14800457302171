import styled from 'styled-components';
import { Row, Col } from 'antd';

const LeagueItemStyles = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  > .name {
    color: var(--white);
    background-color: var(--naviGreen);
    text-align: center;
    font-weight: bold;
    padding: 9px;
  }

  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding: 3px 0;
  }

  .heading {
    .ant-row {
      background-color: #fafafa;

      > .ant-col {
        &:nth-child(even) {
          background-color: #63a0af10;
        }
      }
    }
  }
`;

export default function LeagueItem({ name, children }) {
  return (
    <LeagueItemStyles>
      <div className="name">{name}</div>
      <div className="heading">
        <Row>
          <Col span={6}>
            <div className="box" style={{ justifyContent: 'flex-end' }}>
              <p className="title">Limits</p>
            </div>
          </Col>
          <Col span={4}>
            <div className="box">
              <p className="title">Spread</p>
              <p className="num">$500</p>
            </div>
          </Col>
          <Col span={4}>
            <div className="box">
              <p className="title">MoneyLine</p>
              <p className="num">$500</p>
            </div>
          </Col>
          <Col span={4}>
            <div className="box">
              <p className="title">Total</p>
              <p className="num">$500</p>
            </div>
          </Col>
          <Col span={6}>
            <div className="box">
              <p className="title">Team Total</p>
              <p className="num">$500</p>
            </div>
          </Col>
        </Row>
      </div>

      {children}
    </LeagueItemStyles>
  );
}
