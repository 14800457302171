import styled from 'styled-components';

const CmsTitleStyles = styled.div`
  background: var(--naviGreen);
  padding: 35px 105px;

  h2 {
    font-size: 26px;
    font-weight: 500;
    line-height: 1;
    color: var(--white);
    text-transform: uppercase;
  }
`;

export default function CmsTitle({ children }) {
  return (
    <CmsTitleStyles>
      <h2>{children}</h2>
    </CmsTitleStyles>
  );
}
