import styled from 'styled-components';

const BoxWrapperStyles = styled.div`
  background-color: var(--grayWhite);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 16px;

  & + & {
    margin-top: 20px;
  }
`;

export default function BoxWrapper({ children }) {
  return <BoxWrapperStyles>{children}</BoxWrapperStyles>;
}
