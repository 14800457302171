import React from 'react';
import { Dropdown, Menu, Typography } from 'antd';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { LogoutOutlined } from '@ant-design/icons';

import { doLogout, getCurrentUser } from 'store/slices/authSlice';
import { UserIcon, DownOutIcon } from '../assets/icons/index';
const BtnStyle = styled.div`
  .info {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    .anticon {
      cursor: pointer;
      margin-left: 12px;
    }
  }
`;

const BtnLoguot = () => {
  return (
    <BtnStyle className="info">
      <LogoutOutlined
        style={{ fontSize: 15, marginRight: 10 }}
      />
      <Typography.Text>Đăng xuất</Typography.Text>
    </BtnStyle>
  );
};

const MenuProps = () => {
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(doLogout());
  };
  const handleClick = (value) => {
    switch(value.key) {
      case "BtnLoguot":
        handleLogout();
      break;
    default:
      return;
    }
  }
  return (
    <Menu
     onClick={handleClick}
      items={[
        {
          key: 'BtnLoguot',
          label: <BtnLoguot />,
        },
      ]}
    />
  );
};

const MenuStyle = styled.div`
  .Menu {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .name {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      .nameIcon {
        margin-left: 8px;
        margin-right: 10px;
        color: #8a8d93;
      }
    }
    .boxAvatar {
      padding: 8px;
      background: ${({ theme }) => theme.colors.primary};
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
      margin-right: 4px;
    }
    .icon,
    .nameIcon {
      font-size: 20px;
      color: white;
      path {
        fill: transparent;
      }
    }
  }
`;
function MenuHeader() {
  const currentUser = useSelector(getCurrentUser);
  return (
    <MenuStyle>
      <Dropdown overlay={<MenuProps />} placement="bottomLeft">
        <div className="Menu">
          <div className="boxAvatar">
            <UserIcon className="icon" />
          </div>
          <p className="name">
            {currentUser.name} <DownOutIcon className="nameIcon" />
          </p>
        </div>
      </Dropdown>
    </MenuStyle>
  );
}

export default MenuHeader;
