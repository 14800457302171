import axiosClient from './axiosClient';

export const categoryApi = {
  getAll() {
    return axiosClient.get('/admin/categories');
  },
  delete(id) {
    return axiosClient.delete(`/admin/categories/${id}/`);
  },
  add(data) {
    return axiosClient.post(`/admin/categories`, data);
  },
  edit(data, id) {
    return axiosClient.patch(`/admin/categories/${id}`, data);
  },
  get(id) {
    return axiosClient.get(`/admin/categories/${id}`);
  }
};
