import { useIntl } from 'react-intl';
import { Form, Input, Select } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';
import ButtonOther from 'components/common/button/ButtonOther';

import { TitleWrapper, ContentWrapper } from 'components/layout';

import { DEFAULT_PAGINATION } from 'constants/common';
import { useCustomSearchParams } from 'hooks/useCustomSearchParams';
import { TableWrapper, Import } from 'components';

import { SearchForm } from 'components/form';
import { serializeSearchParams } from 'utils/common';
import { companyApi } from 'api/companyApi';
import { ButtonAddNew } from 'components/common/button';
import { PATH_NAME, PATH_NAME_ACTION } from 'constants/routes';
import useColumns from './columns/useColumns';
import { isIncludeTextSearch } from 'utils/common';

import {
  useCompanies,
  useDeleteCompanies,
  useDeletesCompany,
  useEnableCompanies,
} from './queries';
import { useCompanytype } from '../companyTypes/queries';
import { FilterLocationModal } from './search';
import SelectUser from './select/selectUser';

const { Option } = Select;

const CompaniesStyles = styled.div`
  .action {
    margin-left: 5px;
  }
  .action:hover {
    opacity: 0.7;
    cursor: pointer;
  }
  .selectType {
    margin-left: 10px;
    padding: 4px 0px;
    background: #fff;
    border-radius: 3px;
    min-width: 150px;
    width: auto;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border: none;
    box-shadow: none;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    outline: none;
  }
`;
export default function Companies() {
  const intl = useIntl();
  const [search, setSearch] = useCustomSearchParams();

  const argument = {
    params: search,
    options: {
      keepPreviousData: true,
    },
  };
  const { data, isLoadingCompanies, isFetchedCompanies } = useCompanies({
    params: search,
  });
  //  table
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loadingReload, setLoadingReload] = useState(false);
  const [loadingDelete, setLoadingDetele] = useState(false);
  const [locationData, setLocationData] = useState({
    province: null,
    district: null,
    ward: null,
    street: null,
  });
  const {
    data: Companytype,
    isLoading,
    isFetched,
  } = useCompanytype({
    options: {
      keepPreviousData: true,
    },
  });
  const { mutate } = useDeletesCompany({
    argument,
  });

  const { mutate: MutateEnable } = useEnableCompanies(argument);
  const hasSelected = selectedRowKeys.length > 0;
  const deleteSelected = selectedRowKeys.length > 0;

  const handleReload = () => {
    setLoadingReload(true); // ajax request after empty completing
    setTimeout(() => {
      setLoadingReload(false);
    }, 1000);
    setSelectedRowKeys([]);
  };
  const handleDelete = () => {
    setLoadingDetele(true); // ajax request after empty completing
    setTimeout(() => {
      setLoadingDetele(false);
      mutate(selectedRowKeys);
      setSelectedRowKeys([]);
    }, 1000);
    setSelectedRowKeys([]);
  };
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const handleChange = ({ current, pageSize }) =>
    setSearch({ ...search, page: current, page_size: pageSize });
  console.log('data size', search);
  const handleSearch = (values) => {
    if (values.type_id === 'all') {
      values.type_id = null;
    }
    if (values.user_created_id === 'all') {
      values.user_created_id = null;
    }
    const params = serializeSearchParams({
      ...search,
      ...values,
      province_id: locationData.province?.id,
      district_id: locationData.district?.id,
      ward_id: locationData.ward?.id,
      street_id: locationData.street?.id,
      page: DEFAULT_PAGINATION.PAGE,
    });

    setSearch(params);
    console.log(values.type_id);
  };
  // MutateEnable
  const onChange = (item) => {
    //  đây chỉ dùng set cứng nếu liên quan đến status thì sửa lại !
    const categoriesArr = item.categories.reduce((inilValue, item) => {
      return [...inilValue, item.category_id];
    }, []);
    item.categories = categoriesArr;
    item.status = 1;
    item.enable = item.enable === 1 ? 0 : 1;
    delete item.created_by;
    MutateEnable(item);
  };
  const { columns } = useColumns({ search, onChange });
  const renderExtra = (
    <>
      <ButtonOther
        type="default"
        onClick={handleDelete}
        disabled={!deleteSelected}
        loading={loadingDelete}
      >
        Xóa
      </ButtonOther>
      <ButtonOther
        type="default"
        onClick={handleReload}
        disabled={!hasSelected}
        loading={loadingReload}
      >
        Làm mới
      </ButtonOther>
      <Import request={companyApi.import} />
      <ButtonAddNew to={`${PATH_NAME.COMPANIES}/${PATH_NAME_ACTION.CREATE}`} />
    </>
  );
  return (
    <CompaniesStyles>
      <TitleWrapper>
        {intl.formatMessage({ id: 'views.companies.title.list' })}
      </TitleWrapper>

      <ContentWrapper>
        <SearchForm
          initialValues={{
            ...search,
            type_id: search.type_id ? search.type_id : 'all',
            user_created_id: search.user_created_id
              ? +search.user_created_id
              : 'all',
          }}
          onFinish={handleSearch}
        >
          <Form.Item name="search">
            <Input
              placeholder={intl.formatMessage({ id: 'input.search' })}
              value={search.search}
            />
          </Form.Item>
          <Form.Item name="location">
            <FilterLocationModal
              data={locationData}
              setData={setLocationData}
            />
          </Form.Item>
          {!isLoading && isFetched && (
            <Form.Item name="type_id">
              <Select
                className="selectType"
                showSearch
                placeholder="Chọn loại công ty"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  isIncludeTextSearch(input, option.children)
                }
              >
                <Option value={'all'}>Tất cả</Option>
                {Companytype.data.map((CompanyType) => (
                  <Option value={CompanyType.id} key={CompanyType.id}>
                    {CompanyType.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <SelectUser />
        </SearchForm>
        <TableWrapper
          rowKey="id"
          columns={columns}
          dataSource={data?.data || []}
          loading={!isLoadingCompanies && isFetchedCompanies}
          rowSelection={rowSelection}
          pagination={{
            position: ['bottomCenter'],
            current: search.page,
            pageSize: search.page_size,
            total: data?.meta?.total ?? 0,
            pageSizeOptions: ['10', '20', '50', '100', '1000'],
          }}
          onChange={handleChange}
          extra={renderExtra}
          scroll={{
            x: '100%',
          }}
        />
      </ContentWrapper>
    </CompaniesStyles>
  );
}
