import { useEffect } from 'react';
import { useQuery, useQueryClient, useMutation } from 'react-query';

import { blogApi } from 'api/blogApi';

export const BlogKey = {
  all: ['Blogs'],
  lists: () => [...BlogKey.all, 'list'],
  list: (filter) => [...BlogKey.lists(), filter],
  details: () => [...BlogKey.all, 'detail'],
  detail: (id) => [...BlogKey.details(), id],
};
const updateBlog = (id) => {
  return blogApi.enable(id);
}
export const useBlog = ({ params, options }) => {
  return useQuery({
    queryKey: BlogKey.list(params),
    queryFn: () => blogApi.getAll(params),
    ...options,
  });
};
export const useUpdateBlog = ({ params, options }) => {
  const queryClient = useQueryClient();
  return useMutation(updateBlog, {
    onSuccess: (data) => {
      queryClient.setQueryData({
        queryKey: BlogKey.list(params),
        queryFn: () => blogApi.getAll(params),
        ...options,
      }, (oldQueryData) => {
        const oldData = oldQueryData.data;
        const index = oldData.findIndex((blog) => blog.id === data.data.id);
        oldData[index].enable = !oldData[index].enable;
        return {
          ...oldQueryData,
        };
      })
    }
  });
}
export const usePrefetchCompanies = (data, queryParams) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    if (data?.link?.next) {
      const newQueryParams = { ...queryParams, page: queryParams.page + 1 };

      queryClient.prefetchQuery(BlogKey.list(newQueryParams), () =>
        blogApi.getAll(newQueryParams),
      );
    }
  }, [data, queryParams, queryClient]);
};

