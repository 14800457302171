import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Button,
  Col,
  ConfigProvider,
  DatePicker,
  Form,
  Image,
  Input,
  Row,
  Select,
  Space,
  Spin,
  Upload,
} from 'antd';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { TitleWrapper } from 'components/layout';
import { userApi } from 'api/userApi';
import { Option } from 'antd/lib/mentions';
import ImgCrop from 'antd-img-crop';
import { PlusOutlined } from '@ant-design/icons';
import { useUser } from '../queries';
import moment from 'moment';

const layout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 10,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 4,
    span: 2,
  },
};
const UserAddStyle = styled.div`
  .form {
    margin-top: 30px;
  }
`;
const dateFormat = 'YYYY-MM-DD';
function AddUser(props) {
  const intl = useIntl();
  const { id } = useParams();

  const { isLoading, data } = useUser({ id });
  // let newData = { ...data } || {} ;
  // const ArrString = newData.data?.date_of_birth.split(" ");
  // newData?.data?.date_of_birth = ArrString[0];
  // console.log('ArrString', ArrString);

  let navigate = useNavigate();
  const formElemt = useRef();
  const name = useRef();
  const phone = useRef();
  const email = useRef();
  const sex = useRef();
  const enable = useRef();

  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const [idImage, setIdImage] = useState(null);
  // const [isLoading, setIsLoading] = useState(false);

  const [base64, setBase64] = useState(null);

  const onDatepickerChange = (date, dateString) => {
    setSelectedDate(dateString);
  };

  const onFinish = async (values) => {
    if (values.avatar.file != undefined) {
      values.date_of_birth = selectedDate;
      let data = await userApi.edit(values, id);
      if (data.error) {
        console.log('message', data.error);
      } else {
        navigate('/users');
      }
      const formDataImage = new FormData();
      formDataImage.append('image', values.avatar.file);
      await userApi.updateImages(id, formDataImage);
    } else {
      values.date_of_birth = selectedDate;
      let data = await userApi.edit(values, id);
      if (data.error) {
        console.log('message', data.error);
      } else {
        navigate('/users');
      }
    }
  };

  const handleChangeForm = (value) => {
    console.log(value);
  };

  // const handleChange = ({ fileList }) => {
  //   console.log(fileList);
  //   setDataImages((prev) => {
  //     return {
  //       ...prev,
  //       fileList: fileList.map((item) => ({ ...item, prev })),
  //     };
  //   });
  // };
  // useEffect (() => {
  //   form.setFieldsValue(data)
  //  }, [form, data])
  if (data) {
    console.log('data khi click view gửi đi', data);
  }
  if (isLoading) {
    return <div>Loading..</div>;
  }

  const handleBeforeUpload = (file) => {
    if (file.type === 'image/jpeg' || file.type === 'image/png') {
      const reader = new FileReader();
      reader.addEventListener('loadstart', () => {
        // setIsLoading(true);
      });
      reader.addEventListener('load', () => {
        setBase64(reader.result);
      });

      reader.addEventListener('loadend', () => {
        // setIsLoading(false);
      });

      reader.readAsDataURL(file);
    } else {
      Notification('error', 'File không đúng định dạng, xin vui lòng thử lại');
      return Upload.LIST_IGNORE;
    }
    return false;
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  return (
    <UserAddStyle>
      <TitleWrapper>
        {intl.formatMessage({
          id: id ? 'views.users.title.edit' : 'views.users.title.add',
        })}
      </TitleWrapper>
      <Form
        {...layout}
        form={form}
        onFinish={onFinish}
        initialValues={{
          ...data?.data,
          date_of_birth: moment(data.data.date_of_birth, dateFormat),
        }}
        style={{ marginTop: 20 }}
        // onFormChange={handleChangeForm}
        // btnSubmitProps={{ loading: isLoading }}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: false,
              message: 'không để trường này trống',
            },
          ]}
        >
          <Input ref={name} />
        </Form.Item>
        <Form.Item
          name="phone"
          label="Phone"
          rules={[
            {
              required: false,
              message: 'hãy nhập số điện thoại',
            },
          ]}
        >
          <Input disabled={false} ref={phone} />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              message: 'không để trường này trống',
            },
          ]}
        >
          <Input disabled={true} ref={email} />
        </Form.Item>
        <Form.Item
          name="sex"
          label="Sex"
          rules={[
            {
              required: false,
              message: 'hãy chọn giới tính',
            },
          ]}
        >
          <Select style={{ width: 120 }}>
            <Select.Option ref={sex} value={0}>
              Female
            </Select.Option>
            <Select.Option ref={sex} value={1}>
              Male
            </Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="enable"
          label="Status"
          rules={[
            {
              required: false,
              message: 'hãy chọn trạng thái hoạt động',
            },
          ]}
        >
          <Select style={{ width: 120 }}>
            <Select.Option value={0}>In active</Select.Option>
            <Select.Option value={1}>Active</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="date_of_birth" label="Date of birth">
          <DatePicker
            name="date_of_birth"
            // format={dateFormat}
            onChange={onDatepickerChange}
            // onChange={(date) => {setSelectedDate(date)}}
          />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({
            id: 'views.users.avatar',
          })}
          name="avatar"
          valuePropName="file"
        >
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader upload-list-inline"
            accept=".jpeg, .png, .jpg"
            showUploadList={false}
            beforeUpload={handleBeforeUpload}
            // style={{ width: 100 }}
          >
            <Spin spinning={isLoading}>
              {base64 ? (
                <Image
                  accept="image/png, image/gif, image/jpeg"
                  src={base64}
                  preview={false}
                  style={{ width: 80, height: 80 }}
                />
              ) : data.data.avatar ? (
                <Image
                  accept="image/png, image/gif, image/jpeg"
                  src={data.data.avatar}
                  alt="avatar"
                  preview={false}
                  style={{ width: 80, height: 80 }}
                />
              ) : (
                <PlusOutlined style={{ maxWidth: '100%' }} />
              )}
            </Spin>
          </Upload>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            {id ? 'Sửa ' : 'Thêm '}
          </Button>
        </Form.Item>
      </Form>
    </UserAddStyle>
  );
}

export default AddUser;
