import { useEffect } from 'react';
import { useQuery, useQueryClient, useMutation } from 'react-query';

import { settingsApi } from 'api/settingsApi';


export const SettingsKey = {
  all: ['settings'],
  lists: () => [...SettingsKey.all, 'list'],
  list: (filter) => [...SettingsKey.lists(), filter],
  details: () => [...SettingsKey.all, 'detail'],
  detail: (id) => [...SettingsKey.details(), id],
};

export const useSettings = ({ params, options}) => {
    return useQuery({
        queryKey: SettingsKey.list(params),
        queryFn: () => settingsApi.getAll(params),
        ...options,
    });
};

const deleteSettings = (id) => {
    return settingsApi.delete(id);
}


export const useDeleteSetting = ({ params, options }) => {
  const queryClient = useQueryClient();
  return useMutation(deleteSettings, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: SettingsKey.list(params),
        queryFn: () => settingsApi.getAll(params),
        ...options,
      })
    }
  });
}


export const usePrefetchCompanies = (data, queryParams) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    if (data?.link?.next) {
      const newQueryParams = { ...queryParams, page: queryParams.page + 1 };

      queryClient.prefetchQuery(SettingsKey.list(newQueryParams), () =>
        settingsApi.getAll(newQueryParams),
      );
    }
  }, [data, queryParams, queryClient]);
};
