import { useState, useRef, useCallback, memo, useEffect } from 'react';
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  Autocomplete,
} from '@react-google-maps/api';
import { Spin, message } from 'antd';

const GoogleMapWithSearch = ({ value, onChange }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
    libraries: ['places'],
  });
  const [map, setMap] = useState(null);
  const searchBox = useRef(null);
  const [center, setCenter] = useState(() => value);
  const [marker, setMarker] = useState({ lat: 10.8105831, lng: 106.7091422 });
  const [isFindingLocation, setIsFindingLocation] = useState(false);
  useEffect(
    () => {
      if (!value) {
        setIsFindingLocation(true);
        if (!navigator.geolocation) {
          const position = { lat: 10.8105831, lng: 106.7091422 };
          setCenter(position);
          setIsFindingLocation(false);
          setMarker(position);
        } else {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const pos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              };
              setCenter(pos);
              setMarker(pos);
              setIsFindingLocation(false);
            },
            () => {
              const posError = {
                lat: 10.8105831,
                lng: 106.7091422,
              };
              setCenter(posError);
              setMarker(posError);
              setIsFindingLocation(false);
            },
          );
        }
      } else {
        setCenter(value);
        setMarker(value);
        setIsFindingLocation(false);
      }
      setMap(map);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value],
  );

  const onUnmount = useCallback((map) => {
    setMap(null);
  }, []);

  const handleClick = (e) => {
    const pos = {
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    };
    setMarker(pos);
    setCenter(pos);
    onChange(e.latLng);
  };

  const handlePlaceChanged = () => {
    const place = searchBox.current?.getPlace();
    if (!place?.geometry || !place?.geometry?.location) {
      // User entered the name of a Place that was not suggested and
      // pressed the Enter key, or the Place Details request failed.
      message.error('Không có thông tin chi tiết về vị trí. Vui lòng nhập lại');
      return;
    }

    // If the place has a geometry, then present it on a map.
    const pos = {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    };
    setMarker(pos);
    setCenter(pos);
    onChange(place.geometry.location);
  };

  const onLoadAutoComplete = (ref) => {
    searchBox.current = ref;
  };

  return (
    <div>
      {isLoaded && (
        <Spin spinning={isFindingLocation}>
          <GoogleMap
            mapContainerStyle={{
              width: '100%',
              height: '400px',
            }}
            center={center}
            zoom={17}
            onUnmount={onUnmount}
            onClick={handleClick}
          >
            <Autocomplete
              onLoad={onLoadAutoComplete}
              onPlaceChanged={handlePlaceChanged}
            >
              <input
                type="text"
                placeholder="Nhập vị trí chính xác"
                style={{
                  boxSizing: `border-box`,
                  border: `1px solid transparent`,
                  width: `240px`,
                  height: `32px`,
                  padding: `0 12px`,
                  borderRadius: `3px`,
                  boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                  fontSize: `14px`,
                  outline: `none`,
                  textOverflow: `ellipses`,
                  position: 'absolute',
                  left: '50%',
                  marginLeft: '-120px',
                }}
              />
            </Autocomplete>

            {marker && <Marker position={marker} />}
          </GoogleMap>
        </Spin>
      )}
    </div>
  );
};

export default memo(GoogleMapWithSearch);
