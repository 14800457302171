import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { EditOutlined } from '@ant-design/icons';
import { Button, Select } from 'antd';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { TitleWrapper, ContentWrapper } from 'components/layout';

import { useCustomSearchParams } from 'hooks/useCustomSearchParams';
import { useDeleteAboutus, useAboutUs } from './queries';
import { ButtonAddNew } from 'components/common/button';
import { TableWrapper } from 'components';
import { PATH_NAME, PATH_NAME_ACTION } from 'constants/routes';

import { calcIndexByOrder } from 'utils/common';
import { Option } from 'antd/lib/mentions';

const AboutUsStyle = styled.div`
  .action {
    margin: 0px 5px;
  }
  .action:hover {
    opacity: 0.7;
    cursor: pointer;
  }
  .ant-table-wrapper {
  }
`;

function AboutUs(props) {
  const intl = useIntl();
  const [search, setSearch] = useCustomSearchParams();
  // table
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loadingReload, setLoadingReload] = useState(false);
  const [loadingDelete, setLoadingDetele] = useState(false);

  let navigate = useNavigate();
  const argument = {
    params: search,
    options: {
      keepPreviousData: true,
    },
  };
  const { mutate } = useDeleteAboutus(argument);

  const { data, isPreviousData, isFetching } = useAboutUs(argument);
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const hasSelected = selectedRowKeys.length > 0;
  const deleteSelected =
    selectedRowKeys.length > 0 && selectedRowKeys.length < 2;
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  // function MyComponent() {
  //   return <div dangerouslySetInnerHTML={{__html: data}}/>;
  // }
  // MyComponent();

  //handle edit
  const handleEdit = (id) => {
    navigate(`/about_us/edit/${id}`);
  };
  const columns = [
    {
      title: intl.formatMessage({ id: 'common.index' }),
      render: (_, __, index) =>
        calcIndexByOrder({ index, page: search.page, limit: search.page_size }),
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'about_us.title' }),
      dataIndex: 'title',
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'about_us.detail' }),
      dataIndex: 'detail',
      // align: 'center',
      render: (_, value) => (
        <>
          <div dangerouslySetInnerHTML={{ __html: value.detail }} />
        </>
      ),
    },
    {
      title: intl.formatMessage({ id: 'about_us.action' }),
      dataIndex: 'action',
      align: 'center',
      render: (_, value) => (
        <>
          <EditOutlined
            className={'action'}
            onClick={() => handleEdit(value.id)}
          />
        </>
      ),
    },
  ];

  const handleReload = () => {
    setLoadingReload(true); // ajax request after empty completing
    setTimeout(() => {
      setLoadingReload(false);
    }, 1000);
    setSelectedRowKeys([]);
  };

  const handleDelete = () => {
    setLoadingDetele(true); // ajax request after empty completing
    setTimeout(() => {
      setLoadingDetele(false);
      mutate(selectedRowKeys[0]);
      setSelectedRowKeys([]);
    }, 1000);
    setSelectedRowKeys([]);
  };
  const handleChange = ({ current, pageSize }) =>
    setSearch({ ...search, page: current, page_size: pageSize });

  const renderExtra = (
    <>
      <ButtonAddNew to={`${PATH_NAME.ABOUTUS}/${PATH_NAME_ACTION.CREATE}`} />
      <Button
        type="primary"
        onClick={handleDelete}
        disabled={!deleteSelected}
        loading={loadingDelete}
      >
        Xóa
      </Button>
      <Button
        type="primary"
        onClick={handleReload}
        disabled={!hasSelected}
        loading={loadingReload}
      >
        Làm mới
      </Button>
    </>
  );

  return (
    <>
      <AboutUsStyle>
        <TitleWrapper>
          {intl.formatMessage({ id: 'views.about_us.title.list' })}
        </TitleWrapper>
        <ContentWrapper>
          <TableWrapper
            // styled={{textAlign: 'center'}}
            rowKey="id"
            columns={columns}
            dataSource={data?.data || []}
            loading={isPreviousData && isFetching}
            rowSelection={rowSelection}
            pagination={{
              current: search.page,
              pageSize: search.page_size,
              total: data?.meta?.total ?? 0,
            }}
            onChange={handleChange}
            extra={renderExtra}
          />
        </ContentWrapper>
      </AboutUsStyle>
    </>
  );
}

export default AboutUs;
