import axiosClient from './axiosClient';

export const newsCategoriesApi = {
  getAll(params) {
    return axiosClient.get('/admin/news/categories/all/', { params });
  },
  delete(ids) {
    return axiosClient.delete(`/admin/news/categories/multiple`, {
      data: { ids: ids },
    });
  },
  add(newsCategories) {
    return axiosClient.post(`/admin/news/categories`, newsCategories);
  },
  edit({ data, id }) {
    return axiosClient.patch(`/admin/news/categories/${id}`, data);
  },
  getById(id) {
    return axiosClient.get(`/admin/news/categories/${id}`);
  },
};
