import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

import Sidebar from './components/Sidebar';


const WrapperStyles = styled.div`
 margin-right: 50px;
`
export default function DashboardLayout() {
  return (
    <Layout style={{ minHeight: '100%' }}>
      {/* <Header /> */}

      <Layout>
        <Sidebar />

        <Layout.Content>
          <WrapperStyles className="wrapper">
            <Outlet />
          </WrapperStyles>
        </Layout.Content>
      </Layout>
    </Layout>
  );
}
