import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Form, Input, Select } from 'antd';
import styled from 'styled-components';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { TitleWrapper } from 'components/layout';
import { Notification } from 'components';
import { AddressApi } from 'api/AddressApi';
import { useGetListDistrict, useGetAllProvince } from '../../queries';
import WrapperCenter from 'components/common/button/wrapperCenter';

const { Option } = Select;
const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 0,
    span: 24,
  },
};
const ProvinceAddStyles = styled.div`
  .form {
    margin-top: 10px;
  }
`;
function AddFeatureKeyWords(props) {
  const intl = useIntl();
  let navigate = useNavigate();
  const formElemt = useRef();
  const province = useRef();
  const { id } = useParams();
  const [form] = Form.useForm();

  const [searchParams] = useSearchParams();
  const district_id = searchParams.get('district_id');
  const province_id = searchParams.get('province_id');
  const [objectAddress, setObjectAddress] = useState({
    district_id: +district_id,
    province_id: +province_id,
  });
  const onFinish = async (values) => {
    values.prefix = 'Đường';
    values['district_id'] = district_id;
    values['province_id'] = province_id;
    let data = id
      ? await AddressApi.EditStreet(values, id)
      : await AddressApi.AddStreet(values);
    if (data.error) {
      Notification('error', data.error.message);
    } else {
      navigate('/address/street');
    }
  };
  const { data: AllDistrict } = useGetListDistrict({
    id: objectAddress.province_id,
    district: 'district_id',
    setData: false,
    setObjectAddress,
  });
  const { data: AllProvince } = useGetAllProvince({
    params: 'ProvinceAllData',
  });
  const List = AllProvince?.data?.data || [];
  const ListDistrict = AllDistrict?.data?.data || [];
  async function fetchProvice(id) {
    const { data } = await AddressApi.GetStreet(id);
    formElemt.current.setFieldsValue({
      ...data,
    });
  }
  const HandleValuesChange = (value) => {
    if (value.province_id) {
      setObjectAddress(value);
    } else {
      setObjectAddress((prev) => {
        return {
          ...prev,
          ...value,
        };
      });
    }
  };
  useEffect(() => {
    if (id) {
      fetchProvice(id);
    }
    province.current.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ProvinceAddStyles>
      <TitleWrapper>
        {intl.formatMessage({
          id: id
            ? 'views.address.title.street.edit'
            : 'views.address.title.street.add',
        })}
      </TitleWrapper>
      <Form
        ref={formElemt}
        onValuesChange={HandleValuesChange}
        {...layout}
        form={form}
        name="control-hooks"
        onFinish={onFinish}
        className="form"
      >
        <Form.Item
          name="name"
          label="name"
          rules={[
            {
              required: true,
              message: 'không để trường này trống',
            },
          ]}
        >
          <Input ref={province} />
        </Form.Item>
        <Form.Item
          initialValue={objectAddress.province_id}
          name="province_id"
          label="province"
          rules={[
            {
              required: true,
              message: 'không để trường này trống',
            },
          ]}
        >
          <Select
            showSearch
            disabled={true}
            placeholder="Chọn Thị xã và quận hoặc huyện"
            optionFilterProp="children"
            filterOption={(input, option) => option.children.includes(input)}
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
          >
            {List.map((province) => (
              <Option value={province.id} key={province.id}>
                {province.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="district_id"
          label="district"
          initialValue={objectAddress.district_id}
          rules={[
            {
              required: true,
              message: 'không để trường này trống',
            },
          ]}
        >
          <Select
            showSearch
            disabled={true}
            placeholder="Chọn Thị xã và quận hoặc huyện"
            optionFilterProp="children"
            filterOption={(input, option) => option.children.includes(input)}
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
          >
            {ListDistrict.map((district) => (
              <Option value={district.id} key={district.id}>
                {district.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="keyword" label="Nhập từ khóa">
          <Input />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <WrapperCenter>
            <Button type="primary" htmlType="submit">
              {id ? 'Sửa đường' : 'Thêm đường'}
            </Button>
          </WrapperCenter>
        </Form.Item>
      </Form>
    </ProvinceAddStyles>
  );
}

export default AddFeatureKeyWords;
