import styled from "styled-components";
import {TitleWrapper} from "../../../components/layout";
import React, {useEffect, useRef, useState} from "react";
import {useIntl} from "react-intl";
import {useParams} from "react-router-dom";
import moment from "moment/moment";
import {Button, DatePicker, Form, Image, Input, InputNumber, Select, Space, Spin, Upload} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {TransactionManagementApi} from "../../../api/transactionmanagement";
import {usePaymentTransactionDetail} from "../../../hooks/usePaymentTransaction";
import TextArea from "antd/es/input/TextArea";

const layout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 10,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 4,
    span: 2,
  },
};

const ViewTransactionContainer = styled.div`
  .form {
    margin-top: 30px;
  }
`;

const PaymentTransactionDetail = () => {
  const intl = useIntl();
  const { id } = useParams();
  const [ form ] = Form.useForm();
  const { isLoadingSync, setIsLoadingSync } = useState(false)

  const {data, loading, fetch} = usePaymentTransactionDetail()
  useEffect(() => {
    fetch(id)
  }, [id])

  if (loading) {
    return <div>Loading..</div>;
  }
  const handleSyncStatusTransaction = async (data) => {
    await TransactionManagementApi.syncStatusPaymentTransaction(data);
    window.location.reload();
  }

  return(
    <ViewTransactionContainer>
      <TitleWrapper>
        {intl.formatMessage({
          id: 'views.transactionmanagement.title.view',
        })}
      </TitleWrapper>
      <Form
        {...layout}
        form={form}
        onFinish={() => handleSyncStatusTransaction({id: data?.id,
          bk_order_id: data?.order_ref_id, mrc_order_id: data?.code})}
        initialValues={data}
        style={{ marginTop: 20 }}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: false,
              message: 'không để trường này trống',
            },
          ]}
        >
          <Input disabled={true}/>
        </Form.Item>
        <Form.Item
          name="desc"
          label="Description"
          rules={[
            {
              required: false,
              message: 'không để trường này trống',
            },
          ]}
        >
          <TextArea disabled={true} rows={4} style={{resize: 'none'}} />
        </Form.Item>
        <Form.Item
          name="amount"
          label="Price"
          rules={[
            {
              required: false,
              message: 'không để trường này trống',
            },
          ]}
        >
          <InputNumber disabled={true}
                       addonAfter={<div>VND</div>}
                       formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} />
        </Form.Item>

        {data?.type == "deposit" && (
          <>
            <Form.Item
              name="order_ref_id"
              label="Order Ref ID"
              rules={[
                {
                  required: false,
                  message: 'không để trường này trống',
                },
              ]}
            >
              <Input disabled={true}/>
            </Form.Item>
            <Form.Item
              name="txn_id"
              label="Transaction ID"
              rules={[
                {
                  required: false,
                  message: 'không để trường này trống',
                },
              ]}
            >
              <Input disabled={true}/>
            </Form.Item>
          </>
        )}

        {data?.type == "payment" && (
          <Form.Item
            name="ads_id"
            label="Ads_id"
          >
            <Form.Item
              name="ads_id"
              rules={[{ required: true }]}
              style={{ display: 'inline-block', width: 'calc(50% - 8px)', marginBottom: 0 }}
            >
              <Input disabled={true} />
            </Form.Item>
            <Form.Item style={{display: 'inline-block', width: '20%', marginLeft: 15, marginBottom: 0}}>
              <a href={`/ad-manager/edit/${data?.ads_id}`}>View</a>
            </Form.Item>
          </Form.Item>
        )}

        <Form.Item
          name="user"
          label="User"
        >
          <Form.Item
            name="user_id"
            rules={[{ required: true }]}
            style={{ display: 'inline-block', width: 'calc(50% - 8px)', marginBottom: 0 }}
          >
            <Input disabled={true} />
          </Form.Item>
          <Form.Item style={{display: 'inline-block', width: '20%', marginLeft: 15, marginBottom: 0}}>
            <a href={`/users/edit/${id}`}>View</a>
          </Form.Item>
        </Form.Item>

        <Form.Item
          name="type"
          label="Type"
          rules={[
            {
              required: false,
              message: 'không để trường này trống',
            },
          ]}
        >
          <Input disabled={true}/>
        </Form.Item>

        <Form.Item
          name="status"
          label="Status"
          rules={[
            {
              required: false,
              message: 'không để trường này trống',
            },
          ]}
        >
          <Input disabled={true}/>
        </Form.Item>
        <Form.Item
          name="created_at"
          label="Date Created"
          rules={[
            {
              required: false,
              message: 'không để trường này trống',
            },
          ]}
        >
          <Input disabled={true}/>
        </Form.Item>
        <Form.Item
          name="updated_at"
          label="Date Updated"
          rules={[
            {
              required: false,
              message: 'không để trường này trống',
            },
          ]}
        >
          <Input disabled={true}/>
        </Form.Item>

        {
          (data?.status === 'processing' || data?.status === 'pending') && data?.type == "deposit" && (
            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                Sync Data
              </Button>
            </Form.Item>
          )
        }
      </Form>
    </ViewTransactionContainer>
  )
}

export default PaymentTransactionDetail
