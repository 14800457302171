import { createGlobalStyle } from 'styled-components';
import 'antd/dist/antd.less';

const GlobalStyle = createGlobalStyle`
  #root, body, html {
    height: 100%;
  }

  html {
    --white:#fff;
    --black:#000;
    --blue: #007575;
    --green:#057642;
    --black1: #4F4F4F;
    --gray: #F2F2F2;
    --grayDark: #828282;
    --grayWhite: #F2F2F2;
    --grayLight:  #DADADA;
    --gray6: #bdbdbd;
    --text: #333333;
    --red: #ff0302;
    --redDark: #AE2A2F;
    --naviGreen: #4F808C;
    --yellow: #F2C94C;
  }

  html h1,
  html h2,
  html h3,
  html h4,
  html h5,
  html h6,
  html a,
  html p,
  html li,
  input,
  textarea,
  span,
  div,
  html,
  body,
  html a {
    margin-bottom: 0;
    color: inherit;
  }

  /* Set font-family by language */
  ${
    '' /* html:lang(en) body {}
  html:lang(vi) body {} */
  }

  body {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    color: var(--black)
  }

  ul, ol, dl {
    list-style: none;
    margin-bottom: 0;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  /*==========================
    NOTIFICATION
  ===========================*/
  .ant-notification-notice-with-icon {
    position: relative;
    color: var(--white);

    .ant-notification-notice-icon {
      left: 0;
      margin-left: 0;
      font-size: 30px;
      line-height: 30px;
      height: 30px;
      top: 50%;
      transform: translateY(-50%);
      color: inherit;
    }

    .ant-notification-notice-message {
      margin-bottom: 0;
      color: inherit;
    }
  }

  .ant-notification-notice-close {
    color: var(--white);
    &:hover {
      color: var(--white);
    }
  }

  /*==========================
    INPUT
  ===========================*/
  .ant-input {
    &::-webkit-input-placeholder {
      color: var(--primary);
    }
    &::-moz-placeholder {
      color: var(--primary);
    }
    &:-ms-input-placeholder {
      color: var(--primary);
    }
    &:-moz-placeholder {
      color: var(--primary);
    }
  }
`;

export default GlobalStyle;
