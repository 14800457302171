import axiosClient from './axiosClient';

export const TransactionManagementApi = {
  getAllPaymentTransaction(params) {
    return axiosClient.get(`/admin/transaction?page=${params?.page || 1}&page_size=10&search=${params?.search || ''}`)
  },

  getPaymentTransactionDetail(id) {
    return axiosClient.get(`/admin/transaction/${id}`)
  },

  syncStatusPaymentTransaction(data) {
    return axiosClient.post(`/admin/transaction/sync_status`, data)
  }
}
