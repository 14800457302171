import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Form, Input } from 'antd';
import styled from 'styled-components';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';

import { TitleWrapper } from 'components/layout';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import FilterBuilding from '../FilterBuilding';
import { BuildingApi } from 'api/buildingApi';
import WrapperCenter from 'components/common/button/wrapperCenter';

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
const FeatureAddStyles = styled.div`
  .form {
    margin-top: 10px;
  }
  .editor {
    padding: 10px;
  }
  .demo-editor {
    ${'' /* border: 1px solid #000; */}
    padding: 10px;
    border-radius: 4px;
    background-color: #fff;
    min-height: 200px;
  }
`;

const BuildingStyles = styled(FilterBuilding)``;

function AddbuildingManagement(props) {
  const intl = useIntl();
  const formElemt = useRef();
  const name = useRef();
  const { id } = useParams();
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const district_id = searchParams.get('district_id');
  const province_id = searchParams.get('province_id');
  const street_id = searchParams.get('street_id');

  const [objectAddress, setObjectAddress] = useState({
    provinceId: +province_id,
    districtId: +district_id,
    streetId: +street_id,
  });
  const onFinish = async (values) => {
    const newForm = {
      name: values.name,
      province_id: +province_id,
      district_id: +district_id,
      street_id: +street_id,
    };
    id ? await BuildingApi.edit(newForm, id) : await BuildingApi.add(newForm);
    navigate(-1);
  };

  useEffect(() => {
    async function fetchData(id) {
      const { data } = await BuildingApi.get(id);
      formElemt.current.setFieldsValue({ name: data.name });
    }
    if (id) {
      fetchData(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <FeatureAddStyles>
      <TitleWrapper>
        {intl.formatMessage({
          id: id ? 'views.building.title.edit' : 'views.building.title.add',
        })}
      </TitleWrapper>
      <Form
        ref={formElemt}
        {...layout}
        name="control-hooks"
        className="form"
        onFinish={onFinish}
      >
        <Form.Item
          name="name"
          label="Tên: "
          rules={[
            {
              required: true,
              message: 'không để trường này trống',
            },
          ]}
        >
          <Input ref={name} />
        </Form.Item>
      </Form>
      <BuildingStyles
        objectAddress={objectAddress}
        setObjectAddress={setObjectAddress}
        col={8}
        disabled={true}
      />
      <WrapperCenter>
        <Button
          style={{ padding: '0 !important' }}
          type="primary"
          htmlType="submit"
          onClick={() => formElemt.current.submit()}
        >
          {id ? 'Sửa' : 'Thêm   '}
        </Button>
      </WrapperCenter>
    </FeatureAddStyles>
  );
}
export default AddbuildingManagement;
