import { useEffect } from 'react';
import { useQuery, useQueryClient, useMutation } from 'react-query';

import { BuildingApi } from 'api/buildingApi';


export const BuildingKey = {
  all: ['Building'],
  lists: () => [...BuildingKey.all, 'list'],
  list: (filter) => [...BuildingKey.lists(), filter],
  details: () => [...BuildingKey.all, 'detail'],
  detail: (id) => [...BuildingKey.details(), id],
};

export const useBuilding = ({ params, options, street_id }) => {
  return useQuery({
    queryKey: BuildingKey.list({ params, street_id }),
    queryFn: () => BuildingApi.getAll({ params, street_id }),
    ...options,
  });
};

const deleteBuilding = (data) => {
  return BuildingApi.delete(data);
}


export const useDeleteBuilding = ({ params, options }) => {
  const queryClient = useQueryClient();
  return useMutation(deleteBuilding, {
    onSuccess: () => {
      queryClient.refetchQueries();
    }
  });
}


export const usePrefetchCompanies = (data, queryParams) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    if (data?.link?.next) {
      const newQueryParams = { ...queryParams, page: queryParams.page + 1 };

      queryClient.prefetchQuery(BuildingKey.list(newQueryParams), () =>
        BuildingApi.getAll(newQueryParams),
      );
    }
  }, [data, queryParams, queryClient]);
};
