import { useEffect } from 'react';
import { useQuery, useQueryClient, useMutation } from 'react-query';

import { categoryApi } from 'api/categoryApi';

export const categoryKeys = {
  all: ['category'],
  lists: () => [...categoryKeys.all, 'list'],
  list: (filter) => [...categoryKeys.lists(), filter],
  details: () => [...categoryKeys.all, 'detail'],
  detail: (id) => [...categoryKeys.details(), id],
};

const deletecompantKeys = (id) => {
  return categoryApi.delete(id);
};

export const useCategory = ({ params, options }) => {
  return useQuery({
    queryKey: categoryKeys.list(params),
    queryFn: () => categoryApi.getAll(),
    options: {
      ...options,
    },
  });
};
export const useCategorySelect = ({ params, options }) => {
  return useQuery({
    queryKey: categoryKeys.list(params),
    queryFn: () => categoryApi.getAll(),
    select: (data) => {
      if (data?.data) {
        for (let i = 0; i < data.data.data.length; i++) {
          data.data.data[i].label = data.data.data[i].name;
          data.data.data[i].value = data.data.data[i].id;
        }
      }
      return data.data.data;
    },
    options: {
      ...options,
    },
  });
};
export const useDeleteCategory = ({ params, options }) => {
  const queryClient = useQueryClient();
  return useMutation(deletecompantKeys, {
    onSuccess: (data) => {
      queryClient.refetchQueries();
    },
  });
};
export const usePrefetchCategory = (data, queryParams) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    if (data?.link?.next) {
      const newQueryParams = { ...queryParams, page: queryParams.page + 1 };

      queryClient.prefetchQuery(categoryKeys.list(newQueryParams), () =>
        categoryApi.getAll(newQueryParams),
      );
    }
  }, [data, queryParams, queryClient]);
};
