import React, { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Button, Form, Input, Select } from 'antd';
import styled from 'styled-components';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { TitleWrapper } from 'components/layout';
import { Notification } from 'components';
import { AddressApi } from 'api/AddressApi';
import { useGetAllProvince } from '../../queries';
import WrapperCenter from 'components/common/button/wrapperCenter';

const { Option } = Select;
const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 0,
    span: 24,
  },
};
const ProvinceAddStyles = styled.div`
  .form {
    margin-top: 10px;
  }
`;
function AddFeatureKeyWords(props) {
  const intl = useIntl();
  let navigate = useNavigate();
  const formElemt = useRef();
  const province = useRef();
  const { id } = useParams();
  const [form] = Form.useForm();

  const [searchParams] = useSearchParams();
  const province_id = searchParams.get('province_id');
  const { data: ProvinceAllData } = useGetAllProvince({
    params: 'ProvinceAllData',
  });
  const List = ProvinceAllData?.data?.data || [];
  const onFinish = async (values) => {
    let data = id
      ? await AddressApi.EditDistrict(values, id)
      : await AddressApi.AddDistrict(values);
    if (data.error) {
      Notification('error', data.error.message);
    } else {
      navigate('/address/district');
    }
  };
  async function fetchProvice(id) {
    const { data } = await AddressApi.GetDistrict(id);
    formElemt.current.setFieldsValue({
      ...data,
    });
  }
  useEffect(() => {
    if (id) {
      fetchProvice(id);
    }
    province.current.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ProvinceAddStyles>
      <TitleWrapper>
        {intl.formatMessage({
          id: id
            ? 'views.address.title.district.edit'
            : 'views.address.title.district.add',
        })}
      </TitleWrapper>
      <Form
        ref={formElemt}
        {...layout}
        form={form}
        name="control-hooks"
        onFinish={onFinish}
        className="form"
      >
        <Form.Item
          name="name"
          label="name"
          rules={[
            {
              required: true,
              message: 'không để trường này trống',
            },
          ]}
        >
          <Input ref={province} />
        </Form.Item>
        <Form.Item
          initialValue={+province_id}
          name="province_id"
          label="province"
          rules={[
            {
              required: true,
              message: 'không để trường này trống',
            },
          ]}
        >
          <Select
            showSearch
            disabled={!!id}
            placeholder="Chọn Thị xã và quận hoặc huyện"
            optionFilterProp="children"
            filterOption={(input, option) => option.children.includes(input)}
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
          >
            {List.map((province) => (
              <Option value={province.id} key={province.id}>
                {province.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="prefix"
          label="prefix"
          // initialValue={'Quận'}
          rules={[
            {
              required: true,
              message: 'không để trường này trống',
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Chọn Thị xã và quận hoặc huyện"
            optionFilterProp="children"
            filterOption={(input, option) => option.children.includes(input)}
            // filterSort={(optionA, optionB) =>
            //   optionA.children
            //     .toLowerCase()
            //     .localeCompare(optionB.children.toLowerCase())
            // }
          >
            <Option value="Thị xã">Thị Xã</Option>
            <Option value="Quận">Quận </Option>
            <Option value="Huyện ">Huyện </Option>
            <Option value="Thành Phố">Thành Phố</Option>
          </Select>
        </Form.Item>
        <Form.Item name="keyword" label="Nhập từ khóa">
          <Input />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <WrapperCenter>
            <Button type="primary" htmlType="submit">
              {id ? 'Sửa Huyện' : 'Thêm Huyện'}
            </Button>
          </WrapperCenter>
        </Form.Item>
      </Form>
    </ProvinceAddStyles>
  );
}

export default AddFeatureKeyWords;
