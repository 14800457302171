import { useState } from 'react';
import { useIntl } from 'react-intl';
import { EditOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Modal } from 'antd';

import { TitleWrapper, ContentWrapper } from 'components/layout';

import { useCustomSearchParams } from 'hooks/useCustomSearchParams';
import { useDeleteTopic, useTopic } from './queries';
import { ButtonNewOn } from 'components/common/button/ButtonAddNew';
import { TableWrapper } from 'components';
import ButtonOther from 'components/common/button/ButtonOther';
import AddTopics from './add/AddTopic';

import { calcIndexByOrder } from 'utils/common';

const TopicStyles = styled.div`
  .action {
    margin: 0px 5px;
  }
  .action:hover {
    opacity: 0.7;
    cursor: pointer;
  }
  .ant-table-wrapper {
  }
`;
function Topic() {
  const intl = useIntl();
  const [id, setId] = useState(null);
  const [visible, setVisible] = useState(false);
  const [search, setSearch] = useCustomSearchParams();
  // table
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loadingReload, setLoadingReload] = useState(false);
  const [loadingDelete, setLoadingDetele] = useState(false);
  const argument = {
    params: search,
    options: {
      keepPreviousData: true,
    },
  };
  const { mutate } = useDeleteTopic(argument);
  //

  const { data, isPreviousData, isFetching } = useTopic(argument);
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const hasSelected = selectedRowKeys.length > 0;
  const deleteSelected = selectedRowKeys.length > 0;
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const columns = [
    {
      title: intl.formatMessage({ id: 'common.index' }),
      render: (_, __, index) =>
        calcIndexByOrder({ index, page: search.page, limit: search.page_size }),
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'topic.name' }),
      dataIndex: 'name',
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'topic.action' }),
      dataIndex: 'action',
      align: 'center',
      render: (_, value) => (
        <>
          <EditOutlined
            className={'action'}
            onClick={() => handleEdit(value.id)}
          />
        </>
      ),
    },
  ];
  const handleReload = () => {
    setLoadingReload(true); // ajax request after empty completing
    setTimeout(() => {
      setLoadingReload(false);
    }, 1000);
    setSelectedRowKeys([]);
  };
  const handleDelete = () => {
    setLoadingDetele(true); // ajax request after empty completing
    setTimeout(() => {
      setLoadingDetele(false);
      mutate(selectedRowKeys);
      setSelectedRowKeys([]);
    }, 1000);
    setSelectedRowKeys([]);
  };

  const handleEdit = (id) => {
    setId(id);
    setVisible(true);
  };
  const handleNew = () => {
    setId(null);
    setVisible(true);
  };
  const handleChange = ({ current, pageSize }) =>
    setSearch({ ...search, page: current, page_size: pageSize });
  const renderExtra = (
    <>
      <ButtonNewOn onClick={handleNew} />
      <ButtonOther
        type="default"
        onClick={handleDelete}
        disabled={!deleteSelected}
        loading={loadingDelete}
      >
        Xóa
      </ButtonOther>
      <ButtonOther
        type="default"
        onClick={handleReload}
        disabled={!hasSelected}
        loading={loadingReload}
      >
        Làm mới 
      </ButtonOther>
    </>
  );
  return (
    <>
      <TopicStyles>
        <TitleWrapper>
          {intl.formatMessage({ id: 'views.topic.list.title' })}
        </TitleWrapper>
        <ContentWrapper>
          <TableWrapper
            rowKey="id"
            columns={columns}
            dataSource={data?.data || []}
            loading={isPreviousData && isFetching}
            rowSelection={rowSelection}
            pagination={{
              current: search.page,
              position: ['bottomCenter'],
              pageSize: search.page_size,
              total: data?.meta?.total ?? 0,
            }}
            onChange={handleChange}
            extra={renderExtra}
          />
        </ContentWrapper>
        {visible && (
          <Modal
            onCancel={() => setVisible(false)}
            visible={visible}
            title={null}
            footer={null}
            closable={false}
          >
            <AddTopics id={id} setVisible={setVisible} />
          </Modal>
        )}
      </TopicStyles>
    </>
  );
}

export default Topic;
