import axiosClient from './axiosClient';

export const blogApi = {
  getAll(params) {
    return axiosClient.get('/admin/blogs', { params });
  },
  get(id) {
    return axiosClient.get(`/admin/blogs/${id}`);
  },
  enable(id) {
    return axiosClient.patch(`/admin/blogs/${id}`);
  },
};
