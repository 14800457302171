import axiosClient from './axiosClient';

const newsmanagementApi = {
  getAll() {
    return axiosClient.get('/admin/news');
  },
  get(id) {
    return axiosClient.get(`/admin/news/${id}`);
  },
  add(data) {
    return axiosClient.post('/admin/news', data);
  },
  edit(data, id) {
    return axiosClient.patch(`/admin/news/${id}`, data);
  },
  delete(id) {
    return axiosClient.delete(`/admin/news/${id}`);
  },
  enable(id) {
    return axiosClient.get(`/admin/news/enable/${id}`);
  },
  uploadAvatar(id, form) {
    return axiosClient.post(`/admin/news/avatar/${id}`, form);
  }
};

export default newsmanagementApi;
