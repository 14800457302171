import axiosClient from './axiosClient';

export const aboutusApi = {
  getAll(params) {
    return axiosClient.get('/admin/about_us/',{ params });
  },
  delete(id) {
    return axiosClient.delete(`/admin/about_us/${id}/`);
  },
  add(title) {
    return axiosClient.post(`/admin/about_us`, title);
  },
  edit(keyword, id) {
    return axiosClient.patch(`/admin/about_us/${id}`, keyword);
  },
  get(id) {
    return axiosClient.get(`/admin/about_us/${id}`);
  }
};