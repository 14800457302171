import React, { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Upload, Image, Form } from 'antd';

const Acolumn = {
  labelCol: {
    span: 24,
  },
};

const UploadForm = ({ value, disabled = false, name, label, rules = true }) => {
  const [base64, setBase64] = useState(value);

  useEffect(() => {
    setBase64(value);
  }, [value])
  const rulesArr = rules
    ? [
        {
          required: true,
          message: 'Trường ảnh đã bị thiếu',
        },
      ]
    : [];
  const handleBeforeUpload = (file) => {
    if (file.type === 'image/jpeg' || file.type === 'image/png') {
      const reader = new FileReader();
      reader.addEventListener('loadstart', () => {
        // setIsLoading(true);
      });
      reader.addEventListener('load', () => {
        setBase64(reader.result);
      });

      reader.addEventListener('loadend', () => {
        // setIsLoading(false);
      });
      reader.readAsDataURL(file);
    } else {
      Notification('error', 'File không đúng định dạng, xin vui lòng thử lại');
      return Upload.LIST_IGNORE;
    }
    return false;
  };

  return (
    <Form.Item
      name={name}
      label={label}
      {...Acolumn}
      style={{
        width: '98%',
      }}
      rules={rulesArr}
    >
      <Upload
        disabled={disabled}
        name="avatar"
        listType="picture-card"
        className="avatar-uploader upload-list-inline"
        accept=".jpeg, .png, .jpg"
        showUploadList={false}
        beforeUpload={handleBeforeUpload}
        maxCount={1}
        fileList={[]}
      >
        {base64 ? (
          <Image
            accept="image/png, image/gif, image/jpeg"
            src={base64}
            preview={false}
            style={{ width: 80, height: 80 }}
          />
        ) : (
          <PlusOutlined style={{ maxWidth: '100%' }} />
        )}
      </Upload>
    </Form.Item>
  );
};

export default UploadForm;
