import axiosClient from './axiosClient';

export const adApi = {
  getAll(params) {
    return axiosClient.get('/admin/ads-manager', { params });
  },
  getById(id) {
    return axiosClient.get(`/admin/ads-manager/${id}`);
  },
  delete(id) {
    return axiosClient.delete(`/admin/ads-manager/${id}/`);
  },
  edit(data, id) {
    return axiosClient.patch(`/admin/ads-manager/${id}`,data);
  },
  approveAds(id) {
    return axiosClient.patch(`/admin/ads-manager/${id}/approve`);
  },

  updateImages(id, data) {
    return axiosClient.post(`/admin/ads-manager/images/${id}`, data);
  },
};
