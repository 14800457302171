import { useQuery, useQueryClient, useMutation } from 'react-query';
import { useEffect } from 'react';

import { userApi } from 'api/userApi';

const userKeys = {
  all: ['users'],
  lists: () => [...userKeys.all, 'lists'],
  list: (filter) => [...userKeys.lists(), filter],
  details: () => [...userKeys.all, 'detail'],
  detail: (id) => [...userKeys.details(), id],
};

export const useUsers = ({ params, options } = {}) => {
  return useQuery({
    queryKey: userKeys.list(params),
    queryFn: () => userApi.getAll(params),
    ...options,
  });
};

const deleteUser = (id) => {
  return userApi.delete(id);
}

export const useDeleteUser = ({ params, options }) => {
  const queryClient = useQueryClient();
  return useMutation(deleteUser, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: userKeys.list(params),
        queryFn: () => userApi.getAll(params),
        ...options,
      })
    }
  });
}

export const usePrefetchCompanies = (data, queryParams) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    if (data?.link?.next) {
      const newQueryParams = { ...queryParams, page: queryParams.page + 1 };

      queryClient.prefetchQuery(userKeys.list(newQueryParams), () =>
        userApi.getAll(newQueryParams),
      );
    }
  }, [data, queryParams, queryClient]);
};

// ------------------------------------------------------------------------------------------------
export const useUser = ({id, options} = {}) => {
  return useQuery({
    queryKey: userKeys.detail(id),
    queryFn: () => userApi.getById(id),
    ...options
  })
}