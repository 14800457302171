import { useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { companyConfirmApi } from 'api/companyConfirmApi';

export const CompamyComfirmKey = {
  all: ['featuredkeyword'],
  lists: () => [...CompamyComfirmKey.all, 'list'],
  list: (filter) => [...CompamyComfirmKey.lists(), filter],
  details: () => [...CompamyComfirmKey.all, 'detail'],
  detail: (id) => [...CompamyComfirmKey.details(), id],
};

export const useCompanyConfirm = ({ params, options }) => {
  return useQuery({
    queryKey: CompamyComfirmKey.list(params),
    queryFn: () => companyConfirmApi.getAll(params),
    select: (data) => {
      for (let i = 0; i < data.data.length; i++) {
        const confirm = data.data[i];
        confirm.Companyname = confirm.company.name;
        confirm.Username = confirm.user.name;
      }
      return data;
    },
    ...options,
  });
};
export const usePrefetchCompanies = (data, queryParams) => {
  const queryClient = useQueryClient();
  useEffect(() => {
    if (data?.link?.next) {
      const newQueryParams = { ...queryParams, page: queryParams.page + 1 };

      queryClient.prefetchQuery(CompamyComfirmKey.list(newQueryParams), () =>
        companyConfirmApi.getAll(newQueryParams),
      );
    }
  }, [data, queryParams, queryClient]);
};
