import { useEffect } from 'react';
import { useQuery, useQueryClient, useMutation } from 'react-query';

import { aboutusApi } from 'api/aboutusApi';


export const AboutUsKey = {
  all: ['about_us'],
  lists: () => [...AboutUsKey.all, 'list'],
  list: (filter) => [...AboutUsKey.lists(), filter],
  details: () => [...AboutUsKey.all, 'detail'],
  detail: (id) => [...AboutUsKey.details(), id],
};

export const useAboutUs = ({ params, options}) => {
    return useQuery({
        queryKey: AboutUsKey.list(params),
        queryFn: () => aboutusApi.getAll(params),
        ...options,
    });
};

const deleteAboutus = (id) => {
    return aboutusApi.delete(id);
}


export const useDeleteAboutus = ({ params, options }) => {
  const queryClient = useQueryClient();
  return useMutation(deleteAboutus, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: AboutUsKey.list(params),
        queryFn: () => aboutusApi.getAll(params),
        ...options,
      })
    }
  });
}


export const usePrefetchCompanies = (data, queryParams) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    if (data?.link?.next) {
      const newQueryParams = { ...queryParams, page: queryParams.page + 1 };

      queryClient.prefetchQuery(AboutUsKey.list(newQueryParams), () =>
        aboutusApi.getAll(newQueryParams),
      );
    }
  }, [data, queryParams, queryClient]);
};
