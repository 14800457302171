import { useIntl } from 'react-intl';
import { ButtonAddNew } from 'components/common/button';
import ButtonOther from 'components/common/button/ButtonOther';
import { useState } from 'react';
import styled from 'styled-components';
import { EditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import { TitleWrapper, ContentWrapper } from 'components/layout';
import { TableWrapper } from 'components/common/table';
import { useCustomSearchParams } from 'hooks/useCustomSearchParams';
import {
  useGetAllProvince,
  useGetListDistrict,
  useProvinceDelete,
} from '.././queries';

export default function Province() {
  const intl = useIntl();
  const { mutate } = useProvinceDelete();
  const [search, setSearch] = useCustomSearchParams();
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const [objectAddress, setObjectAddress] = useState({
    province: null,
    district: null,
    filter: 'ward',
  });
  const HandleValuesChange = (value) => {
    if (value.province) {
      setObjectAddress((prev) => {
        return { ...prev, ...value, district: null };
      });
    } else {
      setObjectAddress((prev) => {
        return { ...prev, ...value };
      });
    }
  };
  useGetAllProvince({
    params: 'ProvinceAllData',
    setData,
  });
  useGetListDistrict({
    id: objectAddress.province,
    setData,
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loadingReload, setLoadingReload] = useState(false);
  const [loadingDelete, setLoadingDetele] = useState(false);
  const hasSelected = selectedRowKeys.length > 0;
  const deleteSelected =
    selectedRowKeys.length > 0 && selectedRowKeys.length < 2;
  const handleReload = () => {
    setLoadingReload(true); // ajax request after empty completing
    setTimeout(() => {
      setLoadingReload(false);
    }, 1000);
    setSelectedRowKeys([]);
  };
  const handleDelete = () => {
    setLoadingDetele(true); // ajax request after empty completing
    setTimeout(() => {
      setLoadingDetele(false);
      mutate(selectedRowKeys[0]);
      setSelectedRowKeys([]);
    }, 1000);
    setSelectedRowKeys([]);
  };
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const handleEdit = (id) => {
    navigate(`/address/edit/province/${id}`);
  };
  const renderExtra = (
    <>
      <ButtonAddNew to={`/address/create/province`} />
      <ButtonOther
        type="default"
        onClick={handleDelete}
        disabled={!deleteSelected}
        loading={loadingDelete}
      >
        Xóa
      </ButtonOther>
      <ButtonOther
        type="default"
        onClick={handleReload}
        disabled={!hasSelected}
        loading={loadingReload}
      >
        Làm mới
      </ButtonOther>
    </>
  );
  const columns = [
    {
      title: intl.formatMessage({ id: 'address.index' }),
      align: 'center',
      render: (_, item, index) => <p>{index + 1}</p>,
    },
    {
      title: intl.formatMessage({ id: 'address.name' }),
      dataIndex: 'name',
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'address.keyword' }),
      dataIndex: 'keyword',
      align: 'center',
    },

    {
      title: intl.formatMessage({ id: 'address.action' }),
      render: (_, item, index) => (
        <div>
          <EditOutlined
            className="address__icon"
            onClick={() => handleEdit(item.id)}
          />
        </div>
      ),
      align: 'center',
    },
  ];

  const handleChange = ({ current, pageSize }) => {
    setSearch({ ...search, page: current, page_size: pageSize });
  };
  const AddressContainer = styled.div`
    .address__icon {
      margin-left: 10px;
      cursor: pointer;
    }
  `;
  return (
    <>
      <TitleWrapper>
        {intl.formatMessage({ id: 'views.address.title.province.title' })}
      </TitleWrapper>

      <ContentWrapper>
        <AddressContainer>
          <TableWrapper
            rowKey="id"
            columns={columns}
            dataSource={data || []}
            onValuesChange={HandleValuesChange}
            onChange={handleChange}
            extra={renderExtra}
            rowSelection={rowSelection}
            pagination={{
              position: ['bottomCenter'],
              current: search.page,
              pageSize: search.page_size,
              total: data?.meta?.total ?? 0,
            }}
          />
        </AddressContainer>
      </ContentWrapper>
    </>
  );
}
