import { useQuery, useQueryClient, useMutation } from 'react-query';
import { useEffect } from 'react';

import { newsCategoriesApi } from 'api/newsCategoriesApi';

const newsCategoriesKey = {
  all: ['news_categories'],
  lists: () => [...newsCategoriesKey.all, 'lists'],
  list: (filter) => [...newsCategoriesKey.lists(), filter],
  details: () => [...newsCategoriesKey.all, 'detail'],
  detail: (id) => [...newsCategoriesKey.details(), id],
};

export const useNewsCategories = ({ params, options } = {}) => {
  return useQuery({
    queryKey: newsCategoriesKey.list(params),
    queryFn: () => newsCategoriesApi.getAll(params),
    ...options,
  });
};

//  action
const addnewscategories= (value) => {
  newsCategoriesApi.add(value);
};

export const useAddNewsCategories= ({}) => {
  const queryClient = useQueryClient();
  return useMutation(addnewscategories, {
    onSuccess: () => {
      queryClient.refetchQueries()
    },
  });
};

const editnewscategories = (data) => {
  newsCategoriesApi.edit(data);
};
export const useDitNewsCategories= ({ }) => {
  const queryClient = useQueryClient();
  return useMutation(editnewscategories, {
    onSuccess: () => {
      queryClient.refetchQueries()
    },
  });
};

const deleteUser = (id) => {
  return newsCategoriesApi.delete(id);
};

export const useDeleteNewsCategories = ({ params, options }) => {
  const queryClient = useQueryClient();
  return useMutation(deleteUser, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: newsCategoriesKey.list(params),
        queryFn: () => newsCategoriesApi.getAll(params),
        ...options,
      });
    },
  });
};

export const usePrefetchCompanies = (data, queryParams) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    if (data?.link?.next) {
      const newQueryParams = { ...queryParams, page: queryParams.page + 1 };

      queryClient.prefetchQuery(newsCategoriesKey.list(newQueryParams), () =>
      newsCategoriesApi.getAll(newQueryParams),
      );
    }
  }, [data, queryParams, queryClient]);
};

// ------------------------------------------------------------------------------------------------
// export const useNewsCategories = ({ id, options } = {}) => {
//   return useQuery({
//     queryKey: newsCategoriesKey.detail(id),
//     queryFn: () => newsCategoriesApi.getById(id),
//     ...options,
//   });
// };
