import React, { useEffect, useRef } from 'react';
import { Button, Form, Input } from 'antd';
import styled from 'styled-components';

import { topicApi } from 'api/topicApi';
import { useAddTopic, useDitTopic } from '../queries';

const layout = {
  labelCol: {
    span: 0,
  },
  wrapperCol: {
    span: 24,
  },
};
const TopicAddStyles = styled.div`
  .boxBtn {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .delete {
    margin-left: 10px;
    color: ${({ theme }) => theme.colors.primary};
  }
  .btn {
    width: 50%;
  }
  .input {
    outline: 1px solid #40a9ff;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: ${({ theme }) => theme.colors.primary};
  }
  .ant-row.ant-form-item {
    margin-bottom: 8px;
  }
`;
function AddTopic({ id, setVisible }) {
  const formElemt = useRef();
  const keyword = useRef();
  const [form] = Form.useForm();
  const { mutate: mutateAdd } = useAddTopic({});
  const { mutate: mutateEdit } = useDitTopic({});
  const onFinish = async (data) => {
    id ? mutateEdit({ data, id }) : mutateAdd(data);
    setVisible(false);
  };
  const onReset = () => {
    formElemt.current.resetFields();
  };
  async function fetchKeyWord(id) {
    const { data } = await topicApi.get(id);
    formElemt.current.setFieldsValue({
      keyword: data.keyword,
    });
  }
  useEffect(() => {
    if (id) {
      fetchKeyWord(id);
    }
    keyword.current.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return (
    <TopicAddStyles>
      <Form
        ref={formElemt}
        {...layout}
        form={form}
        name="control-hooks"
        onFinish={onFinish}
        className="form"
      >
        <Form.Item
          name="name"
          label="name"
          rules={[
            {
              required: true,
              message: 'không để trường này trống',
            },
          ]}
        >
          <Input ref={keyword} placeholder="Thêm chủ đề" className="input" />
        </Form.Item>
        <div className="boxBtn">
          <Button type="primary" htmlType="submit" className="btn">
            {id ? 'Sửa' : 'Thêm'}
          </Button>
          <Button type="default" className="btn delete" onClick={onReset}>
            {'Làm mới'}
          </Button>
        </div>
      </Form>
    </TopicAddStyles>
  );
}

export default AddTopic;
