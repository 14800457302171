import { Button } from 'antd';
import styled, { css } from 'styled-components';

const ButtonStyle = styled(Button)`
  &.ant-btn {
    padding: 4px 15px;
    height: auto;
  }

  &.ant-btn-icon-only {
    width: auto;
  }

  &.large {
    font-size: 18px;
    font-weight: 700;
    min-height: 50px;
  }

  ${(props) => {
    if (props.type === 'blue') {
      return css`
        color: var(--white);
        background-color: var(--blue);
        border-color: var(--blue);

        &:hover {
          color: var(--white);
          background-color: var(--blue);
          border-color: var(--blue);
        }
      `;
    }
    if (props.type === 'edit') {
      return css`
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: ${({ theme }) => theme.colors.primary};
        padding: 8px 16px !important;
      `;
    }
    if (props.type === 'view') {
      return css`
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: ${({ theme }) => theme.colors.primary};
        padding: 8px 16px !important;
      `;
    }

    if (props.type === 'green') {
      return css`
        color: var(--white);
        background-color: var(--naviGreen);
        border-color: var(--naviGreen);

        &:hover {
          color: var(--white);
          background-color: var(--naviGreen);
          border-color: var(--naviGreen);
        }
      `;
    }

    if (props.type === 'grayWhite') {
      return css`
        color: var(--text);
        background-color: var(--grayWhite);
        border-color: var(--grayWhite);
        height: 40px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        font-weight: 500;

        &:hover {
          color: var(--text);
          background-color: var(--grayWhite);
          border-color: var(--grayWhite);
        }
      `;
    }

    if (props.type === 'gray') {
      return css`
        color: var(--grayDark);
        background-color: var(--grayLight);
        border-color: var(--grayLight);

        &:hover {
          color: var(--grayDark);
          background-color: var(--grayLight);
          border-color: var(--grayLight);
        }
      `;
    }

    if (props.type === 'red') {
      return css`
        color: var(--white);
        background-color: var(--redDark);
        border-color: var(--redDark);
        border-radius: 6px;
        font-size: 17px;
        font-weight: 700;
        min-height: 55px;

        &:hover {
          color: var(--white);
          background-color: var(--redDark);
          border-color: var(--redDark);
        }
      `;
    }

    if (props.type === 'dark') {
      return css`
        color: var(--white);
        background-color: var(--text);
        border-color: var(--text);
        border-radius: 6px;
        min-height: 40px;

        &:hover {
          color: var(--white);
          background-color: var(--text);
          border-color: var(--text);
        }
      `;
    }

    if (props.type === 'cancel') {
      return css`
        border-radius: 6px;
        min-height: 40px;
      `;
    }

    if (props.green) {
      return css`
        color: var(--naviGreen);
        background-color: transparent;
        border-color: currentColor;
        text-transform: initial !important;
      `;
    }
  }}
`;

export default ButtonStyle;
